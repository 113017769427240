/* eslint-disable prefer-destructuring */
import * as React from 'react';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuidv4 } from 'uuid';
import {
  deleteBusinessLocation,
  selectBusinessLocations,
  createBusinessLocation,
  getBusinessLocations,
} from 'services/businessLocationSlice';
import { IQLocationPLCard, IQButtonLink } from '@gannettdigital/shared-react-components';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { generateUrl, Urls } from 'navigation/Urls';
import {
  selectIsPartnerFlow,
  selectOrderFlow,
  selectOrderItems,
  selectOrdersContent
} from 'services/ordersSlice';
import { Box } from '@mui/system';
import { useTheme } from '@mui/material';
import { sortOrderItems } from 'services/menus';
import { getFirstStepOfferingType } from 'pages/redirect/ProductMoveForward';
import { isCustomProduct } from 'layouts/leftnav/LeftNavMenu';
import { OrderFlow, mediaProducts } from 'shared/constants';
import { excludedOfferingTypes } from 'pages/generic-product/details/DetailsPageConstants';
import { getUserRoles } from "services/userSlice";

export const defaultLocation = {
  locationName: '',
  businessAddressOne: '',
  businessAddressTwo: '',
  city: '',
  stateProvince: '',
  country: '',
  postalCode: '',
  sourceId: '',
};

// eslint-disable-next-line import/no-mutable-exports
export let defaultPageLayoutItems = {
  disableContinue: true,
  header: '',
  description: '',
  skipAndContinue: true,
};

export default function LocationsPageForm() {
  const navigate = useNavigationHandler();
  const dispatch = useDispatch();
  const theme = useTheme();
  const locations = useSelector(selectBusinessLocations);
  const [locationItems, setLocationItems] = useState([]);

  const [cardCount, setCardCount] = useState(0);
  const { businessId, orderFlow: orderDataFlow } = useSelector(selectOrdersContent);
  const { content: orderItems } = useSelector(selectOrderItems);
  const orderFlow = useSelector(selectOrderFlow);
  const isSalesForce = orderFlow === OrderFlow.SALES_FORCE;
  const premiumListingOrderItems = orderItems.filter(item => item.offeringType === 'PremiumListing');
  const isPunchlist = (orderFlow === OrderFlow.PUNCHLIST || orderDataFlow === OrderFlow.PUNCHLIST);
  const isPartnerFlow = useSelector(selectIsPartnerFlow);

  const getOfferingType = (nextItem) => {
    if (nextItem.offeringType === 'ProductsData') {
      return excludedOfferingTypes.includes(nextItem.pdOfferingType) ? nextItem.pdOfferingType : 'GenericProduct';
    }
    return nextItem.offeringType;
  };

  // eslint-disable-next-line consistent-return
  const isMediaProduct = useMemo(() => {
    const mediaProductItemsInOrder = orderItems.filter(item => mediaProducts.includes(item.pdOfferingType)
        || mediaProducts.includes(item.offeringType));

    return mediaProductItemsInOrder.length > 0;
  }, [orderItems]);

  const handleContinue = () => {
    const sortedItems = sortOrderItems(orderItems, isSalesForce, isPunchlist);
    const nextItem = sortedItems[0];
    if (nextItem) {
      const offeringType = getOfferingType(nextItem);

      const nextStep = offeringType === 'MasterAddon' ? 'addons' : '';
      navigate.to(
        getFirstStepOfferingType(offeringType, isSalesForce, isPunchlist, isPartnerFlow) || nextStep,
        nextItem.id,
      );
    } else {
      navigate.to(Urls.Overview);
    }
  };

  const onBack = () => ((isCustomProduct(orderItems) || isSalesForce || isPunchlist || isPartnerFlow) ? navigate.to(Urls.BusinessClient)
    : navigate.to(Urls.BusinessCampaign));

  const addNewItem = async () => {
    const updatedLocations = [...locations];
    updatedLocations.push({ ...defaultLocation, id: locations.length + 1 });
    const defaultLocationToCreate = { ...defaultLocation };
    defaultLocationToCreate.sourceId = uuidv4();
    await dispatch(createBusinessLocation({ businessId, defaultLocationToCreate }));
    await dispatch(getBusinessLocations(businessId));
  };

  const removeLocation = async (location) => {
    if (location.id) {
      await dispatch(deleteBusinessLocation(location.id));
      setLocationItems(locationItems => {
        const updatedLocations = locationItems.filter((el) => el.id !== location.id);
        return updatedLocations;
      });
    }
  };

  const handleSelection = (location) => {
    if (premiumListingOrderItems.length === 0) {
      const urltonav = generateUrl(
        `/${Urls.BusinessLocationRoot}`,
        [location.id.toString(), Urls.BusinessLocationAddress],
      );
      navigate.to(urltonav);
    } else {
      const urltonav = `premiumlistings/location/${location.id.toString()}/address`;
      navigate.to(urltonav);
    }
  };

  useEffect(() => {
    if (locations) {
      setLocationItems(locations);
    }
  }, [locations]);

  useEffect(() => {
    setCardCount(locationItems.length);
  }, [locationItems]);

  const getLocationStatus = (location) => {
    if (location.completed) {
      return 'Complete';
    }
    return location.locationName === '' ? 'NotStarted' : 'InProgress';
  };

  const isAllLocationsCompleted = useMemo(
    () => locationItems.every(item => item.completed === true) && locationItems.length > 0,
    [locationItems],
  );

  const pageDescription = useMemo(() => {
    if (isSalesForce && premiumListingOrderItems.length > 0) {
      return `Add minimum ${premiumListingOrderItems.length} 
      location${premiumListingOrderItems.length > 1 ? 's' : ''} to continue.`;
    }
    return 'Add up to nine locations. Must have at least one location to continue.';
  }, [premiumListingOrderItems]);

  const disableContinueForMediaProduct = useMemo(() => {
    if (isSalesForce && premiumListingOrderItems.length > 0) {
      return (cardCount !== premiumListingOrderItems.length) || !isAllLocationsCompleted;
    }
    return !isAllLocationsCompleted;
  }, [isAllLocationsCompleted, premiumListingOrderItems, cardCount]);

  useMemo(() => {
    if (isMediaProduct) {
      defaultPageLayoutItems = {
        skipAndContinue: false,
        disableContinue: disableContinueForMediaProduct,
        header: 'Locations',
        description: pageDescription,
      };
    } else {
      defaultPageLayoutItems = {
        skipAndContinue: true,
        disableContinue: false,
        header: 'Locations',
        description: 'Add up to nine locations. Optional.',
      };
    }
    if (isAllLocationsCompleted) {
      defaultPageLayoutItems = {
        skipAndContinue: false,
        disableContinue: false,
        header: 'Locations',
        description: 'Add up to nine locations. Optional.',
      };
    }
  }, [isMediaProduct, isAllLocationsCompleted, pageDescription, disableContinueForMediaProduct]);

  const showAddNewLocation = () => {
    if (isPunchlist) {
      return cardCount === 0;
    }

    if (isSalesForce && premiumListingOrderItems.length > 0) {
      return cardCount < premiumListingOrderItems.length;
    }
    return true;
  };

  return (
    <DefaultPageLayout
      disableContinue={defaultPageLayoutItems.disableContinue}
      onContinue={handleContinue}
      onBack={onBack}
      header={defaultPageLayoutItems.header}
      description={defaultPageLayoutItems.description}
      skipAndContinue={defaultPageLayoutItems.skipAndContinue}
    >
      {locationItems && React.Children.toArray([...locationItems].reverse().map(location => (
        <IQLocationPLCard
          field={location.sourceId}
          locationName={location.locationName}
          addressLine1={location.businessAddressOne}
          addressLine2={location.businessAddressTwo}
          city={location.city}
          state={location.stateProvince}
          zipcode={location.postalCode}
          locationStatus={getLocationStatus(location)}
          handleDelete={() => removeLocation(location)}
          onSelect={() => handleSelection(location)}
        />
      )))}
      <Box
        sx={{
          padding: '20px 20px 20px 0',
          backgroundColor: locations.length === 0 ? theme.palette.primary.light : 'initial',
        }}
      >
        {showAddNewLocation() && (
          <IQButtonLink
            startIcon={<AddIcon />}
            disabled={!isPunchlist ? cardCount > 8 : cardCount > 0}
            onClick={addNewItem}
          >
            Add New Location
          </IQButtonLink>
        )}
      </Box>
    </DefaultPageLayout>
  );
}
