import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { Trans, useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import { IQButtonLink } from '@gannettdigital/shared-react-components';
import NeStatusCard from 'components/statusCard/NeStatusCard';
import { useDispatch, useSelector } from 'react-redux';
import {
  createSalesAgent, selectAgents, getSalesAgents, deleteAgent, setAgents,
} from 'services/salesAgentSlice';
import { useEffect, useMemo, useState } from 'react';
import { Urls } from 'navigation/Urls';
import { useParams } from 'react-router';
import { Box, useTheme } from '@mui/material';
import CoModalConfirm from 'components/modals/CoModalConfirm';
import { getIsPremiumListingsHealthcare } from 'services/premiumListingsSlice';
import { OrderFlow } from 'shared/constants';
import { selectOrderFlow, selectOrdersContent } from 'services/ordersSlice';
import { defaultAgent, SalesAgent } from './SalesAgentData';

const defaultAgentToRemove = { agent: defaultAgent, index: -1 };

export default function SalesAgentDetails() {
  const navigate = useNavigationHandler();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const theme = useTheme();
  const [disableContinue, setDisableContinue] = useState(false);
  const orderFlow = useSelector(selectOrderFlow);
  const orderData = useSelector(selectOrdersContent);
  const isPunchListFlow = (orderFlow === OrderFlow.PUNCHLIST || orderData.orderFlow === OrderFlow.PUNCHLIST);

  useEffect(() => {
    dispatch(getSalesAgents(orderId));
    dispatch(getIsPremiumListingsHealthcare(orderId));
  }, []);

  const [showRemoveDialog, setShowRemoveDialog] = useState(false);
  const [agentToRemove, setAgentToRemove] = useState(defaultAgentToRemove);

  const salesAgents = useSelector(selectAgents);

  const openRemoveDialog = (agent, index) => {
    setAgentToRemove({ agent, index });
    setShowRemoveDialog(true);
  };

  const cancelAgentRemove = () => {
    setShowRemoveDialog(false);
    setAgentToRemove(defaultAgentToRemove);
  };

  const removeAgent = async () => {
    if (agentToRemove.agent.id) await dispatch(deleteAgent({ orderId, id: agentToRemove.agent.id }));
    const updatedList = salesAgents.slice();
    updatedList.splice(agentToRemove.index, 1);
    dispatch(setAgents(updatedList));
    setAgentToRemove(defaultAgentToRemove);
    setShowRemoveDialog(false);
  };

  const onAgentSelect = (agent) => {
    navigate.to(`${orderId}/salesReps/${agent.id}/edit`);
  };

  const verifyStatus = (agent: SalesAgent) => {
    const {
      salesAgentEmail,
      salesAgentName,
    } = agent;

    if (salesAgentName === '' && salesAgentEmail === '') {
      return 'notStarted';
    }

    if (salesAgentName !== '' && salesAgentEmail !== '') { return 'complete'; }

    return 'review';
  };

  const salesAgentCard = (agent: SalesAgent, index: number) => {
    const {
      id, salesAgentName, salesAgentPhoneNumber, salesAgentEmail,
    } = agent;
    const textColumns = [
      {
        title: t('pages.salesAgent.nameLabel'),
        placeholder: t('pages.salesAgent.namePlaceholder'),
        value: salesAgentName,
      },
      {
        title: t('pages.salesAgent.phoneLabel'),
        placeholder: t('pages.salesAgent.phonePlaceholder'),
        value: salesAgentPhoneNumber,
      },
      {
        title: t('pages.salesAgent.emailLabel'),
        placeholder: t('pages.salesAgent.emailPlaceholder'),
        value: salesAgentEmail,
      },
    ];

    return (
      <NeStatusCard
        key={id}
        textColumns={textColumns}
        status={verifyStatus(agent)}
        onDelete={() => openRemoveDialog(agent, index)}
        onSelect={() => onAgentSelect(agent)}
      />
    );
  };

  const agentsCompleted = useMemo(
    () => salesAgents.length > 0 && !salesAgents.some(agent => verifyStatus(agent) !== 'complete'),
    [salesAgents],
  );

  const addSalesAgent = async () => {
    await dispatch(createSalesAgent({ orderId, content: defaultAgent }));
    dispatch(getSalesAgents(orderId));
  };

  const onContinue = async () => {
    setDisableContinue(true);
    return navigate.to(!isPunchListFlow ? Urls.BusinessSearch : Urls.BusinessDetail);
  };

  return (
    <DefaultPageLayout
      onContinue={onContinue}
      header={t('pages.salesAgent.title')}
      description={t('pages.salesAgent.description')}
      disableContinue={!agentsCompleted || disableContinue}
      continueButtonLabel="Continue"
      disableBack
    >
      {salesAgents.map((agent, index) => salesAgentCard(agent, index))}
      <Box
        sx={{
          padding: '20px 20px 20px 0',
          backgroundColor: salesAgents.length === 0 ? theme.palette.primary.light : 'initial',
        }}
      >
        <IQButtonLink startIcon={<AddIcon />} onClick={addSalesAgent}>
          {t('pages.salesAgent.addSalesAgent')}
        </IQButtonLink>
      </Box>
      <CoModalConfirm open={showRemoveDialog}
        title={t('pages.salesAgent.removeModal.title')}
        description={(
          <Trans
            i18nKey="pages.salesAgent.removeModal.message"
            values={{
              name: agentToRemove.agent.salesAgentName,
              phone: agentToRemove.agent.salesAgentPhoneNumber,
            }}
            components={{ strong: <strong /> }}
          />
        )}
        confirmText="Remove"
        handleClose={cancelAgentRemove}
        handleConfirm={() => removeAgent()}
        className='sales-agent-deletion-confirm-dialog'
      />
    </DefaultPageLayout>
  );
}
