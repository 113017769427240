export const productNameMap = {
  'e-commerce microsite': 'E-Commerce Microsites',
  'website - landing page': 'Campaign Landing Pages',
  'website - custom landing page': 'Custom Landing Pages',
  'website - standard': 'Silver Websites',
  'website - gold': 'Gold Websites',
  'website - custom': 'Custom Websites',
  'website - lite': 'Lite Websites',
  'premium listing': 'Premium Listings',
  chat: 'Smart Chatbot Silver',
  'chat - platinum': 'Live Chat Platinum',
  'master addons': 'Add-Ons',
  'seo-standard': 'SEO',
  'seo-custom': 'SEO',
  'seo-blog-standard': 'SEO Products',
  'seo-blog-enhanced': 'SEO Products',
  'seo-topic-location': 'SEO Products',
  'seo-content-pages': 'SEO Products',
  'smm-facebook-posts': 'SMM Facebook Boosted Posts',
  'smm-instagram': 'SMM Add-on - Instagram',
  'smm-infographics': 'SMM Add-on - Infographics',
  'smm-standard': 'SMM Products',
  'smm-custom': 'SMM Products',
  'dmsnext-cc-by-dash': 'Customer Center',
  'dmsnext-cc': 'Customer Center',
  'smm-x': 'SMM X (Twitter)',
  'smm-pinterest': 'SMM Add-on - Pinterest',
  'custom-reporting': 'Custom Reporting',
  'smb-retail-gci-only-smart-listings': 'SMB Retail GCI Only - SmartListings',
  'smb-retail-gci-only-visible-presence': 'SMB Retail GCI Only - Visible Presence',
  'chat-spanish': 'Spanish Chat Add-on',
  'display-creative-services': 'Display Creative Services',
  'custom-solutions-spotify': 'Custom Solutions - Spotify',
  'custom-solutions-snapchat': 'Custom Solutions - Snapchat',
  'custom-solutions-pandora': 'Custom Solutions - Pandora',
  'custom-solutions-linkedin': 'Custom Solutions - Linkedin',
  'custom-solutions-twitter': 'Custom Solutions - Twitter',
  'video-creative-service-package': 'Video Creative Service Package',
  'targeted-email': 'Targeted Email',
  'social-ads-website-clicks': 'Social Ads Website Clicks',
  'rl-branded-content': 'RL Branded Content',
  'chat-hybrid': 'Chat Hybrid Addon',
  'custom-solutions-tiktok': 'Custom Solutions Tiktok',
  'custom-tracking': 'Custom Tracking',
  'google-tag-manager': 'Google Tag Manager',
  'local-service-ads': 'Local Service Ads',
  'shopping-ads': 'Shopping Ads',
  'site-retargeting-with-lookalikes-gdn': 'Site Retargeting With Lookalikes (GDN)',
  'smb-retail-gci-only-20-page-site': 'SMB Retail GCI Only - 20 Page Site',
  'smb-retail-gci-only-5-page-site': 'SMB Retail GCI Only - 5 Page Site',
  'smb-retail-gci-only-responsive-site': 'SMB Retail GCI Only - Responsive Site',
  'social-ads-lead-ads-with-detailed-targeting': 'Social Ads Lead Ads with Detailed Targeting',
  'social-ads-lead-ads': 'Social Ads Lead Ads',
  yelp: 'Yelp',
  youtube: 'Youtube',
  'targeted-display': 'Targeted Display',
  'social-ads-ad-engagement-add-on': 'Social Ads - Ad Engagement Add-On',
  'search-engine-marketing': 'Search Engine Marketing',
  'social-ads-custom': 'Social Ads - Custom',
  'social-ads-smart-optimization': 'Social Ads - Smart Optimization Technology',
  'social-ads-website-clicks-with-detailed-targeting': 'Social Ads Website Clicks with Detailed Targeting',
  'crm-integration-addon': 'CRM Integration Addon',
  'cs-waze-pilot': 'CS - Waze (Pilot)',
  'custom-campaign-landing-pages': 'Custom Campaign Landing Pages',
  'enhanced-reporting-add-on': 'Enhanced Reporting Add-On',
  'local-services-ads-management-services': 'Local Services Ads - Management Services',
  'national-sem': 'National - SEM',
  'performance-max-beta': 'Performance Max (PMAX BETA ONLY)',
  'website-managed-services': 'Website - Managed Services',
  'website-content-migration': 'Websites Content Migration',
  'premium-listings-ten-plus-physician-directories': 'Premium Listings 10 Plus Physician Directories',
  'premium-listings-10-plus': 'Premium Listings 10 Plus',
  'get-creative-service-fee': 'GetCreative Service Fee',
  'social-ads-retargeting': 'Social Ads Retargeting',
  'display-creative-services-custom': 'Display Creative Service - Custom',
  'targeted-display-legacy': 'Targeted Display Legacy',
  'website-add-on-content-pages': 'Website Add-On - Content Pages',
  xmo: 'XMO',
  'xmo-upgrade': 'XMO Upgrade',
  'promotions-page-and-creatives-reach-local-beta-only': 'Promotions Page And Creatives (ReachLocal Beta ONLY)',
  'promotions-media-mix': 'Promotions Media Mix',
  'aus-tiktok': 'Aus Tiktok',
  'aus-total-track': 'Aus Total Track'
};

export const seoProducts = ['seo-standard', 'seo-custom'];
export const seoBlogProducts = ['seo-blog-standard', 'seo-blog-enhanced'];
export const seoAddons = ['seo-topic-location', 'seo-content-pages'];
export const smmProducts = ['smm-standard', 'smm-custom'];
export const smmAddons = ['smm-facebook-posts', 'smm-instagram', 'smm-infographics', 'smm-x', 'smm-pinterest'];
export const dmsNextProducts = ['dmsnext-cc-by-dash', 'dmsnext-cc'];
export const customReporting = ['custom-reporting'];
export const displayCreativeServices = ['display-creative-services'];
export const displayCreativeServiceCustom = ['display-creative-services-custom'];
export const customSolutionsSpotify = ['custom-solutions-spotify'];
export const customSolutionsSnapchat = ['custom-solutions-snapchat'];
export const customSolutionsPandora = ['custom-solutions-pandora'];
export const customSolutionsLinkedin = ['custom-solutions-linkedin'];
export const customSolutionTiktok = ['custom-solutions-tiktok'];
export const customSolutionTwitter = ['custom-solutions-twitter'];
export const smbProducts = ['smb-retail-gci-only-smart-listings', 'smb-retail-gci-only-visible-presence',
  'smb-retail-gci-only-20-page-site', 'smb-retail-gci-only-5-page-site', 'smb-retail-gci-only-responsive-site'];
export const socialAds = ['social-ads-lead-ads', 'social-ads-ad-engagement-add-on',
  'social-ads-lead-ads-with-detailed-targeting', 'social-ads-smart-optimization', 'social-ads-custom',
  'social-ads-website-clicks', 'social-ads-website-clicks-with-detailed-targeting', 'social-ads-retargeting'];
export const videoCreativeServicePackage = ['video-creative-service-package'];
export const rlBrandedContent = ['rl-branded-content'];
export const customTracking = ['custom-tracking'];
export const googleTagManager = ['google-tag-manager'];
export const localServiceAds = ['local-service-ads'];
export const shoppingAds = ['shopping-ads'];
export const siteRetargetingWithLookalikesGDN = ['site-retargeting-with-lookalikes-gdn'];
export const yelp = ['yelp'];
export const youtube = ['youtube'];
export const sem = ['search-engine-marketing'];
export const targetedDisplay = ['targeted-display'];
export const crmIntegrationAddon = ['crm-integration-addon'];
export const csWazePilot = ['cs-waze-pilot'];
export const customCampaignLandingPages = ['custom-campaign-landing-pages'];
export const enhancedReportingAddon = ['enhanced-reporting-add-on'];
export const localServicesAdsManagementServices = ['local-services-ads-management-services'];
export const nationalSem = ['national-sem'];
export const performanceMaxBeta = ['performance-max-beta'];
export const website = ['website-managed-services', 'website-content-migration', 'website-add-on-content-pages'];
export const premiumListingsTenPlusPhysicianDirectories = ['premium-listings-ten-plus-physician-directories'];
export const getCreativeServiceFee = ['get-creative-service-fee'];
export const promotionsPageAndCreativesReachLocalBetaOnly = ['promotions-page-and-creatives-reach-local-beta-only'];
export const promotionsMixMedia = ['promotions-mix-media'];
export const ausTiktok = ['aus-tiktok'];
export const ausTotalTrack = ['aus-total-track'];

export const SMMOptions = [
  {
    value: 1,
    label: '10 posts - No Rep. Mgmt (SMO)',
    included: [
      '10 Social Posts on Facebook & Twitter or Instagram',
      '$150 of Facebook Boosted Posts',
      'Community Management',
      'Approval Portal & Monthly Report',
    ],
    plus: [],
  },
  {
    value: 2,
    label: '10 posts - With Rep. Mgmt (SMR)',
    included: [
      '10 Social Posts on Facebook & Twitter or Instagram',
      '$300 of Facebook Boosted Posts',
      'Community Management',
      'Approval Portal & Monthly Report',
      'Reputation Management',
      'Competitive Insights',
    ],
    plus: [],
  },
  {
    value: 3,
    label: '20 posts - With Rep. Mgmt (SMI)',
    included: [
      '20 Social Posts on Facebook & Twitter or Instagram',
      '$300 of Facebook Boosted Posts',
      'Community Management',
      'Approval Portal & Monthly Report',
      'Reputation Management',
      'Competitive Insights',
    ],
    plus: [],
  },
];

export const XMOOptions = [
  {
    value: 1,
    label: 'Lead Generator',
  },
  {
    value: 2,
    label: 'First Party Data Builder',
  },
  {
    value: 3,
    label: 'Brand Amplifier',
  },
];

export const XMOUpgradeOptions = [
  {
    value: 1,
    label: 'Lead Generator',
  },
];
