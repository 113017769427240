import * as yup from 'yup';
import i18n from 'i18n/i18n';
import { AttributeType } from './AttributeSelector';

const { t } = i18n;

const SHORT_DESCRIPTION_MAX_LENGTH = 200;
const LONG_DESCRIPTION_MAX_LENGTH = 750;

const schema = yup.object().shape({
  attributes: yup.array(yup.object({
    valueType: yup.string(),
    value: yup.mixed()
      .when(['valueType'], {
        is: (valueType: AttributeType) => valueType === AttributeType.REPEATED_ENUM,
        then: yup.array().of(yup.string()).required(),
        otherwise: yup.string().required().when(['valueType'], {
          is: (valueType: AttributeType) => valueType === AttributeType.URL,
          then: yup.string().url(t('pages.premiumListings.location.info.invalidUrl')).required(),
        }),
      }),
  })).notRequired(),
  shortDescription: yup.string().max(
    SHORT_DESCRIPTION_MAX_LENGTH,
    t('pages.premiumListings.location.info.descriptionError', { max: SHORT_DESCRIPTION_MAX_LENGTH }),
  ).notRequired().nullable(),
  longDescription: yup.string().max(
    LONG_DESCRIPTION_MAX_LENGTH,
    t('pages.premiumListings.location.info.descriptionError', { max: LONG_DESCRIPTION_MAX_LENGTH }),
  ).notRequired().nullable(),
  keywords: yup.array(yup.string()).max(20, t('pages.premiumListings.location.info.keywordsError'))
    .notRequired().nullable(),
});

export default {
  yupValidations: schema,
  customValidations: {
  },
};
