/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import * as yup from 'yup';
import { Button, useTheme } from '@mui/material';
import styled from '@emotion/styled';
import { IQFormInput, IQFormTextArea, ValidationProvider } from '@gannettdigital/shared-react-components';
import { FormProvider, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import GridContainer from 'components/grid/GridContainer';
import GridItem from 'components/grid/GridItem';
import { checkKeyDown } from 'shared/sharedFunctions';

interface Props {
  videos: any[]
  video?: any
  isOpen?: boolean
  onClose?: any
  onSubmit?: any
}

export default function PublishedVideoForm({
  videos, isOpen, onClose, onSubmit, video,
}: Props) {
  const theme = useTheme();
  const { t } = useTranslation();

  const defaultValues = {
    videoTitle: '',
    videoUrl: '',
    description: '',
  };

  // eslint-disable-next-line max-len
  const requiredVideoUrlFormats = /^(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?(?!youtube\.com\/live)((vimeo.com)|(youtu.be)|(youtube.com))\/?[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]*$/;
  const errorMessage = 'Video URL must be valid non-live url and one of vimeo.com, youtu.be, or youtube.com.';

  const videosToCompare = videos.filter(obj => obj.videoUrl !== video.data?.videoUrl);

  const schema = yup.object().shape({
    videoUrl: yup.string()
      .required(t('pages.premiumListings.location.photos.publishedVideos.videoUrlRequired'))
      .url(t('pages.premiumListings.location.photos.publishedVideos.videoUrlInvalid'))
      .matches(requiredVideoUrlFormats, errorMessage)
      .test(
        'isUnique',
        'This URL is already added',
        (value) => !videosToCompare.map(obj => obj.videoUrl).includes(value),
      ),
  });

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    getValues, reset, setValue, formState: { isValid },
  } = methods;

  useEffect(() => {
    if (Object.keys(video.data).length > 0) {
      setValue('videoTitle', video.data.videoTitle);
      setValue('videoUrl', video.data.videoUrl);
      setValue('description', video.data.description);
    }
  }, [video]);

  const handleSubmit = () => {
    onSubmit(getValues(), video.index);
    reset();
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  const SaveButton = styled(Button)`
    display: block;
    color: white; 
    padding: 0px;
    width: 245px;
    height: 40px;
    background: ${theme.palette.primary.main};
    border: 1px solid ${theme.palette.primary.main};
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);

    &:hover {
      background-color: ${theme.palette.primary.dark};
    }
  `;

  const CancelButton = styled(Button)`
    color: ${theme.palette.primary.main};
    font-family: 'Unify Sans', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-decoration-line: underline;
    text-transform: none;
    margin: 0 auto;
  `;

  const children = (
    <FormProvider {...methods}>
      <form onKeyDown={(e) => checkKeyDown(e)}>
        <ValidationProvider schema={schema}>
          <GridContainer fullWidth sx={{ width: '775px' }}>
            <GridItem>
              <IQFormInput
                name="videoTitle"
                id="videoTitle"
                labelText={t('pages.premiumListings.location.photos.publishedVideos.videoTitle')}
                fontLabelWeight="bold"
                theme={theme}
                fullWidth
              />
            </GridItem>
            <GridItem>
              <IQFormInput
                name="videoUrl"
                id="videoUrl"
                labelText={t('pages.premiumListings.location.photos.publishedVideos.videoUrl')}
                fontLabelWeight="bold"
                placeholder={t('pages.premiumListings.location.photos.publishedVideos.videoUrlPlaceholder')}
                tooltipText={t('pages.premiumListings.location.photos.publishedVideos.videoUrlTooltipText')}
                tooltipPlacement="top"
                tooltipPaddingBottom={18}
                schema={schema}
                theme={theme}
                fullWidth
              />
            </GridItem>
            <GridItem>
              <IQFormTextArea
                name="description"
                id="description"
                labelText={t('pages.premiumListings.location.photos.publishedVideos.videoDescription')}
                fontLabelWeight="bold"
                fullWidth
              />
            </GridItem>
            <GridItem sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
              <SaveButton onClick={handleSubmit} disabled={!isValid}>
                {t('pages.premiumListings.location.photos.publishedVideos.saveVideo')}
              </SaveButton>
            </GridItem>
            <GridItem sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
              <CancelButton onClick={handleClose}>
                {t('pages.premiumListings.location.photos.publishedVideos.close')}
              </CancelButton>
            </GridItem>
          </GridContainer>
        </ValidationProvider>
      </form>
    </FormProvider>
  );

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      { isOpen && children}
    </>
  );
}
