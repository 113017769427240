import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import { Urls } from 'navigation/Urls';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectOrderItems } from 'services/ordersSlice';
import { selectIsPremiumListingsHealthcare } from 'services/premiumListingsSlice';
import { sortOrderItems } from 'services/menus';
import { putOrderItemsWithCampaignId } from 'services/campaignSlice';
import { useMemo } from 'react';
import { useLocation } from 'react-router';
import CampaignIdSelect from './CampaignIdSelect';
import { MapOfferingTypesToProductLabel } from './CampaignIds';

type CampaignType = {
  cid: number;
  name: string;
  status: string;
};

interface Props {
  campaignData: CampaignType[];
}

export default function CampaignIdsForm({ campaignData }: Props) {
  const navigate = useNavigationHandler();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history: any = useLocation();
  const { content: orderItems } = useSelector(selectOrderItems);
  const isHealthCareOrder = useSelector(selectIsPremiumListingsHealthcare);
  const isRedirectedFromOverviewPage = useMemo(() => history.state?.previousPath.includes('overview'), [history]);

  const methods = useForm({
    mode: 'all',
  });

  const {
    register, getValues, handleSubmit, formState: {
      isValid, isDirty, isSubmitting, isSubmitSuccessful, errors,
    },
  } = methods;

  const onBack = () => {
    navigate.to(Urls.BusinessClient);
  };

  const onSubmit = () => {
    const formData = getValues();
    const resource = { itemCampaigns: [] };

    Object.keys(formData).forEach(value => resource.itemCampaigns.push(
      { campaignId: formData[value], itemId: Number(value) },
    ));

    dispatch(putOrderItemsWithCampaignId(resource));

    const isPremiumListing = orderItems.some(item => item.offeringType === 'PremiumListing');

    if (isRedirectedFromOverviewPage) return navigate.to(Urls.Overview);

    if (isPremiumListing && isHealthCareOrder) {
      return navigate.to(Urls.PlHealthcareLocations);
    }
    return navigate.to(Urls.BusinessLocation);
  };

  let orderData = (orderItems?.length > 0) ? sortOrderItems(orderItems, true) : [];
  orderData = orderData.filter(item => item.offeringType !== 'MasterAddon');

  // eslint-disable-next-line no-nested-ternary
  const getOfferingType = (obj, pdName = false) => (obj.offeringType === 'ProductsData'
    ? (pdName ? obj.pdName : obj.pdOfferingType)
    : obj.offeringType);

  return (
    <DefaultPageLayout
      header={t('pages.business.campaignIds.title')}
      description={t('pages.business.campaignIds.description')}
      disableContinue={!isValid}
      onContinue={handleSubmit(onSubmit)}
      onBack={onBack}
    >
      <FormProvider {...methods}>
        {
          orderData.map((obj) => (
            <CampaignIdSelect
              key={obj.id}
              label={MapOfferingTypesToProductLabel[getOfferingType(obj)] || getOfferingType(obj, true)}
              name={obj.id.toString()}
              data={campaignData}
              offeringType={getOfferingType(obj)}
              register={register}
              errors={errors}
              value={obj.campaignId}
            />
          ))
        }
      </FormProvider>
      <CoNavigationConfirm
        showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
      />
    </DefaultPageLayout>
  );
}
