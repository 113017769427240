import { IQFormTextArea, ValidationProvider } from '@gannettdigital/shared-react-components';
import { Grid, Typography } from '@mui/material';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import CoFileUploader, { AllUploaders } from 'components/fileUploader/CoFileUploader';
import { FileUploadDirectories, ProductTypes, selectFilesUploaded } from 'services/fileUploaderSlice';
import { WebsiteOfferingTypes } from 'services/websitesSlice';
import { Urls } from 'navigation/Urls';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import {
  getCustomLandingPage,
  selectAdditionalCustomLandingPage,
  setAdditional,
} from 'services/customLandingPageSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { selectActiveProductId } from 'services/navigationSlice';
import schema from './AdditionalInfo.schema';

export default function CustomLandingPageAdditionalInfo() {
  const { t } = useTranslation();
  const navigate = useNavigationHandler();
  const { orderItemId } = useParams();
  const websiteId = useSelector(selectActiveProductId);
  const dispatch = useDispatch();
  const additional = useSelector(selectAdditionalCustomLandingPage);
  const filesUploaded = useSelector(selectFilesUploaded, (prev, curr) => prev.content.length === curr.content.length);

  const fileUploaderPropsAddInfo : AllUploaders['fileUploaderOptions'] = {
    name: 'additionalInfoAssets',
    directory: FileUploadDirectories.ECOMMERCE,
    label: t('pages.landing.custom.additional.assets'),
    supportingText: t('pages.landing.custom.additional.assetsDescription'),
    showRecommendedImageText: false,
    showCheckboxes: false,
    showComments: false,
    imageTypes: ['.png', '.jpg', '.jpeg', '.gif', '.CR2', '.DNG'],
    videoTypes: ['.mp4'],
    documentTypes: ['.pdf', '.docx'],
    maxFileSize: 256 * 1_000_000,
    uploaderType: 'additionalInfoAssets',
    withLabelError: true,
  };

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      additionalInfo: '',
      additionalInfoAssets: [],
    },
  });

  const { setValue, getValues, trigger } = methods;

  useEffect(() => {
    if (orderItemId) {
      dispatch(getCustomLandingPage(orderItemId));
    }
  }, [orderItemId]);

  useEffect(() => {
    if (additional) {
      setValue('additionalInfo', additional.additionalInfo);
    }
  }, [additional]);

  useEffect(() => {
    if (filesUploaded.content.length > 0) {
      const filterFiles = filesUploaded.content.filter(obj => obj.uploaderType === 'additionalInfoAssets');
      setValue('additionalInfoAssets', filterFiles.map(file => JSON.stringify(file)));
      trigger('additionalInfoAssets');
    }
  }, [filesUploaded]);

  const handleContinue = () => {
    const listOfUrls: string[] = filesUploaded.content.map((obj) => JSON.stringify(obj));
    const addDetailsData = {
      additionalInfo: getValues('additionalInfo'),
      attachments: listOfUrls || [],
    };

    dispatch(setAdditional({ orderItemId, addDetailsData }));

    navigate.to(
      Urls.Overview,
    );
  };

  const onBack = () => {
    navigate.to(Urls.LandingCustomDevelopment);
  };

  return (
    <DefaultPageLayout
      onContinue={handleContinue}
      onBack={onBack}
      header={t('pages.landing.custom.additional.title')}
      continueButtonLabel={t('form.buttons.continue')}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <Grid container>
            <Grid item xs={6} marginBottom={5}>
              <Typography fontSize="18px">{t('pages.landing.custom.additional.subTitle')}</Typography>
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={6} marginBottom={5}>
              <IQFormTextArea
                id="additionalInfo"
                name="additionalInfo"
                labelText={t('pages.landing.custom.additional.additionalInfo')}
                fontLabelWeight="bold"
                rowCount={3}
                required
                fullWidth
                showError
              />
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={12}>
              <CoFileUploader
                schema={schema}
                productId={websiteId?.toString()}
                productType={ProductTypes.EXISTING_ASSETS}
                showFileUploader
                fileUploaderOptions={fileUploaderPropsAddInfo}
                offeringType={WebsiteOfferingTypes.LANDING_PAGES}
                containerStyles={{
                  xs: 6,
                }}
              />
            </Grid>
          </Grid>
        </FormProvider>
      </ValidationProvider>
    </DefaultPageLayout>
  );
}
