/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import { RJSFSchema } from '@rjsf/utils';
import i18n from 'i18n/i18n';
import FileUploader from 'pages/generic-product/details/custom-fields/FileUploader';
import { ProductTypes } from 'services/fileUploaderSlice';
import HeadingTextLabel from '../custom-fields/HeadingTextLabel';
import TextField from '../custom-fields/TextField';

const { t } = i18n;
export const PremiumListing10PlusSchema: RJSFSchema | any = (data) => ({
  title: t('pages.premiumlisting10plus.headerLabel'),
  type: 'object',
  required: [],
  properties: {
    clientInformationTitle: {
      type: 'string',
      title: t('pages.premiumlisting10plus.pageTitle'),
    },
    clientContactFirstName: {
      type: 'string',
      title: t('pages.premiumlisting10plus.clientContactFirstNameLabel'),
      default: data?.clientContactFirstName || '',
    },
    clientContactLastName: {
      type: 'string',
      title: t('pages.premiumlisting10plus.clientContactLastNameLabel'),
      default: data?.clientContactLastName || '',
    },
    clientContactPhoneNumber: {
      type: 'string',
      title: t('pages.premiumlisting10plus.clientContactPhoneLabel'),
      default: data?.clientContactPhoneNumber || '',
    },
    clientContactEmail: {
      type: 'string',
      title: t('pages.premiumlisting10plus.clientContactEmailLabel'),
      default: data?.clientContactEmail || '',
      description: t('pages.premiumlisting10plus.clientContactEmailHelperText'),
    },
    bestNumberToReachSeller: {
      type: 'string',
      title: t('pages.premiumlisting10plus.clientBestNumberToReachYouLabel'),
      default: data?.bestNumberToReachSeller || '',
    },
    listingBudgetForClientBudget: {
      type: 'string',
      title: t('pages.premiumlisting10plus.howManyListingIncludedInClientBudgetLabel'),
      default: data?.listingBudgetForClientBudget || '',
    },
    attachPL10AddressNameList: {
      type: 'string',
      title: t('pages.premiumlisting10plus.pleaseAttachPL10AddressAndNameLabel'),
    },
    uploader_10PlusAddressNameListFile: {
      type: 'string',
      title: t('pages.premiumlisting10plus.uploadLabel'),
      default: '',
    },
    templateLinkHere: {
      type: 'string',
      title: t('pages.premiumlisting10plus.templateLinkHereLabel'),
      target: '_blank',
    },
    typesOfContentCollectionClientProceedingWith: {
      type: 'string',
      title: t('pages.premiumlisting10plus.typesOfContentCollectionClientProceedingWithLabel'),
      items: {
        enum: [
          t('pages.premiumlisting10plus.collectionProceedingOptions.importDoc'),
          t('pages.premiumlisting10plus.collectionProceedingOptions.gmbConection'),
          t('pages.premiumlisting10plus.collectionProceedingOptions.yextUpload'),
        ],
      },
    },
    notesAndInstructions: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.notesAndInstructions.notesAndInstructionsLabel'),
      default: data?.notesAndInstructions || '',
    },
  },
  dependencies: {
    typesOfContentCollectionClientProceedingWith: {
      oneOf: [
        {
          properties: {
            typesOfContentCollectionClientProceedingWith: {
              enum: ['Import Doc'],
            },
            text1: {
              type: 'string',
              title: t('pages.premiumlisting10plus.textLabel1'),
            },
            uploader_ImportDocumentForClientSmoothOnboardingFile: {
              type: 'string',
              title: t('pages.premiumlisting10plus.attachLabel'),
              default: '',
            },
            importDocTemplateUploadGuid: {
              type: 'string',
              title: t('pages.premiumlisting10plus.importDocTemplateUploadLabel'),
              target: '_blank',
            },
          },
        },
        {
          properties: {
            typesOfContentCollectionClientProceedingWith: {
              enum: ['GMB Connection'],
            },
            clientProvidedLocaliqOrgAccessToTheirGMBLocationGroup: {
              type: 'string',
              title: t('pages.premiumlisting10plus.clientProvidedLOCALIQOrgAccessToTheirGMBLocationGroupLabel'),
              default: data?.clientProvidedLocaliqOrgAccessToTheirGMBLocationGroup || '',
              items: {
                enum: [
                  t('pages.premiumlisting10plus.collectionForClientProvidedAccessToLOCALIQOptions.yes'),
                  t('pages.premiumlisting10plus.collectionForClientProvidedAccessToLOCALIQOptions.no'),
                ],
              },
            },
            thisOptionCanOnlyBeUsedLong: {
              type: 'string',
              title: t('pages.premiumlisting10plus.thisOptionCanOnlyBeUsedLongLabel'),
              target: '_blank',
            },
          },
        },
        {
          properties: {
            typesOfContentCollectionClientProceedingWith: {
              enum: ['Yext Upload'],
            },
            text2: {
              type: 'string',
              title: t('pages.premiumlisting10plus.textLabel2'),
            },
            uploader_YextUploadForOsToAuditFile: {
              type: 'string',
              title: t('pages.premiumlisting10plus.attachLabel'),
              default: '',
            },
          },
        },
      ],
    },
  },
});
const headingFieldKeys = [
  'clientInformationTitle',
];
const headingFields = headingFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'headingTextLabel' },
}), {});
const textFieldKeys = [
  'clientContactFirstName',
  'clientContactLastName',
  'listingBudgetForClientBudget',
];
const textFields = textFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'textInputField' },
}), {});
const phoneNumberTextFieldKeys = [
  'clientContactPhoneNumber',
  'bestNumberToReachSeller',
];
const phoneNumberTextFields = phoneNumberTextFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'phoneNumberTextField' },
}), {});
const emailTextFieldKeys = [
  'clientContactEmail',
];
const emailTextFields = emailTextFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'EmailTextWidget' },
}), {});

const boldHeadingTextLabelKeys = [
  'attachPL10AddressNameList',
  'templateLinkHere',
  'text1',
  'text2',
  'importDocTemplateUploadGuid',
  'thisOptionCanOnlyBeUsedLong',
];
const textAreaFieldKeys = [
  'notesAndInstructions',
];
const textAreaFields = textAreaFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': (props) => <TextField {...props} rows={8} /> },
}), {});
const boldHeadingTextLabels = boldHeadingTextLabelKeys.reduce((o, key) => ({
  ...o,
  [key]: {
    'ui:widget': (props) => {
      if (key === 'templateLinkHere') {
        return (
          <a href="https://gannett-my.sharepoint.com/:x:/p/olga_barrocas/EZShqhXBwkNKlq-f6-v0WGQBgZ1QLoK0-8z_pQSMGNLNrQ" target="_blank" rel="noreferrer">
            <HeadingTextLabel {...props} fontSize="20px" />
          </a>
        );
      }
      if (key === 'thisOptionCanOnlyBeUsedLong') {
        return (
          <a href="https://gannett.sharepoint.com/sites/TheSource/ProductMarketing/Public/Forms/AllItems.aspx?id=%2Fsites%2FTheSource%2FProductMarketing%2FPublic%2FListings%20Management%2FLOCALiQ%20Listings%20Management%2FPremium%20Listings%20Launch%2FArchive%2F2021%5F01789%5FLIQ%5FListings%5FGMB%20Access%2Epdf&parent=%2Fsites%2FTheSource%2FProductMarketing%2FPublic%2FListings%20Management%2FLOCALiQ%20Listings%20Management%2FPremium%20Listings%20Launch%2FArchive&p=true&ga=1" target="_blank" rel="noreferrer">
            <HeadingTextLabel {...props} fontSize="15px" />
          </a>
        );
      }
      if (key === 'importDocTemplateUploadGuid') {
        return (
          <a href="https://gannett.sharepoint.com/:w:/s/TheSource/ProductMarketing/Ee-n23QBQRFEupxMwja3f8EB94348nizC0E-SMRIpDVgRQ" target="_blank" rel="noreferrer">
            <HeadingTextLabel {...props} fontSize="15px" />
          </a>
        );
      }
      return <HeadingTextLabel {...props} fontSize="20px" />;
    },
  },
}), {});
const radioFieldKeys = [
  'typesOfContentCollectionClientProceedingWith',
  'clientProvidedLocaliqOrgAccessToTheirGMBLocationGroup',
];
const radioFields = radioFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'radioButton' },
}), {});
export const PremiumListing10PlusUIOrderSchema = {
  'ui:order': ['clientInformationTitle', 'clientContactFirstName', 'clientContactLastName', 'clientContactPhoneNumber',
    'clientContactEmail', 'bestNumberToReachSeller', 'listingBudgetForClientBudget', 'attachPL10AddressNameList',
    'uploader_10PlusAddressNameListFile', 'templateLinkHere', 'typesOfContentCollectionClientProceedingWith', 'text1', 'text2',
    'clientProvidedLocaliqOrgAccessToTheirGMBLocationGroup', 'thisOptionCanOnlyBeUsedLong',
    'uploader_ImportDocumentForClientSmoothOnboardingFile', 'uploader_YextUploadForOsToAuditFile', 'importDocTemplateUploadGuid', 'notesAndInstructions',
  ],
  ...headingFields,
  ...textFields,
  ...phoneNumberTextFields,
  ...emailTextFields,
  ...boldHeadingTextLabels,
  ...radioFields,
  ...textAreaFields,

  uploader_10PlusAddressNameListFile: {
    'ui:style': {
      marginTop: '16px',
    },
    'ui:widget': (props) => (
      <FileUploader
        {...props}
        uploaderType={ProductTypes.CLIENT_UPLOADER_FILE}
        totalAllowedFiles={1}
        imageTypes={[]}
        videoTypes={[]}
        documentTypes={['.xlsx', '.xls', '.ods', '.csv']}
        supportingText=""
      />
    ),
  },
  uploader_ImportDocumentForClientSmoothOnboardingFile: {
    'ui:style': {
      marginTop: '16px',
    },
    'ui:widget': (props) => (
      <FileUploader
        {...props}
        uploaderType={ProductTypes.CLIENT_IMPORT_ATTACH_FILE}
        totalAllowedFiles={1}
        imageTypes={[]}
        videoTypes={[]}
        documentTypes={['.docx', '.doc', '.pdf']}
        supportingText=""
      />
    ),
  },
  uploader_YextUploadForOsToAuditFile: {
    'ui:style': {
      marginTop: '16px',
    },
    'ui:widget': (props) => (
      <FileUploader
        {...props}
        uploaderType={ProductTypes.CLIENT_YEXT_ATTACH_FILE}
        totalAllowedFiles={1}
        imageTypes={[]}
        videoTypes={[]}
        documentTypes={['.xlsx', '.xls', '.ods', '.csv']}
        supportingText=""
      />
    ),
  },
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const PremiumListing10PlusCustomValidate = (formData: any, errors: any, uiSchema: any) => errors;
