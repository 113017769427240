/* eslint-disable no-console */
/* eslint-disable max-len */
import {
  Box, Button, Chip, FormControl, Grid, IconButton, InputLabel, MenuItem, OutlinedInput, Paper, Select, SelectChangeEvent, Theme, Typography,
  useTheme,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import { FormProvider, useForm } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from 'services/redux/store';
import {
  getChickletUsers, updateUserRoles,
} from 'services/userSlice';
import {
  DataGrid, GridColDef,
} from '@mui/x-data-grid';
import { backgroundDefaultColor } from 'styles/common_styles';
import CoModalPageCard from 'components/pageCard/CoModalPageCard';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function ManageUsers() {
  const appDispatch = useAppDispatch();
  const [coreUsers, setCoreUsers] = useState([]);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [activeUser, setActiveUser] = useState([]);
  const [activeEmail, setActiveEmail] = useState(null);
  const [activeRoles, setActiveRoles] = useState(null);
  const [thisUsersRoles, setThisUsersRoles] = useState(null);
  const [invalidRole, setInvalidRole] = useState(true);

  const methods = useForm({
    mode: 'all',
    defaultValues: { email: '', id: '', roleName: '' },
  });

  useEffect(() => {
    if (!activeRoles) {
      setInvalidRole(true);
    }
  }, [activeRoles]);

  const clearInvalidRoleStatus = () => { setInvalidRole(false); };

  const fetchUserData = () => {
    appDispatch(getChickletUsers(30000)).then(res => setCoreUsers([...coreUsers, ...res.payload.content]));
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  let queryString = '';
  let userSelectedRoles = '';
  // eslint-disable-next-line consistent-return
  const buildRolesQuery = (selectedRoles) => {
    const numberOfRoles = selectedRoles.length;

    if (numberOfRoles === 1) {
      // just send the role
      queryString = selectedRoles;
      return queryString;
    }

    if (numberOfRoles > 1) {
      userSelectedRoles = selectedRoles.toString();
      userSelectedRoles = userSelectedRoles.replaceAll(',', '&userRoles=');
      return userSelectedRoles;
    }
  };

  const roles = [
    'ROLE_ADMIN',
    'ROLE_MANAGER',
    'ROLE_FULFILLMENT',
    'ROLE_CARBON',
    'ROLE_SALES',
    'ROLE_PARTNER_CEF',
  ];

  function getStyles(role: string, roleName: string[], theme: Theme) {
    return {
      fontWeight:
          roleName.indexOf(role) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
    };
  }

  const theme = useTheme();
  const [roleName, setRoleName] = React.useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof roleName>) => {
    const {
      target: { value },
    } = event;

    if (value.length !== 0) {
      clearInvalidRoleStatus();
    }

    if (value.length === 0) {
      setInvalidRole(true);
    }
    setRoleName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    setActiveRoles(value);
  };

  const [rows, setRows] = useState([]);

  const buildGridRows = () => {
    // eslint-disable-next-line array-callback-return
    coreUsers.map((u) => {
      if (u.id === undefined) console.log('undefined ID');
      let newUser = {};
      newUser = {
        id: u[0], firstName: u[1], lastName: u[2], email: u[3], userRoles: u[4],
      };
      console.log('new user: ', newUser);
      setRows(rows => [...rows, newUser]);
    });
  };

  console.log('rows: ', rows);

  const closeEditModal = () => {
    setRoleName([]);
    setOpenEditModal(false);
  };

  useEffect(() => {
    if (coreUsers) {
      buildGridRows();
    }
  }, [coreUsers]);

  const handleEdit = (row) => {
    setActiveUser(row.id);
    setActiveEmail(row.email);
    setActiveRoles(row.userRoles);
    setThisUsersRoles(row.userRoles);
    setOpenEditModal(true);
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'email', headerName: 'Email', width: 280 },
    { field: 'firstName', headerName: 'First Name', width: 150 },
    { field: 'lastName', headerName: 'Last Name', width: 150 },
    { field: 'userRoles', headerName: 'Neon Roles', width: 380 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <IconButton
          color="primary"
          onClick={() => handleEdit(params.row)}
        >
          <EditIcon />
        </IconButton>
      ),

    },
  ];

  const submitUpdate = (activeUser, selectedRoles) => {
    const selectedRolesQuery = buildRolesQuery(selectedRoles);
    const id = activeUser;
    const roleData = { id, roleQuery: selectedRolesQuery };
    appDispatch(updateUserRoles(roleData));
    closeEditModal();
    window.location.reload();
  };

  const updateNeonRoles = () => {
    const newQueryString = buildRolesQuery(activeRoles);
    submitUpdate(activeUser, newQueryString);
  };

  return (
    <Paper elevation={0} sx={{ backgroundColor: backgroundDefaultColor }}>
      <Typography variant="h2" mb={3}>Manage User Roles</Typography>
      <Box
        sx={{
          height: '50%',
          width: '100%',
          '& .actions': {
            color: 'text.secondary',
          },
          '& .textPrimary': {
            color: 'text.primary',
          },
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          disableRowSelectionOnClick
          sx={{
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
          }}
        />
        <CoModalPageCard title="Assign Neon User Roles" isOpen={openEditModal} onClose={closeEditModal}>
          <div>{`Current Roles for ${activeEmail}: ${thisUsersRoles !== (undefined || null) ? thisUsersRoles : 'No roles'}`}</div>
          <FormProvider {...methods}>
            <FormControl sx={{ m: 1, width: 600 }}>
              <InputLabel sx={{ width: 150 }} id="demo-multiple-chip-label">Roles</InputLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id="roleName"
                name="roleName"
                multiple
                value={roleName}
                onChange={handleChange}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {roles.map((role) => (
                  <MenuItem
                    key={role}
                    value={role}
                    style={getStyles(role, roleName, theme)}
                  >
                    {role}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Grid item xs={12} style={{ textAlign: 'center', marginTop: 12 }}>
              <Button variant="contained"
                disabled={invalidRole}
                onClick={updateNeonRoles}
              >
                <Typography>Save</Typography>
              </Button>
            </Grid>
          </FormProvider>
        </CoModalPageCard>
      </Box>
    </Paper>
  );
}
