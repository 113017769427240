/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { IQCheckbox, ValidationProvider } from '@gannettdigital/shared-react-components';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useEffect, useState, useMemo } from 'react';
import { useParams, useLocation } from 'react-router';
import { Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import NeSelectChip from 'components/chips/NeSelectChip';
import GridContainer from 'components/grid/GridContainer';
import GridItem from 'components/grid/GridItem';
import {
  getBusinessLocation, selectCurrentLocation, updateLocationInfo,
} from 'services/businessLocationSlice';
import {
  getCategoryAttributes, getOrderBusinessByOrderId, selectCategoryAttributes, selectOrderBusiness,
} from 'services/businessSlice';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import { TitleWithBadge } from 'pages/premium-listings/TitleWithBadge';
import { locationsTypes } from 'layouts/StepperLayout';
import { OrderFlow } from 'shared/constants';
import { selectOrdersContent } from 'services/ordersSlice';
import { checkKeyDown } from 'shared/sharedFunctions';
import Attributes from './Attributes';
import CountdownTextArea from './CountdownTextArea';

export default function LocationInfo(props: any) {
  const { schema, type, defaultValues } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { orderId, locationId } = useParams();
  const location = useSelector(selectCurrentLocation);
  const business = useSelector(selectOrderBusiness);
  const attributesList = useSelector(selectCategoryAttributes);
  const [keywordsItems, setKeywordsItems] = useState([]);
  const history: any = useLocation();
  const isRedirectedFromOverviewPage = useMemo(() => history.state?.previousPath.includes('overview'), [history]);

  const isPl = ([locationsTypes.PREMIUM_LISTINGS, locationsTypes.HEALTHCARE].includes(type) && !location?.isProvider);
  const ordersContent = useSelector(selectOrdersContent);
  const { orderFlow } = ordersContent;
  const [isPunchlist, setIsPunchlist] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    orderFlow === OrderFlow.PUNCHLIST ? setIsPunchlist(true) : setIsPunchlist(false);
  }, []);

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(schema.yupValidations),
    defaultValues: {
      ...defaultValues,
    },
  });

  const {
    handleSubmit,
    formState: {
      isValid, isDirty, isSubmitSuccessful, isSubmitting,
    },
    setValue,
    getValues,
  } = methods;

  useEffect(() => {
    dispatch(getOrderBusinessByOrderId(orderId));
  }, [orderId]);

  useEffect(() => {
    dispatch(getBusinessLocation(locationId));
    dispatch(getCategoryAttributes(locationId));
  }, [locationId]);

  useEffect(() => {
    if (business && isPl) setValue('sameInfo', business.content.sameInfo);
  }, [business, isPl]);

  useEffect(() => {
    if (location && attributesList) {
      setValue('shortDescription', location.shortDescription || '');
      setValue('longDescription', location.longDescription || '');

      if (location?.categoryAttributes !== undefined) {
        const mapAttributes = location.categoryAttributes.map(att => {
          if (att.valueType === 'ENUM' && att.name === 'Appointment Type') {
            return {
              id: att.externalId,
              displayName: att.name,
              valueType: att.valueType,
              value: att.value,
              valueList: [
                {
                  value: 'regular',
                  description: 'Regular',
                },
                {
                  value: 'urgent',
                  description: 'Urgent',
                },
              ],
            };
          }
          if (att.valueType === 'ENUM' && att.name === 'Wi-Fi') {
            return {
              id: att.externalId,
              displayName: att.name,
              valueType: att.valueType,
              value: att.value,
              valueList: [
                {
                  value: 'paid_wi_fi',
                  description: 'Paid',
                },
                {
                  value: 'free_wi_fi',
                  description: 'Free',
                },
              ],
            };
          }
          if (att.valueType === 'REPEATED_ENUM' && att.name === 'Credit cards') {
            return {
              id: att.externalId,
              displayName: att.name,
              valueType: att.valueType,
              value: att.value.split(','),
              valueList: attributesList?.filter(a => a.displayName === 'Credit cards')[0]?.valueList,
            };
          }
          return {
            id: att.externalId,
            displayName: att.name,
            value: att.value,
            valueType: att.valueType,
          };
        });
        setValue('attributes', mapAttributes);
        mapAttributes.forEach((attribute, index) => {
          setValue(`attributes.${index}`, attribute);
          setValue(`attributes.${index}.value`, attribute.value);
        });
      }
      if (location.keywordsList) {
        setKeywordsItems(location.keywordsList.map(k => ({ label: k.name, value: k.name })));
        setValue('keywords', location.keywordsList.map(k => k.name));
        location.keywordsList?.forEach((keyword, index) => {
          setValue(`keywords.${index}`, keyword.name);
        });
      }
    }
  }, [location, attributesList]);

  const handleValue = (val) => {
    if (Array.isArray(val)) {
      return val.toString();
    }
    return val;
  };

  const onFormSubmit = async () => {
    const values = getValues();
    const resourceHc = {
      categoryAttributes: values.attributes.map(el => ({
        name: el.displayName,
        externalId: el.id,
        value: handleValue(el.value),
        valueType: el.valueType,
      })),
      keywords: values.keywords.map(k => ({ name: k })),
      longDescription: values.longDescription,
      shortDescription: values.shortDescription,
    };

    const resourcePl = {
      ...resourceHc,
      sameForAll: values.sameInfo,
    };
    const resource = isPl ? resourcePl : resourceHc;
    await dispatch(updateLocationInfo({ locationId, resource }));

    props.onContinue();
  };

  useEffect(() => {
    props.onUpdateStatus(getValues(), isValid);
  }, [isValid]);

  return (
    <ValidationProvider schema={schema}>
      <FormProvider {...methods}>
        <form id={props.formId} onSubmit={handleSubmit(onFormSubmit)} onKeyDown={(e) => checkKeyDown(e)}>
          <GridContainer fullWidth>
            <GridItem marginBottom={0}>
              <Typography variant="overline" marginBottom={0}
                sx={{ textTransform: 'initial', fontSize: '18px', color: 'text.secondary' }}
              >
                Locations
              </Typography>
            </GridItem>
            <GridItem>
              <Typography variant="h2" marginBottom={2}>{t('pages.premiumListings.location.info.title')}</Typography>
            </GridItem>
            {location.categoryAttributes !== undefined && <Attributes />}
            <GridItem sizes={[12]} marginTop={3}>
              <TitleWithBadge
                title={t('pages.premiumListings.location.info.descriptionsTitle')}
                badge={t('pages.premiumListings.badge')}
                tooltip={t('pages.premiumListings.badgeTooltip')}
              />
            </GridItem>
            <GridItem sizes={[12]}>
              <CountdownTextArea
                name="shortDescription"
                id="shortDescription"
                labelText={t(isPl
                  ? 'pages.premiumListings.location.info.shortDescription'
                  : 'pages.premiumListings.location.healthcare.info.shortDescription')}
                tooltipText={t(isPl
                  ? 'pages.premiumListings.location.info.shortDescriptionTooltip'
                  : 'pages.premiumListings.location.healthcare.info.shortDescriptionTooltip')}
                limit={200}
                theme={theme}
              />
            </GridItem>
            <GridItem sizes={[12]}>
              <CountdownTextArea
                name="longDescription"
                id="longDescription"
                labelText={t(isPl
                  ? 'pages.premiumListings.location.info.longDescription'
                  : 'pages.premiumListings.location.healthcare.info.longDescription')}
                tooltipText={t(isPl
                  ? 'pages.premiumListings.location.info.longDescriptionTooltip'
                  : 'pages.premiumListings.location.healthcare.info.longDescriptionTooltip')}
                limit={750}
                theme={theme}
              />
            </GridItem>
            <GridItem sizes={[12]} marginTop={5}>
              <TitleWithBadge
                title={t('pages.premiumListings.location.info.additionalInfo')}
                badge={t('pages.premiumListings.badge')}
                tooltip={t('pages.premiumListings.badgeTooltip')}
              />
            </GridItem>
            <GridItem sizes={[12]}>
              <NeSelectChip label labelText={t('pages.premiumListings.location.info.keywords')} items={keywordsItems}
                cleanKeywordItems
                name="keywords" values={[]} maxItems={20}
                disableClear required={false} schema={schema}
                placeholder={t('pages.premiumListings.location.info.keywords')}
                labelTooltipText={t('pages.premiumListings.location.info.keywordsTooltip')}
                theme={theme} freeSolo
              />
            </GridItem>
            {(isPl && !isPunchlist) && (
              <GridItem sizes={[12]}>
                <IQCheckbox
                  label="Apply info to all locations."
                  field="sameInfo"
                  name="sameInfo"
                />
              </GridItem>
            )}
          </GridContainer>
        </form>
        <CoNavigationConfirm
          showDialog={isDirty && !(isSubmitSuccessful || isSubmitting) && isRedirectedFromOverviewPage}
        />
      </FormProvider>
    </ValidationProvider>
  );
}
