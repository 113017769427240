import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  IQButtonRadioContainer, IQFormInput, IQFormTextArea, ValidationProvider,
} from '@gannettdigital/shared-react-components';
import { Grid, useTheme } from '@mui/material';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { useEffect, useMemo } from 'react';
import CoFileUploader, { AllUploaders } from 'components/fileUploader/CoFileUploader';
import { useParams } from 'react-router';
import { FileUploadDirectories, ProductTypes, selectFilesUploaded } from 'services/fileUploaderSlice';
import { WebsiteOfferingTypes } from 'services/websitesSlice';
import { Urls } from 'navigation/Urls';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import {
  getCustomLandingPage,
  selectDesignCustomLandingPage,
  setDesign,
} from 'services/customLandingPageSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveProductId } from 'services/navigationSlice';
import schema from './Design.schema';

const designReferenceOptions = [
  {
    value: 'mockup',
    label: 'Mockup',
    field: 'designReferenceType',
  },
  {
    value: 'landingPage',
    label: 'Example Landing Page',
    field: 'designReferenceType',
  },
  {
    value: 'other',
    label: 'Other',
    field: 'designReferenceType',
  },
];

const stockAssetsOptions = [
  {
    value: 'yes',
    label: 'Yes',
    field: 'stockAssets',
  },
  {
    value: 'no',
    label: 'No, client will provide',
    field: 'stockAssets',
  },
  {
    value: 'other',
    label: 'Other',
    field: 'stockAssets',
  },
];

export default function CustomLandingPageDesign() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { orderItemId } = useParams();
  const websiteId = useSelector(selectActiveProductId);
  const navigate = useNavigationHandler();
  const dispatch = useDispatch();
  const design = useSelector(selectDesignCustomLandingPage);
  const filesUploaded = useSelector(selectFilesUploaded, (prev, curr) => prev.content.length === curr.content.length);

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      designReferenceType: '',
      stockAssets: '',
      mockupAttachments: [],
      landingPageUrl: '',
      otherReference: '',
      otherAssets: '',
    },
  });

  const {
    getValues, setValue, watch, trigger, formState: { isValid },
  } = methods;

  useEffect(() => {
    if (orderItemId) {
      dispatch(getCustomLandingPage(orderItemId));
    }
  }, [orderItemId]);

  useEffect(() => {
    if (design) {
      setValue('designReferenceType', design.designReferenceType);
      setValue('landingPageUrl', design.referenceUrl);
      setValue('otherReference', design.otherReference);
      if (design.imageryRequirement !== 'yes' && design.imageryRequirement !== 'no') {
        setValue('stockAssets', 'other');
        setValue('otherAssets', design.imageryRequirement);
      } else {
        setValue('stockAssets', design.imageryRequirement);
      }
    }
  }, [design]);

  useEffect(() => {
    if (filesUploaded.content.length > 0) {
      const filterFiles = filesUploaded.content.filter(obj => obj.uploaderType === 'mockupAttachments');
      setValue('mockupAttachments', filterFiles.map(file => JSON.stringify(file)));
      trigger('mockupAttachments');
    }
  }, [filesUploaded]);

  const handleContinue = () => {
    const data = getValues();
    const listOfUrls: string[] = filesUploaded.content.map((obj) => JSON.stringify(obj));
    const designReferenceData = {
      designReference: (data.designReferenceType === 'mockup' && listOfUrls) ? listOfUrls || [] : null,
      otherReference: (data.designReferenceType === 'other' && data.otherReference) ? data.otherReference : null,
      referenceUrl: (data.designReferenceType === 'landingPage' && data.landingPageUrl) ? data.landingPageUrl : null,
      designReferenceType: getValues('designReferenceType'),
    };

    const assetsReferenceData = {
      stockAssets: getValues('stockAssets'),
      otherAssets: getValues('otherAssets'),
    };

    dispatch(setDesign({ orderItemId, designReferenceData, assetsReferenceData }));
    navigate.to(
      Urls.LandingCustomDevelopment,
    );
  };

  const onBack = () => {
    navigate.to(Urls.LandingCustomDetails);
  };

  const fileUploaderProps : AllUploaders['fileUploaderOptions'] = {
    name: 'mockupAttachments',
    directory: FileUploadDirectories.ECOMMERCE,
    label: t('pages.landing.custom.design.mockupAttachments'),
    supportingText: t('pages.landing.custom.design.mockupAttachmentsDescription'),
    showRecommendedImageText: false,
    showCheckboxes: false,
    showComments: false,
    imageTypes: ['.png', '.jpg', '.jpeg', '.gif', '.CR2', '.DNG'],
    videoTypes: ['.mp4'],
    documentTypes: ['.pdf', '.docx'],
    maxFileSize: 256 * 1_000_000,
    uploaderType: 'mockupAttachments',
    withLabelError: true,
  };

  const designReferenceType = useMemo(() => getValues('designReferenceType'), [watch('designReferenceType')]);
  // const otherReference = useMemo(() => getValues('otherReference'), [watch('otherReference')]);
  const stockAssets = useMemo(() => getValues('stockAssets'), [watch('stockAssets')]);

  return (
    <DefaultPageLayout
      onContinue={handleContinue}
      disableContinue={!isValid}
      onBack={onBack}
      header={t('pages.landing.custom.design.title')}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <Grid container>
            <Grid item xs={12} marginBottom={3}>
              <IQButtonRadioContainer
                singleColumn
                name="designReferenceType"
                optionsList={designReferenceOptions}
                label={t('pages.landing.custom.design.designReference')}
                allowRadioDeselect
              />
            </Grid>
            {designReferenceType === 'mockup' && (
              <Grid item xs={12} marginBottom={3}>
                <CoFileUploader
                  schema={schema}
                  productId={websiteId.toString()}
                  productType={ProductTypes.EXISTING_ASSETS}
                  showFileUploader
                  fileUploaderOptions={fileUploaderProps}
                  offeringType={WebsiteOfferingTypes.LANDING_PAGES}
                  containerStyles={{
                    xs: 6.2,
                  }}
                />
              </Grid>
            )}
            {designReferenceType === 'landingPage' && (
              <>
                <Grid item xs={6} marginBottom={3}>
                  <IQFormInput
                    schema={schema}
                    showError
                    id="landingPageUrl"
                    name="landingPageUrl"
                    theme={theme}
                    labelText={t('pages.landing.custom.design.exampleLandingPageUrl')}
                    fontLabelWeight="bold"
                    adornmentIcon={<InsertLinkIcon />}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} />
              </>
            )}
            {designReferenceType === 'other' && (
              <Grid item xs={6} marginBottom={3}>
                <IQFormTextArea
                  id="otherReference"
                  name="otherReference"
                  labelText={t('pages.landing.custom.design.otherReferences')}
                  fontLabelWeight="bold"
                  rowCount={3}
                  required
                  fullWidth
                  showError
                />
              </Grid>
            )}
            <Grid item xs={12} marginBottom={3}>
              <IQButtonRadioContainer
                singleColumn
                name="stockAssets"
                optionsList={stockAssetsOptions}
                label={t('pages.landing.custom.design.stockAssets')}
                allowRadioDeselect
              />
            </Grid>
            {stockAssets === 'other' && (
              <Grid item xs={6}>
                <IQFormTextArea
                  id="otherAssets"
                  name="otherAssets"
                  labelText={t('pages.landing.custom.design.otherAssets')}
                  fontLabelWeight="bold"
                  rowCount={3}
                  required
                  fullWidth
                  showError
                />
              </Grid>
            )}
          </Grid>
        </FormProvider>
      </ValidationProvider>
    </DefaultPageLayout>
  );
}
