import DefaultPageLayout from "layouts/DefaultPageLayout";
import {useTranslation} from "react-i18next";
import {FormControlLabel, Radio, RadioGroup, Tooltip, Typography} from "@mui/material";
import React, {useState} from "react";
import {Box} from "@mui/system";
import GridItem from "components/grid/GridItem";
import GridContainer from "components/grid/GridContainer";
import schema from "./XmoGoal.schema";
import {IQFormTextArea, ValidationProvider} from "@gannettdigital/shared-react-components";
import {FormProvider, useForm} from "react-hook-form";
import XmoStepType from "models/XmoStepType";
import schemaValidate from "components/schemaValidate";
import CoNavigationConfirm from "components/navigation/CoNavigationConfirm";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

interface Props {
  product: any;
  onSubmit: (data: any) => void;
  onBack: () => void;
}

export type DetailsFormProps = {
  goalValue: string,
  comment: string,
  campaignChannelLaunchStrategy: string,
};

export default function XmoGoalPageForm({product, onSubmit, onBack}: Props) {
  const {t} = useTranslation();
  const [variant, setVariant] = useState<any>(product?.variant || '');

  const methods = useForm<DetailsFormProps>({
    mode: 'onChange',
    defaultValues: {
      goalValue: product?.solutionStrategy?.goalValue || '',
      comment: product?.solutionStrategy?.comment || '',
      campaignChannelLaunchStrategy: product?.solutionStrategy?.campaignChannelLaunchStrategy || '',
    },
  });

  const handleFormSubmit = (data: DetailsFormProps) => {
    if (variant !== 1) {
      data.campaignChannelLaunchStrategy = '';
    }
    const pageFormData = {
      solutionStrategy: {
        ...product?.solutionStrategy, ...data
      }
    };
    onSubmit(pageFormData);
  }

  const {
    handleSubmit, unregister, register,
    formState: {
      isValid,
      isDirty,
      isSubmitSuccessful,
      isSubmitting,
    },
  } = methods;

  return (
      <DefaultPageLayout
          disableContinue={!isValid}
          onBack={onBack}
          header={t('pages.xmo.solutionsStrategy.goal.goalTitle')}
          onContinue={handleSubmit(handleFormSubmit)}>
        <ValidationProvider schema={schema}>
          <FormProvider {...methods}>
            <Box
                id="mainForm"
                component="form"
                sx={{display: 'flex'}}
                onSubmit={() => {
                  handleSubmit(handleFormSubmit)
                }}
            >
              <GridContainer fullWidth>
                <GridItem sizes={[12]}>
                  <Typography
                      variant="h6">{
                    variant === 1 ? t('pages.xmo.solutionsStrategy.goal.leadGeneratorTitle') :
                        variant === 2 ? t('pages.xmo.solutionsStrategy.goal.firstPartyDataBuilderTitle') :
                            t('pages.xmo.solutionsStrategy.goal.brandAmplifierTitle')}</Typography>
                </GridItem>
                <GridItem sizes={[12]}>
                  <IQFormTextArea
                      fontLabelWeight="600"
                      id="goalValue"
                      name="goalValue"
                      fullWidth
                      showError
                      labelText={'Goal Value *'}
                  />
                </GridItem>
                <GridItem sizes={[12]}>
                  <IQFormTextArea
                      fontLabelWeight="600"
                      id="comment"
                      name="comment"
                      fullWidth
                      showError
                      labelText={t('pages.xmo.solutionsStrategy.goal.commentValue')}
                  />
                </GridItem>
                {
                    variant === 1 && <GridItem sizes={[12]}>
                      <Typography
                          fontWeight={600}
                          variant="subtitle1">{t('pages.xmo.solutionsStrategy.goal.campaignChannelLaunchStrategy')}
                        <RadioGroup
                            id='campaignChannelLaunchStrategy'
                            name='campaignChannelLaunchStrategy'
                            defaultValue={product?.solutionStrategy?.campaignChannelLaunchStrategy || ''}
                            row
                        >
                          <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            {[t('pages.xmo.solutionsStrategy.inputValues.nonStaggeredLaunch'),
                              t('pages.xmo.solutionsStrategy.inputValues.staggeredLaunch')].map(opt => (
                                <Box>
                                  <FormControlLabel
                                      key={opt}
                                      control={<Radio value={opt}/>}
                                      label={opt}
                                      {...register('campaignChannelLaunchStrategy', {
                                        validate: (value) => schemaValidate(value, 'campaignChannelLaunchStrategy', schema),
                                      })}
                                  />
                                  <Tooltip
                                      title={opt === t('pages.xmo.solutionsStrategy.inputValues.nonStaggeredLaunch')
                                          ? t('pages.xmo.solutionsStrategy.goal.nonStaggeredToolTipText')
                                          : t('pages.xmo.solutionsStrategy.goal.staggeredToolTipText')
                                      }
                                      placement="right"
                                  >
                                    <InfoOutlinedIcon color="primary"
                                                      sx={{marginLeft: 0, fontSize: '18px'}}/>
                                  </Tooltip>
                                </Box>
                            ))}
                          </Box>
                        </RadioGroup>
                      </Typography>
                    </GridItem>
                }
              </GridContainer>
            </Box>
            <CoNavigationConfirm
                showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
            />
          </FormProvider>
        </ValidationProvider>
      </DefaultPageLayout>
  );
}