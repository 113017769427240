import React from 'react';
import {
  FormControl,
  FormControlLabel, Grid,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';

/* This component is newly created. If any field is required,
   we make it mandatory using an if-else condition on the schema page under CustomValidate.
 */

export default function RadioButton(props: any) {
  return (
      <Grid item mt={2}>
        <FormControl component="fieldset">
          <Typography variant="subtitle2" component="h3" fontSize={16}>
            {props.label}
          </Typography>
          <RadioGroup
              aria-label="options"
              name="options"
              value={props.value}
              onChange={(e) => props.onChange(e.target.value)}
          >
            {
              props?.schema?.items?.enum?.map((radioItem: any, index: number) => {
                return (
                    <FormControlLabel key={index}
                                      value={radioItem}
                                      control={<Radio/>}
                                      label={radioItem}
                                      disabled={props?.disabled || undefined}
                    />
                )
              })
            }
          </RadioGroup>
        </FormControl>
      </Grid>
  );
}
