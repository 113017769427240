import i18n from 'i18n/i18n';

const {t} = i18n;

export const yesNoDropDown = [
  {value: 'Yes' , description: t('pages.xmo.reach.useAutomaticPlacementOnAdsOptions.yes')},
  {value: 'No' , description:  t('pages.xmo.reach.useAutomaticPlacementOnAdsOptions.no')},
];

export const baseWebsiteDropDown = [
  {value: 'CaptureCode on Client\'s Site' , description: t('pages.xmo.solutionsStrategy.proxy.baseWebsiteDropDown1')},
  {value: 'Client Center with Lead Engagement Add-On (Edge' , description:  t('pages.xmo.solutionsStrategy.proxy.baseWebsiteDropDown2')},
  {value: 'Direct Web Link (DWL) - NO TRACKING' , description:  t('pages.xmo.solutionsStrategy.proxy.baseWebsiteDropDown4')},
];

export const websiteTrackingDropDown = [
  {value: 'Vertical Specific Proxy Domains' , description: t('pages.xmo.solutionsStrategy.proxy.websiteTracking1stDropDown')},
  {value: 'No Tracking' , description: t('pages.xmo.solutionsStrategy.proxy.websiteTracking2stDropDown')},
];

export const canWeStartYourCampaignOn3ppDropDown = [
  {value: 'Yes - Start Campaign On 3pp until CaptureCode is Implemented' , description: t('pages.xmo.solutionsStrategy.proxy.canWeStartYourCampaignOn3ppDropDownOpt1')},
  {value: 'No - Hold Campaign until CaptureCode is Implemented' , description:  t('pages.xmo.solutionsStrategy.proxy.canWeStartYourCampaignOn3ppDropDownOpt2')},
];