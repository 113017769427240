import * as yup from 'yup';
import i18n from "i18n/i18n";

const {t} = i18n;

// Regular expression to match Facebook URLs
const facebookUrlRegex = /^(https?:\/\/)?(www\.)?(facebook\.com\/|fb\.com\/)[a-zA-Z0-9(\.\?)?]/;

const schema = {
  selectCampaignGoalFocus: yup.string().required(t('pages.xmo.tactics.socialSmartOptimization.selectCampaignGoalFocusErrorMsg')),
  landingPageUrl: yup.string().required().url(t('pages.xmo.tactics.socialSmartOptimization.landingPageUrlErrorMsg')),
  landingPageIsNotLive: yup.string().notRequired(),
  facebookPageUrl: yup.string()
  .required(t('pages.xmo.tactics.socialSmartOptimization.facebookPageUrlErrorMsg'))
  .url(t('pages.xmo.tactics.socialSmartOptimization.facebookPageUrlErrorMsg'))
  .test('is-facebook-url', t('pages.xmo.tactics.socialSmartOptimization.facebookPageUrlErrorMsg'), value => {
    return facebookUrlRegex.test(value);
  }),
  facebookPageIsNotLive: yup.string().notRequired(),
  allowEmojis: yup.string().notRequired(),
};

export default {
  yupValidations: schema,
  customValidations: {},
};
