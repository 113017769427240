import { IQThemeV2 } from '@gannettdigital/shared-react-components';
import {
  Typography, Select, MenuItem, Grid,
  TextField,
} from '@mui/material';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import ErrorText from 'components/errorText/ErrorText';
import GridItem from 'components/grid/GridItem';
import i18n from 'i18n/i18n';
import { Controller } from 'react-hook-form';
import schemaValidate from 'components/schemaValidate';
import { Trans } from 'react-i18next';
import { TypeOfSaleConstants } from 'pages/shopping-cart/Constants';
import { renderBudgetField } from './Budget';
import { cyclesOptions } from '../generalQuestionsData';
import schema from '../GeneralQuestions.schema';
import { tooltip } from './Tracking';

const { t } = i18n;

export const Details = (errors, register, control, budgetLimits, campaignName, typeOfSale) => (
  <>
    <GridItem marginBottom={3}>
      <Typography variant="h5" component="h2">
        {t('pages.generalQuestions.details')}
      </Typography>
    </GridItem>

    <GridItem marginBottom={1} sizes={[8]}>
      <GridItem marginBottom={1}>
        <Typography variant="subtitle2" component="h3" fontSize={16}
          color={errors.campaignName ? IQThemeV2.palette.error.main : '#000000'}
        >
          {t('pages.generalQuestions.campaignName.title')}
        </Typography>
      </GridItem>
    </GridItem>

    <GridItem marginBottom={3} sizes={[8]}>
      {campaignName ? <GridItem marginBottom={0}>
        <GridItem marginBottom={1}>
          <Typography>{campaignName}</Typography>
        </GridItem>
      </GridItem> : <Grid>
        <TextField
            hiddenLabel
            required
            id="campaignName"
            name="campaignName"
            fullWidth
            error={!!errors.campaignName}
            {...register('campaignName', {
              validate: (value) => schemaValidate(value, 'campaignName', schema),
            })}
        />
        <ErrorText
            hasError={!!errors.campaignName}
            errorText={t('pages.generalQuestions.campaignName.error')}
        /></Grid>
      }
    </GridItem>

    <GridItem sizes={[8]} marginBottom={3}>
      <GridItem marginBottom={1}>
        <Typography variant="subtitle2" component="h3" fontSize={16}
          color={errors.budget ? IQThemeV2.palette.error.main : '#000000'}
        >
          {t('pages.generalQuestions.budget.title')}
        </Typography>
        <GridItem marginBottom={0}>
          {renderBudgetField(errors, register, budgetLimits)}
        </GridItem>
      </GridItem>
    </GridItem>

    <GridItem sizes={[8]} marginBottom={3}>
      <GridItem marginBottom={1}>
        <Typography variant="subtitle2" component="h3" fontSize={16}
          color={errors.initialCycles ? IQThemeV2.palette.error.main : '#000000'}
        >
          {t('pages.generalQuestions.initialCycles.title')}
        </Typography>
        <GridItem marginBottom={0}>
          <Controller
            name="initialCycles"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Select
                label=""
                displayEmpty
                labelId="select-label"
                error={!!errors.initialCycles}
                {...field}
                sx={{
                  width: '100%',
                }}
              >
                <MenuItem value="" disabled>
                  Select Cycles
                </MenuItem>
                {cyclesOptions.map(option => (
                  <MenuItem key={option} value={option}>
                    {option === 99 ? 'Unlimited' : option}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          <ErrorText
            hasError={!!errors.initialCycles}
            errorText={t('pages.generalQuestions.initialCycles.error')}
          />
        </GridItem>
      </GridItem>
    </GridItem>

    {typeOfSale === TypeOfSaleConstants.BUDGET_REALLOCATION && (
      <GridItem sizes={[8]} marginBottom={3}>
        <GridItem marginBottom={1}>
          <Typography variant="subtitle2" component="h3" fontSize={16}
            color={errors.budgetReallocationCid ? IQThemeV2.palette.error.main : '#000000'}
          >
            {t('pages.generalQuestions.budgetReallocationCid.title')}
          </Typography>
          <GridItem marginBottom={0}>
            <TextField
              hiddenLabel
              required
              id="budgetReallocationCid"
              name="budgetReallocationCid"
              fullWidth
              error={!!errors.budgetReallocationCid}
              {...register('budgetReallocationCid', {
                validate: (value) => schemaValidate(value, 'budgetReallocationCid', schema),
              })}
            />
            <ErrorText
              hasError={!!errors.budgetReallocationCid}
              errorText={t('pages.generalQuestions.budgetReallocationCid.error')}
            />
          </GridItem>
        </GridItem>
      </GridItem>
    )}
  </>
);

export const DetailsRebuild = (errors, register) => (
  <>
    <GridItem marginBottom={3}>
      <Typography variant="h5" component="h2">
        {t('pages.generalQuestions.details')}
      </Typography>
    </GridItem>
    <GridItem sizes={[8]} marginBottom={3}>
      <GridItem marginBottom={1}>
        <Typography variant="subtitle2" component="h3" fontSize={16}
          color={errors.rebuildCid ? IQThemeV2.palette.error.main : '#000000'}
        >
          {t('pages.generalQuestions.rebuildCid.title')}
          {tooltip(<Trans
            i18nKey="pages.generalQuestions.rebuildCid.tooltip"
            components={{ br: <br /> }}
          />, <HelpOutlineOutlinedIcon />, '208px')}
        </Typography>
        <GridItem marginBottom={0}>
          <TextField
            hiddenLabel
            required
            id="rebuildCid"
            name="rebuildCid"
            fullWidth
            error={!!errors.rebuildCid}
            {...register('rebuildCid', {
              validate: (value) => schemaValidate(value, 'rebuildCid', schema),
            })}
          />
          <ErrorText
            hasError={!!errors.rebuildCid}
            errorText={t('pages.generalQuestions.rebuildCid.error')}
          />
        </GridItem>
      </GridItem>
    </GridItem>
  </>
);
