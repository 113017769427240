import ContentLoader from 'components/contentLoader/ContentLoader';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderItems, selectOrderFlow, selectOrderItems } from 'services/ordersSlice';
import { useParams } from 'react-router';
import { useEffect } from 'react';
import OrdersItemsType from 'models/OrderItemsType';
import { Urls } from 'navigation/Urls';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { OfferingTypesWithMenus, sortOrderItems } from 'services/menus';
import { selectIsPremiumListingsHealthcare } from 'services/premiumListingsSlice';
import { OrderFlow } from 'shared/constants';
import { selectNavigationMenu } from 'services/navigationSlice';

export const getLastStepOfferingType = (offeringType: string): string => {
  switch (offeringType) {
    case 'LandingPage': return Urls.LandingStockAssets;
    case 'CustomLandingPage': return Urls.LandingCustomAdditionalInfo;
    case 'ChatProduct': return Urls.ChatAdditional;
    case 'ChatPlatinumProduct': return Urls.PlatinumChatAdditional;
    case 'EcommerceMicrosite': return Urls.AdditionalStoreDetails;
    case 'GoldSite': return Urls.SitePages;
    case 'Website': return Urls.SitePages;
    case 'PremiumListing': return Urls.GeneralQuestions;
    case 'MasterAddon': return Urls.WebsiteUrl;
    case 'CustomWebsite': return Urls.CustomWebsiteAdditionalInfo;
    case 'SEOStandard': return Urls.SolutionStrategy;
    case 'SEOCustom': return Urls.CustomSolutionStrategy;
    case 'SEOBlogStandard': return Urls.BlogSolutionStrategy;
    case 'SEOBlogEnhanced': return Urls.BlogSolutionStrategy;
    case 'GenericProduct': return Urls.GenericProductDetails;
    case 'SMMStandard': return Urls.SMMSolutionStrategy;
    case 'SMMCustom': return Urls.SMMSolutionStrategy;
    case 'DMSNextCustomerCenterByDash': return Urls.CCByDashStrategy;
    case 'DMSNextCustomerCenter': return Urls.CustomerCenterStrategy;
    case 'YouTube': return Urls.YouTubeTargeting;
    case 'PiQLandingPages': return Urls.PiQAdditionalInfo;
    case 'TargetedDisplay': return Urls.TargetedDisplay;
    case 'TargetedDisplayLegacy': return Urls.TargetedDisplayLeagacyProxyProvisioning;
    case 'XMO': return Urls.XmoDetails;
    case 'XMOUpgrade': return Urls.XmoDetails;
    case 'PromotionsMediaMix': return Urls.PmmDetails;
    case 'SearchEngineMarketing': return Urls.GenericProductDetails;
    default: return Urls.GenericProductDetails;
  }
};

export default function ProductMoveBackward() {
  const { orderId, orderItemId } = useParams();
  const orderItems = useSelector(selectOrderItems);
  const dispatch = useDispatch();
  const navigate = useNavigationHandler();
  const isHealthCare = useSelector(selectIsPremiumListingsHealthcare);
  const leftNavMenuSteps = useSelector(selectNavigationMenu);
  const orderFlow = useSelector(selectOrderFlow);
  const isSalesForce = orderFlow === OrderFlow.SALES_FORCE;
  const healthcareOrderItems = orderItems.content
    .filter(item => (item.offeringType === 'PremiumListing' && item.healthcare));

  useEffect(() => {
    if (!orderItems.content || orderItems.content.length === 0) dispatch(getOrderItems(orderId));
  }, [orderItemId]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const defaultStep = (isHealthCare || healthcareOrderItems.length > 0)
      ? Urls.PlHealthcareLocations : Urls.BusinessLocation;
    if (orderItems.content && orderItems.content.length > 0) {
      const orderItemsWithOffering = orderItems.content.filter(o =>
        OfferingTypesWithMenus.includes(o.pdOfferingType || o.offeringType));

      const orderItemsIds = sortOrderItems(orderItemsWithOffering, isSalesForce).map(o => o.id);
      let prevOrderItems = orderItemsIds;
      if (orderItemId !== undefined) {
        prevOrderItems = orderItemsIds.slice(0, orderItemsIds.indexOf(Number.parseInt(orderItemId, 10)));
      }

      if (prevOrderItems?.length > 0) {
        const prevOrderItemId = prevOrderItems[prevOrderItems.length - 1];
        const oItem = orderItems.content.filter(o => o.id === prevOrderItemId)[0] as OrdersItemsType;
        const offeringType = oItem.pdOfferingType || oItem.offeringType;
        let url = getLastStepOfferingType(offeringType);
        if (offeringType === 'MasterAddon') {
          const addonSteps = leftNavMenuSteps.find(e => e.label === 'Addons');
          url = addonSteps.subSteps.at(-1).url;
        }
        navigate.to(url, `${prevOrderItemId}`);
      } else {
        navigate.to(defaultStep);
      }
    } else {
      navigate.to(defaultStep);
    }
  }, [orderItems]);

  return (
    <ContentLoader status="" message="" />
  );
}
