import { IQLoadingSpinner } from '@gannettdigital/shared-react-components';
import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Urls } from 'navigation/Urls';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  getCaseDetails,
  selectErrorMessage,
  getOpportunity,
  selectOpportunity,
  selectOpportunityStatus,
} from 'services/cefSlice';

export const loadingSalesforce = () => (
  <>
    <IQLoadingSpinner size={60} />
    <Typography mt={2}>
      Getting information from Salesforce
    </Typography>
  </>
);

export const failedRequest = (handleClick, errorMessage) => (
  <>
    <Typography mb={4}>
      {errorMessage}
    </Typography>
    <Button variant="contained" sx={{ padding: '8px 22px' }} onClick={handleClick}>
      <Typography>Back to Main Page</Typography>
    </Button>
  </>
);

export default function LoadingScreenCef() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const opportunityId = searchParams.get('OpportunityId') || searchParams.get('opportunityId');
  const solutionId = searchParams.get('SolutionId') || searchParams.get('solutionId');
  const caseId = searchParams.get('CaseId') || searchParams.get('caseid') || searchParams.get('caseId');
  const accountId = searchParams.get('AccountId') || searchParams.get('accountId') || searchParams.get('AccountID');
  const isPartnerFlow = searchParams.get('channel') === "partner"
  const opportunity = useSelector(selectOpportunity);
  const opportunityStatus = useSelector(selectOpportunityStatus);
  const errorMessage = useSelector(selectErrorMessage);
  useEffect(() => {
    if (opportunityId) {
      dispatch(getOpportunity(opportunityId));
    } else if (caseId) {
      dispatch(getCaseDetails(caseId));
    }
  }, []);

  useEffect(() => {
    if (Object.keys(opportunity).length > 0 && !errorMessage) {
      if (opportunityId) {
        if (solutionId) navigate(`/${Urls.Cef}/${opportunityId}/${solutionId}`);
        else navigate(`/${Urls.Cef}/${opportunityId}`);
      } else if (caseId) {
        navigate(`/${Urls.Cef}/${caseId}?isBlue=true`);
      }
    }
  }, [opportunity]);

  useEffect(() => {
    if (!(opportunityId || caseId)) {
      if (isPartnerFlow && accountId) {
        navigate(`/ourThemes?partnerAccount=${accountId}`);
      } else {
        navigate('/');
      }
    }
  }, [isPartnerFlow, accountId, opportunityId, caseId]);

  return (
    <Box
      width="100vw"
      height="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      {opportunityStatus !== 'failed' && !errorMessage
        ? loadingSalesforce()
        : failedRequest(() => navigate('/'), errorMessage)}
    </Box>
  );
}
