/* eslint-disable max-len */
import i18n from 'i18n/i18n';

import {RJSFSchema} from '@rjsf/utils';
import Dropdown from 'pages/generic-product/details/custom-fields/Dropdown';

const {t} = i18n;

export const TotalTrackingSchema: RJSFSchema | any = (data) => ({
  title: t('pages.ausProduct.totalTracking.title'),
  type: 'object',
  required: ['totalTrackNumbersAreRequired'],
  properties: {
    totalTrackNumbersAreRequired: {
      type: 'string',
      title: t('pages.ausProduct.totalTracking.totalTrackNumbersAreRequiredLabel'),
      default: '',
      enum: [
        t('pages.ausProduct.totalTracking.one'),
        t('pages.ausProduct.totalTracking.two'),
        t('pages.ausProduct.totalTracking.three'),
      ],
    },
  },
});

const dropdownFieldKeys = [
  'totalTrackNumbersAreRequired'
];

const dropdownFields = dropdownFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {
    'ui:widget': (props) => (
        <Dropdown
            {...props}
            errorMessage={t('pages.ausProduct.tracking.requiredFieldErrorMessage')}
        />
    ),
  },
}), {});

export const TotalTrackingUISchema = {
  'ui:order': ['totalTrackNumbersAreRequired'],
  ...dropdownFields
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const TotalTrackingCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.totalTrackNumbersAreRequired === '') {
    errors.totalTrackNumbersAreRequired.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  return errors;
};
