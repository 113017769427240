import * as yup from 'yup';
import {t} from "i18next";

const schema = {
  keywordListCreation: yup.string().required(t('pages.xmo.sem.keywordListCreationErrorMessage')),
  selectSitelink: yup.string().required(t('pages.xmo.sem.selectSitelinkErrorMessage')),
  enableRemarketingLists: yup.string().required(t('pages.xmo.sem.enableRemarketingListsErrorMessage')),
  textAdPreference: yup.string().required(t('pages.xmo.sem.textAdPreferenceErrorMessage')),
  primaryAdGroupLandingPage: yup.string().required(t('pages.xmo.sem.primaryAdGroupLandingPageErrorMessage')),
  multipleLocations: yup.string().required(t('pages.xmo.sem.multipleLocationsErrorMessage')),
  locationExtensionSettings: yup.string().required(t('pages.xmo.sem.locationExtensionSettingsErrorMessage')),
  flightedCampaign: yup.string().required(t('pages.xmo.sem.flightedCampaignErrorMessage')),
};

export default {
  yupValidations: schema,
  customValidations: {},
};
