/* eslint-disable react/jsx-fragments */
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { FormProvider, useForm } from 'react-hook-form';

import { FileUploadDirectories, ProductTypes, setFiles } from 'services/fileUploaderSlice';
import { WebsiteOfferingTypes } from 'services/websitesSlice';

import { Box, Grid } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import {
  IQFormInput,
  IQFormTextArea,
  IQThemeV2,
  IQYesNoFormRadioButtons,
  ValidationProvider,
} from '@gannettdigital/shared-react-components';

import LandingPageType from 'models/LandingPageType';
import useUpdateAssets from 'hooks/useUpdateAssets';
import { t } from 'i18next';

import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import CoFileUploader, { OnlyFileUploader } from 'components/fileUploader/CoFileUploader';

import { useDispatch, useSelector } from 'react-redux';
import { selectActiveProductId } from 'services/navigationSlice';
import schema from './LandingExistingContent.schema';

interface Props {
  product: LandingPageType;
  onSubmit: (data: SubmitProps) => void;
  onBack: () => void;
}

type SubmitProps = {
  existingWebsiteSelection: boolean;
  existingLandingPagesSelection: boolean;
  existingWebsiteUrl?: string;
  urlsToMigrate?: string;
};

export type LandingExistingContentFormProps = {
  existingWebsiteSelection: 'yes' | 'no' | '';
  existingLandingPagesSelection: 'yes' | 'no' | '';
  existingWebsiteUrl?: string;
  urlsToMigrate?: string;
  files?: File[];
};

const megabyte = 1_000_000;

const fileUploaderProps :OnlyFileUploader['fileUploaderOptions'] = {
  label: t('pages.landing.existingContent.fileUploaderLabel'),
  name: 'files',
  showComments: true,
  showCheckboxes: true,
  maxFileSize: 256 * megabyte,
  imageTypes: ['.png', '.jpg', '.jpeg', '.gif', '.CR2', '.DNG'],
  videoTypes: ['.mp4'],
  documentTypes: ['.pdf', '.doc', '.docx'],
  directory: FileUploadDirectories.LANDING,
  showRecommendedImageText: false,
  uploaderType: 'existing-content',
  supportingText: t('pages.landing.existingContent.fileUploaderSupportingText'),
};

function evaluateSelection(selection: string): boolean;
function evaluateSelection(selection: boolean): 'yes' | 'no';
function evaluateSelection(selection: string | boolean): 'yes' | 'no' | '' | boolean {
  if (typeof selection === 'string') {
    return selection === 'yes';
  }

  if (typeof selection !== 'boolean') {
    return '';
  }

  if (selection) return 'yes';
  return 'no';
}

export default function LandingExistingContentForm(props: Props) {
  const { product } = props;
  const dispatch = useDispatch();
  const { orderId, orderItemId } = useParams();
  const websiteId = useSelector(selectActiveProductId);

  const methods = useForm<LandingExistingContentFormProps>({
    mode: 'all',
    defaultValues: {
      existingWebsiteSelection: evaluateSelection(product?.existingWebsiteSelection),
      existingLandingPagesSelection: evaluateSelection(product?.existingLandingPagesSelection),
      urlsToMigrate: product?.urlsToMigrate || '',
      existingWebsiteUrl: product?.existingWebsiteUrl || null,
      files: [],
    },
  });

  const {
    handleSubmit,
    formState: {
      isValid, isDirty, isSubmitting, isSubmitSuccessful, dirtyFields,
    },
    watch,
    setValue,
    unregister,
  } = methods;

  const { existingWebsiteSelection, existingLandingPagesSelection, files } = watch();

  const websiteUrl = watch('existingWebsiteUrl');

  const { triggerAllUpdates, deleteAllAssets } = useUpdateAssets(
    watch,
    fileUploaderProps.name,
    null,
    websiteId.toString(),
    orderId,
    ProductTypes.EXISTING_CONTENT,
    WebsiteOfferingTypes.LANDING_PAGES,
    orderItemId,
  );

  const onSubmit = async (data: LandingExistingContentFormProps) => {
    if (existingLandingPagesSelection === 'no') {
      deleteAllAssets();
    } else {
      await triggerAllUpdates();
    }

    const dataToSubmit = {
      ...data,
      files: null,
      existingWebsiteSelection: evaluateSelection(data.existingWebsiteSelection),
      existingLandingPagesSelection: evaluateSelection(data.existingLandingPagesSelection),
      urlsToMigrate: evaluateSelection(data.existingLandingPagesSelection) ? data?.urlsToMigrate : null,
    };

    await props.onSubmit(dataToSubmit);
    dispatch(setFiles([]));
  };

  useEffect(() => {
    if (existingWebsiteSelection === 'no') {
      setValue('existingWebsiteUrl', null);
      unregister('existingWebsiteUrl');
    }

    if (existingLandingPagesSelection === 'no') {
      setValue('urlsToMigrate', '');
      unregister('urlsToMigrate');
    }
  }, [existingWebsiteSelection, existingLandingPagesSelection]);

  useEffect(() => {
    if (existingLandingPagesSelection === 'no' && files.length > 0) {
      triggerAllUpdates();
    }
  }, [existingLandingPagesSelection]);

  const isExistingWebsiteUrlTouched = (dirtyFields?.existingWebsiteUrl && websiteUrl.length > 0) || false;

  return (
    <DefaultPageLayout
      disableContinue={!isValid}
      onBack={props.onBack}
      header={t('pages.landing.existingContent.header')}
      onContinue={handleSubmit(onSubmit)}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <Box
            id="mainForm"
            component="form"
            sx={{ display: 'flex' }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Grid
              container
              item
              display="flex"
              flexDirection="column"
              xs={7}
            >
              <Grid item sx={{ '[role=radiogroup]': { pl: '5px' } }}>
                <IQYesNoFormRadioButtons
                  title={t('pages.landing.existingContent.existingWebsite')}
                  field="existingWebsiteSelection"
                  fontTitleWeight="600"
                />
                {existingWebsiteSelection === 'yes' && (
                <Grid
                  item
                  mt="20px"
                  mb="4px"
                  sx={{ label: { fontWeight: 600, pb: '7px' }, div: { width: '100%', boxSizing: 'border-box' } }}
                >
                  <IQFormInput
                    adornmentIcon={<LinkIcon style={{ fill: '#292928' }} />}
                    labelText={t('pages.landing.existingContent.existingWebsiteUrl')}
                    id="existingWebsiteUrl"
                    name="existingWebsiteUrl"
                    theme={IQThemeV2}
                    customValidation={schema.yupValidations.existingWebsiteUrl}
                    schema={isExistingWebsiteUrlTouched ? schema : null}
                  />
                </Grid>
                )}
              </Grid>
              <Grid item mt="44px" sx={{ '[role=radiogroup]': { pl: '5px' } }}>
                <IQYesNoFormRadioButtons
                  title={t('pages.landing.existingContent.existingLandingPage')}
                  field="existingLandingPagesSelection"
                  fontTitleWeight="600"
                />
                {existingLandingPagesSelection === 'yes' && (
                <React.Fragment>
                  <Grid
                    item
                    mt="20px"
                    sx={{ label: { pb: '7px' } }}
                  >
                    <IQFormTextArea
                      id="existing-pages-urls"
                      name="urlsToMigrate"
                      fontLabelWeight="600"
                      labelText={t('pages.landing.existingContent.existingLandingPageUrls')}
                      fullWidth
                      showError
                    />
                  </Grid>
                  <CoFileUploader
                    offeringType={WebsiteOfferingTypes.LANDING_PAGES}
                    schema={schema}
                    fileUploaderOptions={fileUploaderProps}
                    showFileUploader
                    productType={ProductTypes.EXISTING_CONTENT}
                    productId={websiteId.toString()}
                    containerStyles={{
                      mt: '24px',
                      '.MuiList-root': { mt: '46px' },
                      '& > div > label > span': { display: 'none' },
                    }}
                  />
                </React.Fragment>
                )}
              </Grid>
            </Grid>
            {/* show the dialog if we changed the field and didn't submit the changes */}
            <CoNavigationConfirm
              showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
            />
          </Box>
        </FormProvider>
      </ValidationProvider>
    </DefaultPageLayout>
  );
}
