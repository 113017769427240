import React, {useEffect, useState} from 'react';
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "services/redux/store-schedule";
import {saveWebEvent, selectWebEvent} from "services/addWebEventSlice";
import GenericCard from "components/generic-card/GenericCard";

type WebEvent = {
  webEventUrl: string;
  webEventName: string;
  value: string;
};

export default function AddWebEvents(props: any) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const savedWebEvents = useSelector((state: RootState) => selectWebEvent(state));
  const [webEvents, setWebEvents] = useState<WebEvent[]>(savedWebEvents.webEvents || []);
  const [open, setOpen] = useState<boolean>(false);
  const [newWebEvent, setNewWebEvent] = useState<any>({
    webEventUrl: '',
    webEventName: '',
    value: ''
  });
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [isValidUrl, setIsValidUrl] = useState<boolean>(true);
  const [isEmptyFields, setIsEmptyFields] = useState<boolean>(true);
  const urlValidationRegex = /^(https?:\/\/|www\.)[a-zA-Z0-9\-._~%!$&'()*+,;=:@]+(\.[a-zA-Z]{2,})(:[0-9]{1,5})?(\/[a-zA-Z0-9\-._~%!$&'()*+,;=:@\/?]*)?$/;
  const webEventFieldLabels = ['webEventName', 'value', 'webEventUrl'];
  const {data} = props;

  useEffect(() => {
    props.onChange('');
  }, []);

  useEffect(() => {
    dispatch(saveWebEvent({webEvent: webEvents, webEventFlag: true}));
  }, [webEvents, dispatch]);

  useEffect(() => {
    if (Object.keys(data).length > 0 && data && data.webEventItems) {
      setWebEvents(data.webEventItems);
    }
  }, [data]);

  useEffect(() => {
    if (newWebEvent.value === '' && newWebEvent.webEventName === '' && newWebEvent.webEventUrl === '') {
      setIsEmptyFields(true)
    } else {
      setIsEmptyFields(false)
    }
  }, [newWebEvent]);

  const validateUrl = (url: string) => {
    return urlValidationRegex.test(url);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    setNewWebEvent((prevWebEvent) => ({...prevWebEvent, [name]: value}));
    if (name === 'webEventUrl') {
      setIsValidUrl(validateUrl(value));
    }
  };

  const handleDelete = (index: number) => {
    setWebEvents((prevWebEvents) => prevWebEvents.filter((_, i) => i !== index));
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const {name, value} = e.target;
    setNewWebEvent((prevWebEvent) => ({...prevWebEvent, [name as string]: value}));
  };

  const handleClickOpen = (index: number | null = null) => {
    if (index !== null) {
      setNewWebEvent(webEvents[index]);
      setEditIndex(index);
    } else {
      setNewWebEvent({webEventUrl: '', webEventName: '', value: ''});
      setEditIndex(null);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsValidUrl(true);
  };

  const handleSubmit = () => {
    if (editIndex !== null) {
      const updatedWebEvents = webEvents.slice();
      updatedWebEvents[editIndex] = newWebEvent;
      setWebEvents(updatedWebEvents);
    } else {
      setWebEvents((prevWebEvents) => [...prevWebEvents, newWebEvent]);
    }
    setNewWebEvent({webEventUrl: '', webEventName: '', value: ''});
    handleClose();
  };

  return (
      <Grid item xs={9} my={3}>
        <Grid>
          <Box>
            {Array.isArray(webEvents) && webEvents.map((webEventField, index) => (
                <GenericCard
                    key={index}
                    labels={webEventFieldLabels}
                    data={webEventField}
                    handleDelete={() => handleDelete(index)}
                    onEdit={() => handleClickOpen(index)}
                    onSelect={() => {
                    }}
                />
            ))}
            <Box
                sx={{
                  padding: '20px 20px 20px 0',
                  backgroundColor: theme.palette.primary.light,
                }}
            >
              <Button startIcon={<AddIcon/>} onClick={() => handleClickOpen()}>
                Add Web Events
              </Button>
            </Box>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="sm"
                fullWidth
            >
              <DialogTitle>{editIndex !== null ? 'Edit Web Events' : 'Add Web Events'}</DialogTitle>
              <DialogContent>
                <FormControl fullWidth margin="dense">
                  <InputLabel>Value</InputLabel>
                  <Select
                      name="value"
                      value={newWebEvent.value}
                      onChange={handleSelectChange}
                      label="Value"
                  >
                    <MenuItem value="High">High</MenuItem>
                    <MenuItem value="Medium">Medium</MenuItem>
                    <MenuItem value="Low">Low</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                    margin="dense"
                    name="webEventUrl"
                    label="Web Event URL"
                    type="url"
                    fullWidth
                    variant="outlined"
                    value={newWebEvent.webEventUrl}
                    onChange={handleChange}
                    error={!isValidUrl}
                    helperText={!isValidUrl ? "Please enter a valid URL starting with http:// or https://" : ""}
                />
                <TextField
                    margin="dense"
                    name="webEventName"
                    label="Web Event Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={newWebEvent.webEventName}
                    onChange={handleChange}
                />
              </DialogContent>
              <DialogActions>
                <Button
                    onClick={handleClose}
                    style={{
                      color: '#ffffff',
                      backgroundColor: '#0046ff'
                    }}
                >
                  Cancel
                </Button>
                <Button
                    onClick={handleSubmit}
                    disabled={!isValidUrl || isEmptyFields}
                    style={{
                      color: '#ffffff',
                      backgroundColor: !isValidUrl || isEmptyFields ? '#0046FF38' : '#0046ff'
                    }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Grid>
      </Grid>
  );
}