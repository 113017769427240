import ContentLoader from 'components/contentLoader/ContentLoader';
import useProduct from 'hooks/useProduct';
import {StatusType} from 'models/StatusType';
import {Urls} from 'navigation/Urls';
import {GetXmoStepResponse, XmoStepRequestContentType} from 'models/XmoStepType';
import XmoStepDetailsPageForm from 'pages/xmo/details/XmoStepDetailsPageForm';
import {useParams} from "react-router";

export default function XmoStepDetails() {
  const { page } = useParams();
  const { product, onSubmit, onBack } = useProduct<XmoStepRequestContentType,
  StatusType<GetXmoStepResponse>>(page as Urls);

  return (
    <ContentLoader status={product.status} message={product.message}>
      <XmoStepDetailsPageForm
          product={product?.content?.data}
        onSubmit={onSubmit}
        onBack={onBack}
      />
    </ContentLoader>
  );
}
