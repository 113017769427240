/* eslint-disable react/jsx-fragments, no-unused-vars, @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  Box, FormHelperText, Grid, Typography, useTheme,
} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

import {
  IQCheckbox,
  IQFormTextArea,
  IQTheme,
  IQThemeV2,
  ValidationProvider,
} from '@gannettdigital/shared-react-components';
import styled from '@emotion/styled';

import { t } from 'i18next';
import LandingPageType from 'models/LandingPageType';

import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';

import { useSelector } from 'react-redux';
import { selectOrderFlow, selectOrdersContent } from 'services/ordersSlice';
import { OrderFlow } from 'shared/constants';
import ErrorText from 'components/errorText/ErrorText';
import { checkboxes } from './trackingCheckboxes';
import schema from './Tracking.schema';

type StyledHeadingProps = {
  theme: typeof IQTheme;
  hasError?: boolean;
};

const StyledHeading = styled(Typography, { shouldForwardProp: prop => prop !== 'hasError' })<StyledHeadingProps>`
  color: ${props => (props.hasError ? props.theme.palette.error.main : props.theme.palette.text.primary)};
  font-family: ${props => props.theme.typography.fontFamily};
  font-weight: ${props => props.theme.typography.fontWeightBold};
  letter-spacing: 0.25px;
  margin-bottom: 18px;
`;

const StyledCheckbox = styled('div')<StyledHeadingProps>(({ theme, hasError }) => ({
  label: {
    marginLeft: 0,
    marginBottom: theme.spacing(1),
    borderColor: hasError ? theme.palette.error.main : 'transparent',
    borderWidth: 2,
    borderStyle: 'solid',
    paddingRight: theme.spacing(1),

    '& :first-child': {
      paddingLeft: theme.spacing(0.5),
    },

    '& .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-root': {
      color: hasError ? theme.palette.error.main : 'inherit',
    },
  },
}));

const StyledCheckboxGridItem = styled(Grid)(({ theme }) => ({
  paddingLeft: theme.spacing(4),

  label: {
    display: 'none',
  },

  div: {
    width: '100%',
  },
}));

const StyledNoteGrid = styled(Grid)(({ theme }) => ({
  label: {
    paddingBottom: theme.spacing(1.5),
  },
}));

const GridStyled = styled(Grid)`
  & .MuiFormHelperText-root {
    margin-top: 0;
  }
`;

interface Props {
  product: LandingPageType;
  onSubmit: (data: TrackingFormProps) => void;
  onBack: () => void;
}

export type TrackingFormProps = {
  captureCode: boolean;
  facebookPixels: boolean;
  google: boolean;
  otherTracking: boolean;
  additionalTrackingNotes: string;
  captureCodeText: string;
  facebookPixelsText: string;
  googleAnalyticsText: string;
  otherChannelsText: string;
};

export default function TrackingForm(props: Props) {
  const [checkboxTextareaError, setCheckboxTextareaError] = useState(false);
  const orderFlow = useSelector(selectOrderFlow);
  const orderData = useSelector(selectOrdersContent);
  const isPunchListFlow = (orderFlow === OrderFlow.PUNCHLIST || orderData.orderFlow === OrderFlow.PUNCHLIST);

  const { product } = props;

  const methods = useForm<TrackingFormProps>({
    mode: 'all',
    defaultValues: {
      captureCode: product?.captureCode || false,
      facebookPixels: product?.facebookPixels || false,
      google: product?.google || false,
      otherTracking: product?.otherTracking || false,
      additionalTrackingNotes: product?.additionalTrackingNotes || '',
      captureCodeText: product?.captureCodeText || '',
      facebookPixelsText: product?.facebookPixelsText || '',
      googleAnalyticsText: product?.googleAnalyticsText || '',
      otherChannelsText: product?.otherChannelsText || '',
    },
  });
  const {
    handleSubmit,
    watch,
    setValue,
    formState: {
      isValid, isDirty, isSubmitting, isSubmitSuccessful, errors, touchedFields, dirtyFields,
    },
  } = methods;

  const formCheckboxValues = {
    captureCode: watch('captureCode'),
    facebookPixels: watch('facebookPixels'),
    google: watch('google'),
    otherTracking: watch('otherTracking'),
  };

  const {
    captureCode, facebookPixels, google, otherTracking,
  } = formCheckboxValues;

  const onSubmit = (data: TrackingFormProps) => {
    if(data.captureCode == false){
      data.captureCodeText = "";
    }
    if(data.facebookPixels == false){
      data.facebookPixelsText = "";
    }
    if(data.google == false){
      data.googleAnalyticsText = "";
    }
    if(data.otherTracking == false){
      data.otherChannelsText = "";
    }
    props.onSubmit(data)
  };

  type NoteFields = 'captureCodeText' | 'facebookPixelsText' | 'googleAnalyticsText' | 'otherChannelsText';

  const customValidation = isPunchListFlow
    ? yup.string()
    : yup.string().required('This is a required field.').nullable();

  const checkboxElements = checkboxes.map(checkbox => (
    <StyledCheckbox hasError={checkboxTextareaError} theme={IQThemeV2}>
      <IQCheckbox field={checkbox.field} label={checkbox.label} title=""
        hideErrors
      />
      {formCheckboxValues[checkbox.field] && (
        <StyledCheckboxGridItem item xs={7}>
          <IQFormTextArea
            id={checkbox.textAreaId}
            name={checkbox.textAreaName}
            showError
            rowCount={2}
            customValidation={customValidation}
            onBlur={() => methods.trigger(checkbox.textAreaName as keyof TrackingFormProps)}
          />
        </StyledCheckboxGridItem>
      )}
    </StyledCheckbox>
  ));


  const validCheckboxes = Object.values(formCheckboxValues).some(value => value);

  useEffect(() => {
    const isError = Object.entries(errors)
      .some(([field]) => field.toLowerCase().includes('notes'));

    if (isError) setCheckboxTextareaError(true);

    return () => setCheckboxTextareaError(false);
  }, [Object.keys(errors).length]);

  useEffect(() => {
    const allCheckboxesFalse = Object.values(formCheckboxValues).every(value => !value);

    const {
      captureCode, facebookPixels, google, otherTracking,
    } = touchedFields;

    const {
      captureCode: code, facebookPixels: facebook, google: g, otherTracking: other,
    } = dirtyFields;

    const areCheckboxesDirty = [
      captureCode, facebookPixels, google, otherTracking, code, facebook, g, other,
    ].some(field => field);

    if (allCheckboxesFalse && areCheckboxesDirty) {
      setCheckboxTextareaError(true);
    }

    return () => setCheckboxTextareaError(false);
  }, [captureCode, google, facebookPixels, otherTracking]);

  return (
    <DefaultPageLayout
      disableContinue={!isValid || !validCheckboxes}
      onBack={props.onBack}
      header={t('pages.landing.tracking.header')}
      onContinue={handleSubmit(onSubmit)}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <Box
            id="mainForm"
            component="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Grid item>
              <StyledHeading theme={IQThemeV2} hasError={checkboxTextareaError}>
                {t('pages.landing.tracking.checkboxContainerLabel')}
              </StyledHeading>
              <GridStyled item sx={{
                display: 'flex', flexDirection: 'column', gap: '6px', mb: '28px',
              }}
              >
                {checkboxElements}
                <ErrorText hasError={checkboxTextareaError} errorText={t('pages.landing.tracking.checkboxError')} />
              </GridStyled>
            </Grid>
            <StyledNoteGrid item xs={7}>
              <IQFormTextArea
                id="additional-notes"
                name="additionalTrackingNotes"
                labelText={t('pages.landing.tracking.additionalNotesLabel')}
                fontLabelWeight="600"
                fullWidth
                rowCount={2}
                spaceBottomMultiplier={1}
              />
            </StyledNoteGrid>
            {/* show the dialog if we changed the field and didn't submit the changes */}
            <CoNavigationConfirm
              showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
            />
          </Box>
        </FormProvider>
      </ValidationProvider>
    </DefaultPageLayout>
  );
}
