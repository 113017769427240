import * as yup from 'yup';
import i18n from 'i18n/i18n';

const { t } = i18n;

const schema = {
  clientHaveLocalIqWebsite: yup.string().required(t('pages.xmo.details.doesClientHaveWebsiteError')),
  localIqWebsiteLive: yup.string().required(t('pages.xmo.details.isLocaliQWebsiteLiveError')),
  websiteUrl: yup.string().required(t('pages.socialAds.socialAdsWebClicksSection.inputError.landingPageRequired')),
  temporaryWebsiteUrl: yup.string()
    .required(t('pages.socialAds.socialAdsWebClicksSection.inputError.landingPageRequired')),
  clientFullName: yup.string().required(t('pages.business.client.edit.fullNameRequired')),
  clientEmail: yup.string().required(t('pages.business.client.edit.emailError')),
  clientPhoneNumber: yup.string().required(t('pages.shoppingAds.solutionStrategy.clientsContactNumberErrorLabel')),
  sameEmailForCreativeApproval: yup.string().required(t('pages.xmo.details.creativeApprovalEmailError')),
  creativeApprovalEmail: yup.string().required(t('pages.business.client.edit.emailError')),
  bestPersonToGrantFBAdminAccess: yup.string().required(t('pages.xmo.details.facebookAdminAccessEmailError')),
  facebookAdminAccessEmail: yup.string().required(t('pages.business.client.edit.emailError')),
  bestPersonForGoogleAnalyticsAccess: yup.string()
    .required(t('pages.xmo.details.bestPersonForGoogleAnalyticsAccessError')),
  googleAnalyticsAccessContactFullName: yup.string()
    .required(t('pages.xmo.details.googleAnalyticsAccessContactFullNameError')),
  googleAnalyticsAccessEmail: yup.string().required(t('pages.business.client.edit.emailError')),
  googleAnalyticsAccessPhoneNumber: yup.string()
    .required(t('pages.shoppingAds.solutionStrategy.clientsContactNumberErrorLabel')),
};

export default {
  yupValidations: schema,
  customValidations: {},
};
