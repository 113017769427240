import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { Urls } from 'navigation/Urls';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuidv4 } from 'uuid';
import {
  addNewBusiness,
  getOrderBusiness,
  getOrderBusinessByOrderId,
  selectOrderBusiness,
  setBusinessSelectionType,
  updateBusiness,
} from 'services/businessSlice';
import {
  getOrder, selectOrderFlow, selectOrderItems, selectOrdersContent, startOrder,
} from 'services/ordersSlice';
import { createBusinessLocation } from 'services/businessLocationSlice';
import { useAppDispatch } from 'services/redux/store';
import { selectCase } from 'services/punchlistSlice';
import { OrderFlow, mediaProducts } from 'shared/constants';
import BusinessDetailsForm from './BusinessDetailsForm';

interface BusinessDetailsProps {
  newBusiness?: boolean
}

export default function BusinessDetails({ newBusiness }: BusinessDetailsProps) {
  const navigate = useNavigationHandler();
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const orderContent = useSelector(selectOrdersContent);
  const { businessId, id, orderFlow: orderDataFlow } = useSelector(selectOrdersContent);
  const { content: orderItems } = useSelector(selectOrderItems);
  const currentBusiness = useSelector(selectOrderBusiness);
  const [selectedBusinessLocations, setSelectedBusinessLocations] = useState([]);
  const orderFlow = useSelector(selectOrderFlow);
  const caseContent = useSelector(selectCase);
  const isPunchlist = (orderFlow === OrderFlow.PUNCHLIST || orderDataFlow === OrderFlow.PUNCHLIST);
  const isSalesforce = (orderFlow === OrderFlow.SALES_FORCE || orderDataFlow === OrderFlow.SALES_FORCE);
  const hasRegularPremiumListingOrderItems = orderItems.filter(item => item.offeringType === 'PremiumListing'
    && !item.healthcare);
  const hasHealthcareOrderItems = orderItems.filter(item => item.offeringType === 'PremiumListing'
    && item.healthcare);
  const hasCustomProducts = orderItems
    .filter(item => ['CustomLandingPage', 'CustomWebsite'].includes(item.offeringType)).length > 0;

  const isMediaProduct = useMemo(() => {
    const mediaProductItemsInOrder = orderItems.filter(item => mediaProducts.includes(item.pdOfferingType)
        || mediaProducts.includes(item.offeringType));

    return mediaProductItemsInOrder.length > 0;
  }, [orderItems]);

  const hasBusinessData = useMemo(() => Object.keys(currentBusiness.content).length > 0, [currentBusiness]);

  const onlyCefHealthcare = (isSalesforce
    && hasRegularPremiumListingOrderItems.length === 0
    && hasHealthcareOrderItems.length > 0);

  // This flag will help to skip the business location creation for CEF flow
  // when no Standard PL is available in cart or no Media Product
  const isLocationCreationRequired = (
    (isMediaProduct || !(isSalesforce && hasRegularPremiumListingOrderItems.length === 0)) && !onlyCefHealthcare);

  const isHealthcare = (caseContent?.neonProduct?.neonProductName === 'PremiumListing'
    && caseContent?.offer?.rlOfferName.toLowerCase().includes('healthcare'));

  const grabAddressDetailsFromLocation = (location) => {
    const newLocation: any = {};
    newLocation.locationName = location.name || location.locationName || '';
    newLocation.businessAddressOne = location.street || location.businessAddressOne || '';
    newLocation.businessAddressTwo = location.addressExtra || location.businessAddressTwo || '';
    newLocation.city = location.city || '';
    newLocation.stateProvince = location.province || location.stateProvince || '';
    newLocation.country = location.country || '';
    newLocation.postalCode = location.zip || location.postalCode;
    newLocation.sourceId = location.sourceId || '';
    newLocation.provider = (isPunchlist && isHealthcare) || false;

    return newLocation;
  };

  useEffect(() => {
    if (businessId) dispatch(getOrderBusiness(businessId));
  }, [businessId]);

  useEffect(() => {
    if (newBusiness) dispatch(setBusinessSelectionType({ isNewBusiness: true }));
  }, [newBusiness]);

  const onSubmit = async ({
    data, cefCategoryOptions, cefSubCategoryOptions, selectedCategories, subCategory,
    shouldNavigateBackOverview, shouldNavigateBackReview,
  }) => {
    let selectedCategory;
    let selectedSubCategory;

    selectedCategory = cefCategoryOptions.find(category => category.label === data.category[0] || category.value === data.category[0]);
    selectedSubCategory = cefSubCategoryOptions.find(subCategory => subCategory.label === data.subCategory[0] || subCategory.value === data.subCategory[0]);

    if (!selectedSubCategory?.value) {
      selectedSubCategory = subCategory.find(subCategory => subCategory.label === data.subCategory[0]);
    }

    const requestCategories = [];
    let useSelected = true;
    if (data.businessCategories.length !== selectedCategories.length) {
      data.businessCategories.forEach((cat) => {
        if (typeof cat === 'string') {
          const item = selectedCategories.find(e => e.label === cat);
          if (item !== undefined) requestCategories.push(item);
        }
        if (typeof cat === 'number') {
          const item = selectedCategories.find(e => e.value === cat);
          if (item !== undefined) requestCategories.push(item);
        }
      });
      useSelected = false;
    }

    const content = !newBusiness ? {
      affiliateOrder: data.affiliateOrder,
      businessCategoryOther: data?.businessCategoryOther || '',
      businessName: data.businessName,
      categories: !useSelected
        ? requestCategories.map(cat => ({ name: cat.label, categoryId: cat.value }))
        : selectedCategories.map(cat => ({ name: cat.label, categoryId: cat.value })),
      cefCategories: !hasCustomProducts ? {
        categoryId: selectedCategory.value,
        categoryName: selectedCategory.label,
        subCategoryId: selectedSubCategory.value,
        subCategoryName: selectedSubCategory.label,
      } : null,
      gmaid: data.gmaid,
      orderId: id,
      orderSourceId: null,
      sourceId: null,
    } : {
      affiliateOrder: data.affiliateOrder,
      businessCategoryOther: data?.businessCategoryOther || '',
      businessName: data.businessName,
      categories: [],
      cefCategories: null,
      gmaid: data.gmaid,
      orderId: id,
      orderSourceId: null,
      sourceId: null,
    };

    if (!businessId) {
      await appDispatch(addNewBusiness(content)).then((response) => {
        const newBusinessId = response?.payload?.id;
        if (selectedBusinessLocations.length > 0 && newBusinessId && isLocationCreationRequired) {
          selectedBusinessLocations.forEach((location) => {
            const newLocation = grabAddressDetailsFromLocation(location);
            const defaultLocationToCreate = { ...newLocation, sourceId: uuidv4() };
            dispatch(createBusinessLocation({ businessId: newBusinessId, defaultLocationToCreate }));
          });
        }
      });
    } else {
      await dispatch(updateBusiness({ id: businessId, request: content }));
    }

    await dispatch(getOrder(orderId));
    await dispatch(getOrderBusinessByOrderId(orderId));

    if (shouldNavigateBackOverview) {
      navigate.to(Urls.Overview);
    } else if (shouldNavigateBackReview) {
      navigate.to('review?reviewId=true');
    } else {
      navigate.to(Urls.BusinessClient);
    }
  };

  useEffect(() => {
    if (Object.keys(orderContent).length > 0 && !hasBusinessData && !businessId) {
      navigate.to(Urls.BusinessSearch);
    }
  }, [hasBusinessData, businessId, orderContent]);

  return (hasBusinessData || !!businessId) && (
    <BusinessDetailsForm
      onSubmit={onSubmit}
      newBusiness={newBusiness}
      setSelectedBusinessLocations={setSelectedBusinessLocations}
    />
  );
}
