import i18n from 'i18n/i18n';
import {
  TrackingCustomValidate,
  TrackingSchema, TrackingUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/TrackingSchema";
import {
  TotalTrackingCustomValidate,
  TotalTrackingSchema, TotalTrackingUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/TotalTrackingSchema";
import {
  TrackingNumber1DetailsCustomValidate,
  TrackingNumber1DetailsSchema, TrackingNumber1DetailsUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/TrackingNumber1Details";
import {
  NotesAndInstructionsCustomValidate,
  NotesAndInstructionsSchema, NotesAndInstructionsUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/NotesAndInstructionsSchema";

const {t} = i18n;

export const AusTotalTrackSchema = (data) => {
  return [
    {
      type: 'object',
      title: t('pages.ausProduct.tracking.title'),
      schema: TrackingSchema(data),
      uiSchema: TrackingUISchema,
      customValidate: TrackingCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.totalTracking.title'),
      schema: TotalTrackingSchema(data),
      uiSchema: TotalTrackingUISchema,
      customValidate: TotalTrackingCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.trackingNumber1Details.title'),
      schema: TrackingNumber1DetailsSchema(data),
      uiSchema: TrackingNumber1DetailsUISchema,
      customValidate: TrackingNumber1DetailsCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.notesAndInstructions.title'),
      schema: NotesAndInstructionsSchema(data),
      uiSchema: NotesAndInstructionsUISchema,
      customValidate: NotesAndInstructionsCustomValidate,
    },
  ];
};

export const AusTotalTrackUISchema = (data) => ({
  'ui:order': [
    ...TrackingUISchema['ui:order'],
    ...TotalTrackingUISchema['ui:order'],
    ...TrackingNumber1DetailsUISchema['ui:order'],
    ...NotesAndInstructionsUISchema['ui:order'],
  ],
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const AusTotalTrackCustomValidate = (formData: any, errors: any, uiSchema: any) => errors;
