import * as React from 'react';
import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import CheckIcon from '@mui/icons-material/Check';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { selectIsPremiumListingsHealthcare } from 'services/premiumListingsSlice';
import { adpatNewLinesInString } from 'services/leftNavMenu/util/data-util';
import CoModalConfirm from 'components/modals/CoModalConfirm';
import { TopNavStatusEnum } from 'services/TopNavStatusEnum';
import { Urls } from 'navigation/Urls';
import { selectOrderItems } from 'services/ordersSlice';

export interface IStep {
  label?: string;
  url?: string;
  offeringType?: string;
  status: 'active' | 'incomplete' | 'completed' | TopNavStatusEnum;
  orderItemId?: string;
  subSteps?: IStep[];
  builtFor?: string | number;
}

export interface LeftNavStepperProps {
  // eslint-disable-next-line react/no-unused-prop-types
  leftNavSteps: IStep[]
  defaultOpenStepIndex?: number
  onStepClick?: (stepUrl: string, offeringType: string, orderItemId: string) => void;
  toggleMenu?: boolean
}

type CombinedProps = IStep & LeftNavStepperProps;

const LeftNav = styled('nav')<Pick<LeftNavStepperProps, 'toggleMenu'>>`
  font-family: ${(props) => props.theme.typography.fontFamily};
  ${({ toggleMenu }) => (toggleMenu && css`
    margin-left: 15px;
  `)}
`;

const MainSteps = styled('ul')`
  list-style: none;
  padding-left: 0;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    width: 4px;
    height: calc(100% - 50px);
    background: ${(props) => props.theme.palette.primary.main};
    left: -2px;
    top: 0;
    z-index: -1;
    margin-top: 34px;
  }
`;

const SubMainSteps = styled('ul')`
  list-style: none;
  position: relative;
  left: -20px;
  padding: 0;
  li:first-of-type {
    padding-top: 30px;
  }
  li:last-child {
    padding-bottom: 0;
  }
`;

const Step = styled('li')`
  position: relative;
  padding: 15px 0 15px 20px;
  &.active a{
    &:after {
    content: "";
      background-color: ${(props) => props.theme.palette.primary.main};
      width: 6px;
      height: 6px;
      border-radius: 50%;
      position: absolute;
      top: 61%;
      transform: translateY(-50%);
      left: -23px;
    }
  }
`;

const SubStep = styled('li')`
  padding: 15px 0 15px 20px;
  &.active a{
    &:after {
    content: "";
      background-color: ${(props) => props.theme.palette.primary.main};
      width: 6px;
      height: 6px;
      border-radius: 50%;
      position: absolute;
      top: 61%;
      transform: translateY(-50%);
      left: -23px;
    }
  }
`;

const LinkContainer = styled('span')<Pick<CombinedProps, 'status' | 'toggleMenu'>>`
  width: 80%;
  position: relative;
  display: inline-block;
  ${({ status, toggleMenu, theme }) => (status === 'active' && css`
    &:after {
      content: "";
      border-bottom: 4px solid ${theme.palette.primary.main};
      width: ${toggleMenu ? '0' : '100%'};
      height: 4px;
      position: absolute;
      bottom: -10px;
      left: 0px;
    }
  `)}
`;

const Link = styled('a') <Pick<IStep, 'status'>>`
  &:hover {
    text-decoration: underline;
      }
  text-decoration: none;
  position: relative;
  color: ${({ status }) => {
    switch (status) {
      case 'completed':
        return (props) => props.theme.palette.text.secondary;
      default:
        return (props) => props.theme.palette.common.black;
    }
  }};
  font-weight: ${({ status }) => {
    switch (status) {
      case 'active':
        return 'bold';
      default:
        return 'initial';
    }
  }};
  font-size: 0.875rem;
  line-height: 1rem;
  ${({ status, theme }) => (status === 'active' && css`
  &:after {
        content: '';
        background-color: ${theme.palette.primary.main};
        width: 6px;
        height: 6px;
        border-radius: 50%;
        position: absolute;
        top: 10px;
        transform: translateY(-50%);
        left: -23px;
      }
  `)}
  &:before {
    content: "";
    position: absolute;
    background-color: ${({ status }) => {
    switch (status) {
      case 'completed':
        return (props) => props.theme.palette.primary.main;
      case 'incomplete':
        return (props) => props.theme.palette.common.white;
      case 'active':
        return (props) => props.theme.palette.common.white;
      default:
        return 'transparent';
    }
  }};
    width: 14px;
    height: 14px;
    left: -30px;
    top: 0px;
    border-radius: 50%;
    border: 3px solid ${(props) => props.theme.palette.primary.main};
  }
`;

const SubLink = styled('a') <Pick<IStep, 'status'>>`
  text-decoration: none;
  position: relative;
  color: ${({ status }) => {
    switch (status) {
      case 'completed':
        return (props) => props.theme.palette.text.secondary;
      default:
        return (props) => props.theme.palette.common.black;
    }
  }};
  font-weight: ${({ status }) => {
    switch (status) {
      case 'active':
        return 'bold';
      default:
        return 'initial';
    }
  }};
  font-size: 12px;
  line-height: 1rem;
  &:hover {
    text-decoration: underline;
      }
  
  ${({ status, theme }) => (status === 'active' && css`
    &:after {
      content: "";
      background-color: ${theme.palette.primary.main};
      width: 4px;
      height: 4px;
      border-radius: 50%;
      position: absolute;
      top: 55%;
      transform: translateY(-50%);
      left: -22px;
    }
  `)}
  &:before {
    content: "";
    position: absolute;
    background-color: ${({ status }) => {
    switch (status) {
      case 'completed':
        return (props) => props.theme.palette.primary.main;
      case 'incomplete':
        return (props) => props.theme.palette.common.white;
      case 'active':
        return (props) => props.theme.palette.common.white;
      default:
        return 'transparent';
    }
  }};
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    left: -26px;
    top: 2px;
    border-radius: 50%;
    border: 2px solid ${(props) => props.theme.palette.primary.main};
  }
`;

const ArrowIconStyled = styled(KeyboardArrowDownIcon)`
  position: absolute;
  color: ${(props) => props.theme.palette.common.black};
  top: 12px;
  right: 0;
  font-size: 1.2rem;
  cursor: pointer;
  transition: 0.3s;
`;

export default function NeLeftNavStepper({
  defaultOpenStepIndex,
  leftNavSteps,
  onStepClick,
  toggleMenu,
}: LeftNavStepperProps) {
  const [openStepIndex, setOpenStepIndex] = useState(defaultOpenStepIndex);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [triggeredUrl, setTriggeredUrl] = useState(null);
  const isHealthCareOrder = useSelector(selectIsPremiumListingsHealthcare);
  const { content: orderItems } = useSelector(selectOrderItems);

  const history: any = useLocation();
  const isRedirectedFromOverviewPage = React.useMemo(() => history.state?.previousPath.includes('overview'), [history]);

  const { orderItemId } = useParams();
  const { pathname } = useLocation();
  const toggleStep = (index: number) => {
    const newIndex = index === openStepIndex ? null : index;
    setOpenStepIndex(newIndex);
  };

  const isSalesforce = orderItems?.filter(item => item?.salesForceOfferId)?.length > 0;

  useEffect(() => {
    if (!orderItemId) {
      let index = -1;
      if (pathname.includes('businesses')) {
        index = 1;
      } else {
        index = leftNavSteps.findIndex(step => step.url === pathname.split('/').at(-1));
        index = index === -1 ? 1 : index;
      }
      setOpenStepIndex(index);
    } else setOpenStepIndex(defaultOpenStepIndex);
  }, [orderItemId, defaultOpenStepIndex, pathname]);

  const onClickLink = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    url: string,
    offeringType: string,
    orderItemId: string,
  ) => {
    e.preventDefault();
    if (isRedirectedFromOverviewPage) {
      setTriggeredUrl({ url, offeringType, orderItemId });
      setIsModalOpen(true);
      return;
    }
    if (url) onStepClick?.(url, offeringType, orderItemId);
  };

  const getCustomLabel = (label, builtFor) => {
    if (label === 'Generic Product') {
      const filterItem = orderItems.filter(item => item.id === builtFor);
      return filterItem?.[0]?.pdName;
    }
    if (label === 'Premium Listings') {
      return isHealthCareOrder ? adpatNewLinesInString('Premium Listings\n1-9: Healthcare')
        : adpatNewLinesInString('Premium Listings\n1-9');
    }
    return label;
  };

  const handleClose = () => setIsModalOpen(false);

  const confirmNavigation = () => {
    const { url, offeringType, orderItemId } = triggeredUrl;
    if (url) onStepClick?.(url, offeringType, orderItemId);
  };

  return (
    <LeftNav toggleMenu={toggleMenu}>
      <MainSteps>
        {React.Children.toArray(leftNavSteps.map(({
          label, subSteps, status, url, offeringType, orderItemId, builtFor,
        }, index) => (
          <Step>
            <LinkContainer onClick={() => toggleStep(index)} status={status} toggleMenu={toggleMenu}>
              <Link status={status} href="/" onClick={(e) => onClickLink(e, url, offeringType, orderItemId)}>
                {status === 'completed' && (
                  <CheckIcon
                    sx={{
                      position: ' absolute',
                      left: '-28px',
                      fontSize: ' 16px',
                      top: '2px',
                      color: 'common.white',
                    }}
                  />
                )}
                {!toggleMenu && getCustomLabel(label, builtFor)}
              </Link>
            </LinkContainer>
            { !toggleMenu && !!subSteps?.length
            && (
              <ArrowIconStyled
                sx={{ transform: `rotate( ${openStepIndex === index ? '180' : '0'})deg)` }}
                onClick={() => toggleStep(index)}
              />
            )}
            {openStepIndex === index && subSteps?.length && (
              <SubMainSteps>
                {React.Children.toArray(subSteps.map((subStep) => ((
                  !isSalesforce
                  || (isSalesforce && subStep.url !== Urls.BusinessCampaign))) && (
                  <SubStep>
                    <SubLink
                      status={subStep.status}
                      href="/"
                      onClick={(e) => onClickLink(e, subStep.url, offeringType, orderItemId)}
                    >
                      {subStep.status === 'completed' && (
                        <CheckIcon sx={{
                          position: ' absolute',
                          fontSize: '9px',
                          left: '-25px',
                          top: '4px',
                          color: 'common.white',
                        }}
                        />
                      )}
                      {!toggleMenu && subStep.label}
                    </SubLink>
                  </SubStep>
                )))}
              </SubMainSteps>
            )}
          </Step>

        )))}
      </MainSteps>
      <CoModalConfirm title="Leave page?"
        description="Are you sure you want to leave this page? The information you have entered may not be saved."
        confirmText="Leave page" cancelText="Stay on page"
        open={isModalOpen} handleClose={handleClose}
        handleConfirm={confirmNavigation}
      />
    </LeftNav>
  );
}
