import {
  Typography, Box, Grid, Link,
} from '@mui/material';
import GridItem from 'components/grid/GridItem';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from 'services/redux/store';
import { useParams } from 'react-router';
import { getProduct } from 'services/productsSlice';
import { displayNotRequiredField, editButton } from 'pages/overview/Overview';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { Urls } from 'navigation/Urls';
import OrderTracker from 'pages/orders/OrderTracker';
import { ProductTypes, getFiles } from 'services/fileUploaderSlice';
import { siteTypeOptions } from 'pages/seo/details/DetailsPageConstants';
import { brightStarOptions, neighborlyOptions } from 'pages/customSeo/solutionStrategy/CustomSolutionConstants';
import { headingStyle } from '../Business/BusinessSection';
import { actionItems } from '../common/actionItems';
import { GeneralQuestionsSection } from '../generalQuestions/GeneralQuestionsSection';
import { displayAssets } from '../sites/sitesSection';

export default function SeoSection({ product, isEditable, showTracker }: any) {
  const appDispatch = useAppDispatch();
  const navigate = useNavigationHandler();
  const { orderId } = useParams();
  const [seoData, setSeoData] = useState({} as any);
  const [existingAssets, setExistingAssets] = useState([]);

  const getProductData = (orderItemId) => {
    appDispatch(getProduct({
      orderItemId,
    })).then((response) => setSeoData(response.payload));
  };

  const getAssets = (offeringId, offeringType) => {
    appDispatch(getFiles({
      websiteId: offeringId,
      uploaderType: ProductTypes.SOW_DOCUMENT,
      offeringType,
    })).then((response) => setExistingAssets(response.payload));
  };

  useEffect(() => {
    if (product) {
      getProductData(product?.id);
      getAssets(product?.offeringId, product.pdOfferingType);
    }
  }, [product, orderId]);

  const offeringType = product.offeringType === 'ProductsData' ? product.pdOfferingType : product.offeringType;
  const solutionStrategyUrl = offeringType === 'SEOStandard' ? Urls.SolutionStrategy : Urls.CustomSolutionStrategy;
  const status = product?.trackerStatus === 'Completed' ? 'Seo Live' : product?.trackerStatus;

  const valueInArray = (values) => (values?.length > 0 ? values.join(', ') : null);

  const filterTier = (options, tier) => options.filter(opt => opt.value === tier)?.[0]?.label;

  const subTypeTierLabel = (type, tier) => {
    switch (type) {
      case 'gannett-brightStar':
        return filterTier(brightStarOptions, tier);
      case 'national-neighborly':
        return filterTier(neighborlyOptions, tier);
      default:
        return 'No subtype tier';
    }
  };

  return (
    <Grid container sx={{ borderTop: '1px solid #716F6F' }}>
      {/* Status Tracker */}
      {showTracker && (
      <GridItem sizes={[12, 12]} marginBottom={8} sx={{
        padding: '40px 0 0', borderBottom: '1px solid #000000',
      }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {editButton(isEditable, () => {})}
          <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Order Status</Typography>
        </Box>
        <Box>
          <OrderTracker
            key={product?.id}
            status={status}
            productName={product?.pdOfferingType}
            product={product}
          />
        </Box>
      </GridItem>
      )}

      {/* General Questions */}
      { GeneralQuestionsSection(offeringType, product.offeringId, product.id, navigate, isEditable) }

      {/* Details */}
      <GridItem sizes={[12, 12]} marginBottom={3} sx={{ padding: '20px 0 0', borderTop: '1px solid black' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {editButton(isEditable, () => navigate.to(
            Urls.SeoDetails,
            product.id,
          ))}
          <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Details</Typography>
        </Box>
        <Box sx={{ marginTop: '15px' }}>
          {/* Website */}
          <Typography sx={{ fontSize: '18px', margin: '10px 0 20px' }}>Website</Typography>
          <GridItem sizes={[6, 6]} sx={{ borderBottom: '1px solid #000000' }}>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Website URL</Typography>
                <Typography>{seoData?.data?.websiteUrl}</Typography>
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Localiq Site Live</Typography>
                <Typography textTransform="capitalize">{seoData?.data?.isSiteLive}</Typography>
              </Box>
            </Box>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Localiq or Byos</Typography>
                <Typography>
                  {displayNotRequiredField('option selected', siteTypeOptions
                    .filter(opt => opt.value === seoData?.data?.siteType)?.[0]?.label)}
                </Typography>
              </Box>
            </Box>
          </GridItem>
          {/* BYOS */}
          {seoData?.data?.siteType === 'byos'
          && (
          <>
            <Typography sx={{ fontSize: '18px', margin: '10px 0 20px' }}>BYOS</Typography>
            <GridItem sizes={[6, 6]} sx={{ borderBottom: '1px solid #000000' }}>
              <Box>
                {seoData?.data?.notesAndAttachments?.hasAttachments && (
                <Box mb={2}>
                  <Typography sx={headingStyle} color="primary">Notes & Attachments</Typography>
                  <Typography sx={{ display: 'block' }}>
                    <Link href={seoData?.data?.notesAndAttachments?.viewUrl} target="_blank">
                      {seoData?.data?.notesAndAttachments?.attachmentTitle}
                    </Link>
                  </Typography>
                </Box>
                )}
                <Box mb={2}>
                  <Typography sx={headingStyle} color="primary">Web Developer Acknowledgement</Typography>
                  <Typography>{seoData?.data?.byosAcknowledgement ? 'Yes' : 'No'}</Typography>
                </Box>
                <Box mb={2}>
                  <Typography sx={headingStyle} color="primary">Web Developer Contact</Typography>
                  <Typography textTransform="capitalize">{seoData?.data?.isContactInfoAvailable}</Typography>
                </Box>
              </Box>
              <Box>
                <Box>
                  <Typography sx={headingStyle} color="primary">Web Developer Contact Information</Typography>
                  <Typography>
                    {displayNotRequiredField(
                      'contact information',
                      seoData?.data?.developerName,
                    )}
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography>{seoData?.data?.developerEmail}</Typography>
                  {seoData?.data?.developerPhone ? (
                    <Typography>
                      {`${seoData?.data?.developerPhone}${seoData?.data?.developerPhoneExtension
                        && ` ext.${seoData.data.developerPhoneExtension}`}`}
                    </Typography>
                  ) : (
                    <Typography fontWeight="bold">No Phone Number</Typography>
                  )}
                </Box>
              </Box>
            </GridItem>
          </>
          )}
          {/* Contact Info */}
          <Typography sx={{ fontSize: '18px', margin: '20px 0 20px' }}>Contact Info</Typography>
          <GridItem sizes={[6, 6]}>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Main Contact for Seo Campaign</Typography>
                <Typography>{seoData?.data?.mainContactName}</Typography>
                <Typography>{seoData?.data?.mainContactEmail}</Typography>
                <Typography>
                  {`${seoData?.data?.mainContactPhone}${seoData?.data?.mainContactPhoneExtension
                    && ` ext.${seoData.data.mainContactPhoneExtension}`}`}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">
                  Contact for Google analytics, Google
                  search console, and/or GBP access
                </Typography>
                <Typography>{seoData?.data?.accessContactName}</Typography>
                <Typography>{seoData?.data?.accessContactEmail}</Typography>
                <Typography>
                  {`${seoData?.data?.accessContactPhone}${seoData?.data?.accessContactPhoneExtension
                    && ` ext.${seoData.data.accessContactPhoneExtension}`}`}
                </Typography>
              </Box>
            </Box>
          </GridItem>
        </Box>
      </GridItem>

      {/* Solution Strategy */}
      <GridItem sizes={[12, 12]} marginBottom={3} sx={{ padding: '20px 0 0', borderTop: '1px solid black' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {editButton(isEditable, () => navigate.to(
            solutionStrategyUrl,
            product.id,
          ))}
          <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Solution Strategy</Typography>
        </Box>
        <Box sx={{ marginTop: '15px' }}>
          <GridItem sizes={[6, 6]}>

            <Box>
              {offeringType === 'SEOStandard'
            && (
            <>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Services/Products</Typography>
                {displayNotRequiredField('services/products', valueInArray(seoData?.data?.services))}
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Cities/Towns (Geos)</Typography>
                {seoData?.data?.areas.length > 0 ? (
                  seoData?.data?.areas?.map(area => (
                    <Typography key={area}>{area}</Typography>
                  ))) : (
                    <Typography fontWeight="bold">No cities/towns (GEOs)</Typography>
                )}
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Keywords</Typography>
                {displayNotRequiredField('keywords', valueInArray(seoData?.data?.keywords))}
              </Box>
            </>
            )}
              {offeringType === 'SEOCustom'
            && (
            <>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Custom Seo Subtype</Typography>
                <Typography textTransform="capitalize">{seoData?.data?.subType}</Typography>
              </Box>
              {
                (seoData?.data?.subType === 'national-neighborly' || seoData?.data?.subType === 'gannett-brightStar')
                && (
                <Box mb={2}>
                  <Typography sx={headingStyle} color="primary">Custom Seo Subtype Tier</Typography>
                  <Typography textTransform="capitalize">
                    {subTypeTierLabel(seoData?.data?.subType, seoData?.data?.subTypeTier)}
                  </Typography>
                </Box>
                )
              }
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Custom Sow signed</Typography>
                <Typography textTransform="capitalize">{seoData?.data?.customSow}</Typography>
              </Box>
              {
                (seoData?.data?.subType !== 'neighborly' && seoData?.data?.subType !== 'brightStar')
                && (
                <Box mb={2}>
                  <Typography sx={headingStyle} color="primary">Custom sow uploaded</Typography>
                  {displayAssets(existingAssets, 'Uploaded File')}
                </Box>
                )
              }
            </>
            )}
            </Box>
            <Box>
              {
                (seoData?.data?.subType === 'neighborly' || seoData?.data?.subType === 'brightStar')
                && (
                <Box mb={2}>
                  <Typography sx={headingStyle} color="primary">Custom sow uploaded</Typography>
                  {displayAssets(existingAssets, 'Uploaded File')}
                </Box>
                )
              }
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Competitors</Typography>
                {displayNotRequiredField('competitors', seoData?.data?.competitors)}
              </Box>
              {offeringType === 'SEOStandard'
              && (
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Strategy or Goal</Typography>
                {displayNotRequiredField('strategy or goal', seoData?.data?.strategy)}
              </Box>
              )}
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Additional Notes/Instructions</Typography>
                {displayNotRequiredField('Additional Notes/Instructions', seoData?.data?.additionalNotes)}
              </Box>
            </Box>
          </GridItem>
        </Box>
      </GridItem>

      {/* Actions items */}
      {showTracker && actionItems(false, product?.id)}
    </Grid>
  );
}
