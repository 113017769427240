/* eslint-disable react/no-danger */
import {
  Box, Grid, IconButton, Toolbar, Typography, Divider, Collapse, Link,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useLocation, useNavigate, useParams } from 'react-router';
import {
  Children, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Theme,
  ThemeFilterType,
  convertPreviewString,
  getThemeBenefit,
  getThemeBenefitsId,
  getThemeById,
  getThemeCollaterals,
  getThemeFeature,
  getThemeFeaturesId,
  getThemesByFamily,
  resetCurrent,
  selectAllAddons,
  selectCurrentTheme,
  selectCurrentThemeBenefits,
  selectCurrentThemeCollaterals,
  selectCurrentThemeFeatures,
  selectMenuThemeTypes,
  setFilter,
  setFilteredThemes,
  setImageUrl,
} from 'services/themesSlice';
import { getOrderItems, requestNewOrder, selectOrdersContent } from 'services/ordersSlice';
import { getThemeFamilyById } from 'services/adminSlice';
import { Urls } from 'navigation/Urls';
import { setProduct } from 'services/topNavMenuSlice';
import { mapAddons } from 'pages/salesforce-entry/OpportunityData';
import CoCarousel from '../../../components/carousel/CoCarousel';
import CollateralSection from './CollateralSection';
import ThemeVariants from './ThemeVariants';
import BundlesSection from './BundlesSection';
import {
  productNameMap, seoAddons, seoBlogProducts, seoProducts, smmAddons, smmProducts,
} from '../ThemeConstants';

export const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

const SectionTitle = (props: any) => (
  <Typography fontWeight="500" fontSize="22px" marginBottom={3}
    {...props}
  >
    {props.children}
  </Typography>
);

export default function ThemeDetails() {
  const navigate = useNavigate();
  const { opportunityId } = useParams();
  const query = useQuery();
  const dispatch = useDispatch();
  const currentOrder = useSelector(selectOrdersContent);
  const currentTheme = useSelector(selectCurrentTheme);
  const currentThemeFeatures = useSelector(selectCurrentThemeFeatures);
  const currentThemeBenefits = useSelector(selectCurrentThemeBenefits);
  const currentThemeCollaterals = useSelector(selectCurrentThemeCollaterals);
  const addons = useSelector(selectAllAddons);
  const menuThemes = useSelector(selectMenuThemeTypes);
  const [selectedVariant, setSelectedVariant] = useState(null as Theme);
  const [showDetailsBelow, setShowDetailsBelow] = useState(false);
  const [dynamicProduct, setDynamicProduct] = useState('');
  const noCollateralSites = ['website - standard', 'website - gold'];

  const isSeo = [...seoProducts, ...seoBlogProducts, ...seoAddons].includes(currentTheme.product || dynamicProduct);
  const isSmm = [...smmProducts, ...smmAddons].includes(currentTheme.product || dynamicProduct);

  const isBlue = useMemo(() => query.get('isBlue'), [query]);
  const partnerAccountId = useMemo(() => query.get('partnerAccount'), [query]);
  const checkForBlue = `?isBlue=${isBlue}`;

  useEffect(() => {
    if (Object.keys(currentOrder).length === 0) {
      dispatch(requestNewOrder({}));
    } else dispatch(getOrderItems(currentOrder.id));
  }, [currentOrder]);

  useEffect(() => {
    const id = query.get('id');
    const product = query.get('product');
    if (id) {
      dispatch(getThemeById(parseInt(id, 10)));
      dispatch(getThemeBenefitsId(parseInt(id, 10)));
      dispatch(getThemeCollaterals(parseInt(id, 10)));
      dispatch(getThemeFeaturesId(parseInt(id, 10)));
    }

    if (product) setDynamicProduct(product);
  }, [query]);

  useEffect(() => {
    if (currentTheme?.familyId) {
      dispatch(getThemeFamilyById(currentTheme.familyId));
      dispatch(getThemesByFamily(currentTheme.familyId));
    }
  }, [currentTheme]);

  const fetch = async (id, action) => {
    await dispatch(action(id));
  };

  useEffect(() => {
    if (currentThemeFeatures.idList.length > 0) {
      currentThemeFeatures.idList.forEach(async id => {
        await fetch(id, getThemeFeature);
      });
    }
  }, [currentThemeFeatures.idList]);

  useEffect(() => {
    if (currentThemeBenefits.idList.length > 0) {
      currentThemeBenefits.idList.forEach(async id => {
        await fetch(id, getThemeBenefit);
      });
    }
  }, [currentThemeBenefits.idList]);

  const goToProduct = async (product) => {
    if (Array.isArray(product)) {
      dispatch(setFilter({ type: ThemeFilterType.PRODUCT_TYPE, value: product }));
      const allChildren = [];
      product.forEach(type => {
        const filtered = menuThemes.filter(theme => theme.product.replaceAll(' ', '+') === type);
        if (filtered.length > 0) filtered.forEach(item => allChildren.push(item));
      });
      dispatch(setFilteredThemes(allChildren));
    } else {
      await dispatch(setFilter({ type: ThemeFilterType.PRODUCT_TYPE, value: product }));
      const filteredThemes = menuThemes.filter(theme => theme.product.replaceAll(' ', '+') === product);
      await dispatch(setFilteredThemes(filteredThemes));
    }

    dispatch(setProduct(product));
    dispatch(resetCurrent({}));
    const url = partnerAccountId ? `/${Urls.Themes}?product=${product}&partnerAccount=${partnerAccountId}` : `/${Urls.Themes}?product=${product}`;
    navigate(!opportunityId ? url : `/${Urls.Cef}/${opportunityId}${checkForBlue}`);
  };

  const returnProducts = () => {
    if (isSeo) return partnerAccountId ? [...seoProducts, 'seo-topic-location'] :  [...seoProducts, ...seoBlogProducts, ...seoAddons];
    if (isSmm) return partnerAccountId ? ['smm-custom', 'smm-instagram', 'smm-infographics', 'smm-pinterest'] : [...smmProducts, ...smmAddons];
    const productName = currentTheme.product || dynamicProduct;
    return productName.replaceAll(' ', '+');
  };

  const filterAddons = () => {
    const addonName = query.get('addon');
    if (opportunityId && addonName) {
      return addons.filter(addon => addon.name === mapAddons[addonName]);
    }
    return addons;
  };

  return (
    <Box maxWidth="lg" width="90%" sx={{ margin: 'auto' }}>
      <Toolbar />
      <Grid container sx={{ marginTop: 5 }}>
        <Grid item xs={12}>
          <IconButton onClick={() => goToProduct(returnProducts())} sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            textDecoration: 'none',
            color: '#000000',
            paddingLeft: 0,
            marginBottom: '24px',
            '&:hover': {
              background: 'transparent',
            },
          }}
          >
            <ChevronLeftIcon fontSize="large" />
            <Typography>{productNameMap[currentTheme.product || dynamicProduct]}</Typography>
          </IconButton>
        </Grid>

        <Grid item xs={8} paddingRight={3}>
          <ThemeVariants
            theme={currentTheme?.id ? currentTheme : {
              id: 0,
              title: productNameMap[dynamicProduct],
              product: dynamicProduct,
            }}
            setSelectedVariant={setSelectedVariant}
            noVariants={!!dynamicProduct}
          />
          {currentTheme.product === 'master addons' && (
            <>
              <Grid container marginTop={5} marginBottom={2}>
                {Children.toArray(filterAddons().filter(addon => addon.name !== 'Pop-up').map(addon => (
                  <>
                    <Grid item xs={1}>
                      <img
                        src={`/assets/addons/${addon.imageUrl}`}
                        alt={addon.imageUrl}
                        style={{
                          width: '40px',
                          height: '40px',
                          // eslint-disable-next-line max-len
                          filter: 'invert(52%) sepia(84%) saturate(4399%) hue-rotate(182deg) brightness(105%) contrast(102%)',
                        }}
                      />
                    </Grid>
                    <Grid item xs={11} marginBottom={3}>
                      <Typography fontWeight="bold">{addon.name}</Typography>
                      <Typography>{addon.description}</Typography>
                      {addon.exampleUrl && (
                      <Link
                        sx={{
                          textDecoration: 'none',
                          fontFamily: 'Unify Sans',
                          fontSize: '14px',
                          marginLeft: '0',
                        }}
                        href={addon.exampleUrl}
                        target="_blank"
                        rel="noopener"
                      >
                        View Example
                      </Link>
                      )}
                    </Grid>
                  </>
                )))}
              </Grid>
              <Divider sx={{ marginBottom: 5, marginTop: 5 }} />
            </>
          )}
          {currentThemeFeatures?.featuresContent.length > 0 && (
          <>
            <Grid container marginTop={5} marginBottom={2}>
              {Children.toArray(currentThemeFeatures.featuresContent.map(feature => (
                <>
                  <Grid item xs={1}>
                    {feature?.iconFileName && (
                      <img
                        src={`/assets/features/${feature.iconFileName}`}
                        alt={feature.iconFileName}
                        style={{ width: '40px', height: '40px' }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={11} marginBottom={3}>
                    <Typography fontWeight="bold">{feature.title}</Typography>
                    <Typography>{feature.description}</Typography>
                  </Grid>
                </>
              )))}
            </Grid>
            <Divider sx={{ marginBottom: 5, marginTop: 5 }} />
          </>
          )}
          <Grid container marginTop={5}>
            <Grid item xs={12}>
              {(currentTheme.description
                || currentTheme.detailsAboveTheFold
                || currentTheme.detailsBelowTheFold) && <SectionTitle>Details</SectionTitle> }
              {(currentTheme.description
                && !currentTheme.detailsAboveTheFold
                && !currentTheme.detailsBelowTheFold) && (
                  <Typography>
                    <div dangerouslySetInnerHTML={{ __html: currentTheme.description }} />
                  </Typography>
              )}
              {currentTheme.detailsAboveTheFold && <Typography>{currentTheme.detailsAboveTheFold}</Typography>}
              {currentTheme.detailsBelowTheFold && (
              <>
                <IconButton
                  sx={{
                    padding: 0, marginTop: 3, marginBottom: 3, '&:hover': { backgroundColor: 'transparent' },
                  }}
                  onClick={() => setShowDetailsBelow(!showDetailsBelow)}
                >
                  <Typography color="#0000ff" fontWeight="bold">Read more about this theme</Typography>
                  <ExpandMoreIcon htmlColor="#0000ff" />
                </IconButton>
                <Collapse in={showDetailsBelow}>
                  <Typography>{currentTheme.detailsBelowTheFold}</Typography>
                </Collapse>
              </>
              )}
            </Grid>
          </Grid>
          {(currentTheme.description || currentTheme.detailsAboveTheFold) && (
            <Divider sx={{ marginBottom: 5, marginTop: 6 }} />)}
          {currentThemeBenefits.benefitsContent?.length > 0 && (
          <>
            <Grid container>
              <Grid item xs={12}>
                <SectionTitle>Benefits</SectionTitle>
                {![...seoProducts, ...seoBlogProducts].includes(currentTheme.product)
                  ? Children.toArray(currentThemeBenefits.benefitsContent.map(benefit => (
                    <Box marginBottom={4} key={benefit.title}>
                      <Typography fontWeight="bold" marginBottom={1}>{benefit.title}</Typography>
                      <Typography>{benefit.description}</Typography>
                    </Box>
                  ))) : (
                    <ul style={{ paddingInlineStart: '20px' }}>
                      {Children.toArray(currentThemeBenefits.benefitsContent.map(benefit => (
                        <li><Typography>{benefit.description}</Typography></li>
                      )))}
                    </ul>
                  )}
              </Grid>
            </Grid>
            <Divider sx={{ marginBottom: 5, marginTop: 2 }} />
          </>
          )}
          {(currentTheme.notes && [...seoProducts, ...seoBlogProducts, ...smmProducts]
            .includes(currentTheme.product)) && (
            <Grid container mb={12}>
              <Grid item xs={12}>
                <SectionTitle>Notes</SectionTitle>
                <Typography sx={{ whiteSpace: 'pre-wrap' }}>{currentTheme.notes}</Typography>
              </Grid>
            </Grid>
          )}
          {currentTheme?.themePreview && (
          <>
            <Grid container>
              <Grid item xs={12}>
                <SectionTitle>Picture & Video</SectionTitle>
                <CoCarousel
                  pictures={
                    convertPreviewString(currentTheme.themePreview)
                      ?.map(file => setImageUrl(currentTheme.id, file, 'theme_preview'))
                    || []
                  }
                />
              </Grid>
            </Grid>
            <Divider sx={{ marginBottom: 3, marginTop: 6 }} />
          </>
          )}
          {(currentThemeCollaterals?.length > 0
          && currentThemeCollaterals[0].label
          && !noCollateralSites.includes(currentTheme.product))
          && (
            <>
              <Grid container>
                <Grid item xs={12}>
                  <SectionTitle>Collateral</SectionTitle>
                  <CollateralSection collaterals={currentThemeCollaterals} />
                </Grid>
              </Grid>
              <Divider sx={{ marginBottom: 5, marginTop: 2 }} />
            </>
          )}
        </Grid>
        <Grid item xs={4} paddingLeft={3}>
          <Typography marginBottom={2} variant="h2">&nbsp;</Typography>
          <BundlesSection
            theme={currentTheme?.id ? currentTheme : {
              id: 0,
              title: dynamicProduct,
              product: dynamicProduct,
            }}
            selectedVariant={selectedVariant}
            isBlue={isBlue === 'true'}
            partnerAccount={partnerAccountId}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
