/* eslint-disable radix */
/* eslint-disable max-len */
import { useTheme } from '@emotion/react';
import {
  IQCheckbox, IQDatePicker, IQFormTextArea, ValidationProvider,
} from '@gannettdigital/shared-react-components';
import { Divider, Typography } from '@mui/material';
import GridItem from 'components/grid/GridItem';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBusinessLocation,
  getLocationSpecialHours,
  selectCurrentLocation,
  selectSpecialHours,
  setSpecialHours,
  updateLocationHours,
  updateLocationNotesAndOpening,
  updateLocationSpecialHours,
} from 'services/businessLocationSlice';
import { useLocation, useParams } from 'react-router';
import { getOrderBusinessByOrderId, selectOrderBusiness } from 'services/businessSlice';
import ErrorText from 'components/errorText/ErrorText';
import GridContainer from 'components/grid/GridContainer';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import {
  getOrderItems, selectOrderFlow, selectOrderItems, selectOrdersContent,
} from 'services/ordersSlice';
import { savePlaceDetail, selectPlaceDetail } from 'services/placeDetailSlice';
import { locationsTypes } from 'layouts/StepperLayout';
import { TitleWithBadge } from 'pages/premium-listings/TitleWithBadge';
import { yupResolver } from '@hookform/resolvers/yup';
import { OrderFlow } from 'shared/constants';
import SpecialHours from './SpecialHours';
import HoursOfOperation from './HoursOfOperation';
import { serializeDate } from './HoursFunctions';
import WeekOperationHours from './WeekOperationHours';

const dashProducts = ['DMSNextCustomerCenterByDash'];
const ZERO_HOURS = '00:00';
const COMMA = ', ';
const AM = 'AM';
const PM = 'PM';
const TWELVE = '12';
const ZERO = '0';

export default function LocationHours(props: any) {
  const { type, schema, defaultValues } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { orderId, locationId } = useParams();
  const [hasDashProduct, setHasDashProduct] = useState(null);
  const { content: orderItems } = useSelector(selectOrderItems);
  const locationSpecialHours = useSelector(selectSpecialHours);
  const location = useSelector(selectCurrentLocation);
  const { content: { sameHours } } = useSelector(selectOrderBusiness);
  const [days, setDays] = useState(null);
  const history: any = useLocation();
  const isRedirectedFromOverviewPage = useMemo(() => history.state?.previousPath.includes('overview'), [history]);
  const { placeDetail, addressPagePlaceId, hoursPagePlaceId } = useSelector(selectPlaceDetail);
  const hoursObject = placeDetail ? placeDetail.hoursOfOperation : {};
  const ordersContent = useSelector(selectOrdersContent);
  const { orderFlow: orderDataFlow } = ordersContent;
  const orderFlow = useSelector(selectOrderFlow);
  const isPunchlist = (orderFlow === OrderFlow.PUNCHLIST || orderDataFlow === OrderFlow.PUNCHLIST);

  const isStandard = type === locationsTypes.STANDARD;
  const isPl = ([locationsTypes.PREMIUM_LISTINGS, locationsTypes.HEALTHCARE].includes(type) && !location?.isProvider);
  const isHc = (type === locationsTypes.HEALTHCARE && location?.isProvider);

  const defaultWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
    .map(weekday => ({
      weekday,
      open: false,
      hours: [
        {
          opensAt: null,
          closesAt: null,
        },
      ],
    }));

  let hoursArray = [];
  const weekdayOrder = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  if (Object.keys(hoursObject).length === 0) {
    hoursArray = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
      .map(weekday => ({
        weekday,
        open: true,
        hours: [
          {
            opensAt: null,
            closesAt: null,
          },
        ],
      }));
  } else {
    hoursArray = Object.keys(hoursObject).map((item, key) => {
      if (hoursObject[item] === 'Closed') {
        return {
          hours: [{
            opensAt: null,
            closesAt: null,
          }],
          id: key + 1,
          open: false,
          weekday: item.toLowerCase(),
        };
      }
      if (hoursObject[item] === 'Open 24 hours') {
        return {
          hours: [{
            opensAt: ZERO_HOURS,
            closesAt: ZERO_HOURS,
          }],
          id: key + 1,
          open: true,
          weekday: item.toLowerCase(),
        };
      }
      const timeRegex = /(\d{1,2}):(\d{2})\s*(AM|PM)?\s*–\s*(\d{1,2}):(\d{2})\s*(AM|PM)/;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const hoursInfo = hoursObject[item].split(COMMA);
      let opensAt: string;
      let closesAt: string;

      const hoursInfoData = hoursInfo ? hoursInfo.map((time) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [fullTime, openHour, openMinute, openPeriod, closeHour, closeMinute, closePeriod] = time.match(timeRegex);
        if ((openPeriod === AM && closePeriod === AM) || (openPeriod === undefined && closePeriod === AM)) {
          opensAt = `${(parseInt(openHour === TWELVE ? ZERO : openHour)).toString().padStart(2, ZERO)}:${openMinute}`;
          closesAt = `${(parseInt(closeHour === TWELVE ? ZERO : closeHour)).toString().padStart(2, ZERO)}:${closeMinute}`;
        } else if (openPeriod === AM && closePeriod === PM) {
          opensAt = `${openHour.padStart(2, ZERO)}:${openMinute}`;
          closesAt = `${(parseInt(closeHour === TWELVE ? ZERO : closeHour, 10) + 12).toString().padStart(2, ZERO)}:${closeMinute}`;
        } else if (openPeriod === PM && closePeriod === AM) {
          opensAt = `${(parseInt(openHour === TWELVE ? ZERO : openHour, 10) + 12).toString().padStart(2, ZERO)}:${openMinute}`;
          closesAt = `${(parseInt(closeHour === TWELVE ? ZERO : closeHour)).toString().padStart(2, ZERO)}:${closeMinute}`;
        } else if ((openPeriod === PM && closePeriod === PM) || (openPeriod === undefined && closePeriod === PM)) {
          opensAt = `${(parseInt(openHour === TWELVE ? ZERO : openHour, 10) + 12).toString().padStart(2, ZERO)}:${openMinute}`;
          closesAt = `${(parseInt(closeHour, 10) + 12).toString().padStart(2, ZERO)}:${closeMinute}`;
        }

        return {
          opensAt,
          closesAt,
        };
      }) : [{
        opensAt: null,
        closesAt: null,
      }];

      return {
        hours: hoursInfoData,
        id: key + 1,
        open: true,
        weekday: item.toLowerCase(),
      };
    }).sort((a, b) => weekdayOrder.indexOf(a.weekday) - weekdayOrder.indexOf(b.weekday));
  }

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(schema.yupValidations),
    defaultValues: {
      ...defaultValues,
    },
  });

  const {
    handleSubmit, setValue, getValues, formState: {
      isValid, errors, isDirty, isSubmitSuccessful, isSubmitting,
    },
  } = methods;

  useEffect(() => {
    if (orderItems.length === 0) {
      dispatch(getOrderItems(orderId));
    } else {
      const filteredProducts = orderItems.filter(item => dashProducts.includes(item?.pdOfferingType));
      setHasDashProduct(orderItems.length === 1 && filteredProducts.length > 0);
    }
  }, [orderItems]);

  useEffect(() => {
    if (orderId) dispatch(getOrderBusinessByOrderId(orderId));
  }, [orderId]);

  useEffect(() => {
    dispatch(getBusinessLocation(locationId));
    if (isPl || isHc) dispatch(getLocationSpecialHours(locationId));
  }, [locationId, isHc, isPl]);

  useEffect(() => {
    if (sameHours && (isStandard || isPl)) {
      setValue('applyHoursToAll', sameHours);
    }
    if (isPunchlist) {
      setValue('applyHoursToAll', false);
    }
  }, [sameHours, isStandard, isPl]);

  useEffect(() => {
    if (locationSpecialHours && (isPl || isHc)) {
      setValue('specialHours', locationSpecialHours);

      // API GETresponse for From and To dates are combined like this, eg: "04/01/2023 - 04/30/2023"
      locationSpecialHours.forEach((day, index) => {
        const dates = day.dates.split(' - ');
        setValue(`specialHours.${index}.dateFrom`, dates[0]);
        setValue(`specialHours.${index}.dateTo`, dates[1]);
        setValue(`specialHours.${index}.closed`, !day.closed);
      });
    }
  }, [locationSpecialHours, isPl, isHc]);

  useEffect(() => {
    if (location) {
      if (location.grandOpeningDate !== null) {
        setValue('grandOpeningDate', new Date(location.grandOpeningDate));
      } else {
        setValue('grandOpeningDate', null);
      }

      if (isPl || isHc) setValue('openingHourNotes', location.hoursNotes);

      if (location.hours?.length > 0) {
        const { hours } = location;
        const daysShaped = defaultWeek;
        if (Array.isArray(hours) && hours.length > 0) {
          hours.forEach(hour => {
            let parseHoursFrom = JSON.parse(hour.fromX);
            let parseHoursTo = JSON.parse(hour.toX);
            const hours = [];
            if (typeof parseHoursFrom !== 'object') {
              parseHoursFrom = JSON.parse(parseHoursFrom);
              parseHoursTo = JSON.parse(parseHoursTo);
            }

            if (!hour.closed) {
              hours.push({
                opensAt: parseHoursFrom.from1,
                closesAt: parseHoursTo.to1,
              });

              if (Object.keys(parseHoursFrom).length > 1) {
                hours.push({
                  opensAt: parseHoursFrom.from2,
                  closesAt: parseHoursTo.to2,
                });
              }
            }

            const objIndex = daysShaped.findIndex((day => day.weekday === hour.dayOfTheWeek));
            daysShaped[objIndex] = {
              weekday: hour.dayOfTheWeek,
              open: true,
              hours,
            };
            if (addressPagePlaceId !== '' && hoursPagePlaceId === '') {
              setDays(hoursArray);
            } else if (addressPagePlaceId === hoursPagePlaceId) {
              setDays(daysShaped);
            } else {
              setDays(hoursArray);
            }
          });
        }
      } else {
        setDays(hoursArray);
      }
    }
  }, [location]);

  useEffect(() => {
    if (days) {
      setValue('days', days);
      days.forEach((day, index) => {
        setValue(`days.${index}`, day);
        if (day.hours[0].opensAt === ZERO_HOURS && day.hours[0].closesAt === ZERO_HOURS) {
          setValue(`days.${index}.hours`, [{
            opensAt: ZERO_HOURS,
            closesAt: ZERO_HOURS,
            allDay: true,
          }]);
        } else setValue(`days.${index}.hours`, day.hours);
        day.hours.forEach((hour, hourIndex) => {
          setValue(`days.${index}.hours.${hourIndex}`, hour as never);
          if (hour.opensAt === ZERO_HOURS && hour.closesAt === ZERO_HOURS) {
            setValue(`days.${index as number}.hours.${0 as number}.allDay`, true);
          }
        });
      });
    }
  }, [days]);

  const hoursRequest = async () => {
    const data = getValues();
    const days = data.days.map(day => {
      if (day.hours.length > 1) {
        return {
          closed: !day.open,
          dayOfWeek: day.weekday,
          hours: {
            from_1: day.hours[0].opensAt,
            from_2: day.hours[1].opensAt,
            to_1: day.hours[0].closesAt,
            to_2: day.hours[1].closesAt,
          },
        };
      }

      return {
        closed: !day.open,
        dayOfWeek: day.weekday,
        hours: {
          from_1: day.hours[0].opensAt,
          to_1: day.hours[0].closesAt,
        },
      };
    });

    const businessHoursTotalRequest = {
      businessHoursRequests: days.filter(day => day.closed === false),
      sameHours: data.applyHoursToAll,
    };
    await dispatch(updateLocationHours({ locationId, businessHoursTotalRequest }));
  };

  const grandOpDateRequest = async () => {
    const data = getValues('grandOpeningDate');
    const notes = (isPl || isHc) ? getValues('openingHourNotes') : '';
    const grandOpeningDate = data instanceof Date
      ? serializeDate(data)
      : data;
    const date = grandOpeningDate !== null ? new Date(grandOpeningDate) : null;
    const resource = { grandOpeningDate: date !== null ? date.toISOString() : null, notes };
    await dispatch(updateLocationNotesAndOpening({ locationId, resource }));
  };

  // API PUT for dates are expecting like this, eg: "04/01/2023"
  const convertDateToStringFormat = (date) => {
    const offset = date.getTimezoneOffset() * 60000;
    const localDate = new Date(date.getTime() - offset);
    return localDate.toISOString().slice(0, 10).replace(/-/g, '/');
  };

  const specialHoursRequest = () => {
    // eslint-disable-next-line array-callback-return
    const data = getValues('specialHours');
    const formattedData = data.map(row => {
      const isAllDay = row.specialFromHour1 === '00:00' && row.specialToHour1 === '00:00';
      return {
        dateFrom: typeof row.dateFrom === 'string' ? row.dateFrom : convertDateToStringFormat(row.dateFrom),
        dateTo: typeof row.dateFrom === 'string' ? row.dateFrom : convertDateToStringFormat(row.dateFrom),
        specialFromHour1: typeof row.specialToHour1 === 'string' ? row.specialFromHour1 : '',
        specialToHour1: typeof row.specialToHour1 === 'string' ? row.specialToHour1 : '',
        specialFromHour2: typeof row.specialFromHour2 === 'string' && !isAllDay ? row.specialFromHour2 : '',
        specialToHour2: typeof row.specialToHour2 === 'string' && !isAllDay ? row.specialToHour2 : '',
        allDay: isAllDay || row.allDay,
        secondTimeSlot: row.secondTimeSlot,
        closed: !row.closed,
      };
    });
    const resource = { specialHoursRequest: formattedData };
    dispatch(updateLocationSpecialHours({ locationId, resource }));
  };

  const onFormSubmit = async () => {
    dispatch(savePlaceDetail({
      placeDetail,
      addressPagePlaceId: addressPagePlaceId || '',
      hoursPagePlaceId: addressPagePlaceId || '',
    }));
    await grandOpDateRequest();
    if (isPl || isHc) await specialHoursRequest();
    await hoursRequest();

    if (isPl || isHc) dispatch(setSpecialHours([]));
    props.onContinue();
  };

  const hasError = Object.keys(errors).length > 0;

  useEffect(() => {
    props.onUpdateStatus(getValues(), (isValid && !hasError));
  }, [isValid, hasError, hasDashProduct]);

  return hasDashProduct !== null && (
    <ValidationProvider schema={schema}>
      <FormProvider {...methods}>
        <form id={props.formId} onSubmit={handleSubmit(onFormSubmit)}>
          <GridContainer fullWidth sx={{
            maxWidth: '130% !important',
            width: '130%',
          }}
          >
            <GridItem marginBottom={0}>
              <Typography variant="overline" marginBottom={0}
                sx={{
                  textTransform: 'initial',
                  fontSize: '18px',
                  color: 'text.secondary',
                }}
              >
                Locations
              </Typography>
            </GridItem>
            <GridItem>
              <Typography variant="h2" marginBottom={3}>{t('pages.business.location.hours.title')}</Typography>
              {(isPl || isHc) && (
              <TitleWithBadge
                title={t('pages.premiumListings.location.hours.hoursOfOperation.title')}
                badge={t('pages.premiumListings.badge')}
                tooltip={t('pages.premiumListings.badgeTooltip')}
              />
              )}
            </GridItem>
            {isStandard ? <HoursOfOperation isPunchlist={isPunchlist} /> : <WeekOperationHours />}
            {(isPl || isHc) && (
            <>
              <GridItem sizes={[12]}>
                <IQFormTextArea
                  id="openingHourNotes"
                  name="openingHourNotes"
                  labelText={t('pages.premiumListings.location.hours.openingHourNotes')}
                  fontLabelWeight="bold"
                  fullWidth
                />
              </GridItem>
              <SpecialHours />
              {!isPunchlist && (
              <GridItem marginTop={3}>
                <IQCheckbox
                  label={t('pages.premiumListings.location.hours.hoursOfOperation.applyHoursToAll')}
                  field="applyHoursToAll"
                  name="applyHoursToAll"
                />
              </GridItem>
              )}
              <GridItem sizes={[8]}>
                <Divider />
              </GridItem>
            </>
            )}
            <GridItem>
              {isStandard ? (
                <Typography variant="h6">{t('pages.business.location.hours.grandOpeningDate')}</Typography>
              ) : (
                <TitleWithBadge
                  title={t('pages.premiumListings.location.hours.grandOpeningDate')}
                  badge={t('pages.premiumListings.badge')}
                  tooltip={t('pages.premiumListings.badgeTooltip')}
                />
              )}
            </GridItem>
            <GridItem>
              <Typography>{t('pages.business.location.hours.grandOpeningDatePrompt')}</Typography>
            </GridItem>
            <GridItem marginBottom={1}>
              <IQDatePicker
                id="grandOpeningDate"
                name="grandOpeningDate"
                labelText={t('pages.business.location.hours.grandOpeningDate')}
                fontLabelWeight="bold"
                fullWidth
                theme={theme}
                disablePast={false}
                minDate={new Date('01-01-1800')}
                openPickerOnClick
              />
              <ErrorText
                hasError={!!errors?.grandOpeningDate}
                errorText={t('pages.business.location.hours.grandOpeningFormat')}
              />
            </GridItem>
          </GridContainer>
        </form>
        <CoNavigationConfirm
          showDialog={isDirty && !(isSubmitSuccessful || isSubmitting) && isRedirectedFromOverviewPage}
        />
      </FormProvider>
    </ValidationProvider>
  );
}
