/* eslint-disable @typescript-eslint/no-unused-expressions */
import { IQButtonLink } from '@gannettdigital/shared-react-components';
import { Box, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import { Urls } from 'navigation/Urls';
import { Trans, useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import CoModalConfirm from 'components/modals/CoModalConfirm';
import { useDispatch, useSelector } from 'react-redux';
import {
  createClient,
  deleteClient,
  getClients, selectClients, setClients,
} from 'services/clientsSlice';
import { useParams } from 'react-router-dom';
import { getBusinessRoles } from 'services/businessRolesSlice';
import NeStatusCard from 'components/statusCard/NeStatusCard';
import { getIsPremiumListingsHealthcare, selectIsPremiumListingsHealthcare } from 'services/premiumListingsSlice';
import {
  selectIsPartnerFlow,
  selectOrderFlow,
  selectOrderItems,
  selectOrdersContent
} from 'services/ordersSlice';
import { isCustomProduct } from 'layouts/leftnav/LeftNavMenu';
import { OrderFlow } from 'shared/constants';
import { defaultClient } from './ClientData';
import { ClientStatus } from './ClientType';

const defaultClientToRemove = { client: defaultClient, index: -1 };

export default function ClientDetails() {
  const navigate = useNavigationHandler();
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const clients = useSelector(selectClients);
  const { content: orderItems } = useSelector(selectOrderItems);
  const isHealthCareOrder = useSelector(selectIsPremiumListingsHealthcare);
  const orderData = useSelector(selectOrdersContent);
  const orderFlow = useSelector(selectOrderFlow);

  const [clientToRemove, setClientToRemove] = useState(defaultClientToRemove);
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);
  const [clientsCompleted, setClientsCompleted] = useState(false);
  const isSalesForce = orderFlow === OrderFlow.SALES_FORCE;
  const isPunchlist = (orderFlow === OrderFlow.PUNCHLIST || orderData.orderFlow === OrderFlow.PUNCHLIST);
  const isPartnerFlow = useSelector(selectIsPartnerFlow);
  useEffect(() => {
    if (orderId) {
      dispatch(getClients(orderId));
      dispatch(getBusinessRoles({}));
      dispatch(getIsPremiumListingsHealthcare(orderId));
    }
  }, [orderId]);

  const noEmptyEmails = (list) => {
    const emptyMails = list.filter(e => e === '').length;
    if (emptyMails === 0) return true;

    return false;
  };

  useEffect(() => {
    if (clients.length) {
      const status = [];
      clients.forEach(client => {
        const {
          contactPhoneNumberList,
          contactEmailList,
          contact: { clientFullName },
        } = client;

        if (clientFullName !== ''
        && contactPhoneNumberList.length > 0
        && contactEmailList.length > 0
        && contactEmailList[0] !== ''
        && noEmptyEmails(contactEmailList)) {
          status.push('complete');
        } else status.push('incomplete');
      });

      const incompleteClients = status.filter(e => e === 'incomplete');
      incompleteClients.length === 0 ? setClientsCompleted(true) : setClientsCompleted(false);
    }
  }, [clients]);

  const onContinue = () => {
    if (isCustomProduct(orderItems) || isSalesForce || isPunchlist || isPartnerFlow) {
      const isPremiumListing = orderItems.some(item => item.offeringType === 'PremiumListing');

      if (isPremiumListing && isHealthCareOrder) {
        return navigate.to(Urls.PlHealthcareLocations);
      }
      return navigate.to(Urls.BusinessLocation);
    }
    return navigate.to(Urls.BusinessCampaign);
  };

  const onBack = () => navigate.to(Urls.BusinessDetail);

  const addClient = async () => {
    const contact = {
      contact: {
        id: null,
        businessRoleId: null,
        clientFullName: '',
        email: '',
        firstName: '',
        lastName: '',
        sourceId: null,
      },
      contactEmailList: [''],
      contactPhoneNumberList: [],
    };
    await dispatch(createClient({ orderId, contact }));
    await dispatch(getClients(orderId));
  };

  const openRemoveDialog = (client, index) => {
    setClientToRemove({ client, index });
    setShowRemoveDialog(true);
  };

  const removeClient = async () => {
    if (clientToRemove.client.contact.id) await dispatch(deleteClient(clientToRemove.client.contact.id));
    const updatedList = clients.slice();
    updatedList.splice(clientToRemove.index, 1);
    dispatch(setClients(updatedList));
    setClientToRemove(defaultClientToRemove);
    setShowRemoveDialog(false);
  };

  const cancelClientRemove = () => {
    setClientToRemove(defaultClientToRemove);
    setShowRemoveDialog(false);
  };

  const onClientSelect = (id) => {
    navigate.to(`businesses/client/${id}/edit`);
  };

  const verifyStatus = (client) => {
    const {
      contactPhoneNumberList,
      contactEmailList,
      contact: { clientFullName, businessRoleId },
    } = client;

    if ((clientFullName === '' || clientFullName === null)
    && contactPhoneNumberList.length === 0
    && (contactEmailList[0] === '' || contactEmailList.length === 0)) {
      return ClientStatus.NOT_STARTED;
    }

    if (clientFullName !== ''
      && contactPhoneNumberList.length > 0
      && contactEmailList.length > 0
      && contactEmailList[0] !== ''
      && noEmptyEmails(contactEmailList)
      && businessRoleId !== null) { return ClientStatus.COMPLETE; }

    return ClientStatus.REVIEW;
  };

  const transformClient = (client, index) => {
    const transformedData = {
      ...client,
      status: verifyStatus(client),
    };
    const textColumns = [
      {
        title: t('pages.business.client.nameLabel'),
        placeholder: t('pages.business.client.namePlaceholder'),
        value: client?.contact.clientFullName,
      },
      {
        title: t('pages.business.client.phoneLabel'),
        placeholder: t('pages.business.client.phonePlaceholder'),
        value: client?.contactPhoneNumberList[0]?.phoneNumber,
      },
      {
        title: t('pages.business.client.emailLabel'),
        placeholder: t('pages.business.client.emailPlaceholder'),
        value: client?.contactEmailList[0] || '',
      },
    ];
    return (
      <NeStatusCard
        key={transformedData.id}
        status={transformedData.status}
        textColumns={textColumns}
        onDelete={() => openRemoveDialog(transformedData, index)}
        onSelect={() => onClientSelect(transformedData.contact.id)}
      />
    );
  };

  return (
    <DefaultPageLayout
      disableContinue={clients.length === 0 || !clientsCompleted}
      onContinue={onContinue}
      onBack={onBack}
      header={t('pages.business.client.title')}
      description={t('pages.business.client.requirement')}
    >
      {clients && clients.map((client, index) => transformClient(client, index))}
      <Box
        sx={{
          padding: '20px 20px 20px 0',
          backgroundColor: clients.length === 0 ? theme.palette.primary.light : 'initial',
        }}
      >
        <IQButtonLink startIcon={<AddIcon />} onClick={addClient}>
          {t('pages.business.client.add')}
        </IQButtonLink>
      </Box>
      <CoModalConfirm open={showRemoveDialog}
        title={t('pages.business.client.removeModal.title')}
        description={(
          <Trans
            i18nKey="pages.business.client.removeModal.message"
            context={clientToRemove.client.contact.clientFullName === '' ? 'without-names' : null}
            values={{
              name: clientToRemove.client.contact.clientFullName,
              phone: clientToRemove.client.contactPhoneNumberList[0]?.phoneNumber,
            }}
            components={{ strong: <strong /> }}
          />
        )}
        confirmText="Remove"
        handleClose={cancelClientRemove}
        handleConfirm={() => removeClient()}
      />
    </DefaultPageLayout>
  );
}
