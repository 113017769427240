import { t } from 'i18next';
import * as yup from 'yup';

const yupSchema = {
  reason: yup.string().required(t('pages.landing.custom.details.reasonRequired')),
  openToStandardPage: yup.string().required(),
};
export default {
  yupValidations: yupSchema,
  customValidations: {},
};
