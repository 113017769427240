/* eslint-disable max-len */
import i18n from 'i18n/i18n';

import {RJSFSchema} from '@rjsf/utils';
import Dropdown from 'pages/generic-product/details/custom-fields/Dropdown';

const {t} = i18n;

export const TrackingNumber1DetailsSchema: RJSFSchema | any = (data) => ({
  title: t('pages.ausProduct.trackingNumber1Details.title'),
  type: 'object',
  required: [
    'phoneNumberOnWebsiteToBeMasked',
    'destinationOrTerminationNumber',
    'trackingNumberType',
    'tollFreeNumberFeesBeenCharged',
    'regionForTrackingNumber'
  ],
  properties: {
    phoneNumberOnWebsiteToBeMasked: {
      type: "string",
      title: t('pages.ausProduct.trackingNumber1Details.phoneNumberOnWebsiteToBeMaskedLabel'),
      default: '',
    },
    destinationOrTerminationNumber: {
      type: "string",
      title: t('pages.ausProduct.trackingNumber1Details.destinationOrTerminationNumberLabel'),
      default: '',
      description: t('pages.ausProduct.trackingNumber1Details.destinationOrTerminationNumberDescription')
    },
    trackingNumberType: {
      type: 'string',
      title: t('pages.ausProduct.trackingNumber1Details.trackingNumberTypeLabel'),
      default: '',
      enum: [
        t('pages.ausProduct.trackingNumber1Details.localGeo'),
        t('pages.ausProduct.trackingNumber1Details.mobile'),
        t('pages.ausProduct.trackingNumber1Details.phone'),
        t('pages.ausProduct.trackingNumber1Details.1300Au'),
        t('pages.ausProduct.trackingNumber1Details.1800Au'),
        t('pages.ausProduct.trackingNumber1Details.0800Nz'),
      ],
      description: t('pages.ausProduct.trackingNumber1Details.trackingNumberTypeDescription'),
    },
    tollFreeNumberFeesBeenCharged: {
      type: 'string',
      title: t('pages.ausProduct.trackingNumber1Details.tollFreeNumberFeesBeenChargedLabel'),
      default: '',
      enum: [
        t('pages.ausProduct.tracking.yes'),
        t('pages.ausProduct.tracking.no'),
      ],
    },
    regionForTrackingNumber: {
      type: "string",
      title: t('pages.ausProduct.trackingNumber1Details.regionForTrackingNumberLabel'),
      default: '',
      description: t('pages.ausProduct.trackingNumber1Details.regionForTrackingNumberDescription')
    },
  },

});

const textFieldKeys = [
  'phoneNumberOnWebsiteToBeMasked',
  'destinationOrTerminationNumber',
  'regionForTrackingNumber'
];

const textFields = textFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'textInputField'},
}), {});

const dropdownFieldKeys = [
  'trackingNumberType',
  'tollFreeNumberFeesBeenCharged',
];

const dropdownFields = dropdownFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {
    'ui:widget': (props) => (
        <Dropdown
            {...props}
            errorMessage={t('pages.ausProduct.tracking.requiredFieldErrorMessage')}
        />
    ),
  },
}), {});

export const TrackingNumber1DetailsUISchema = {
  'ui:order': [
    'phoneNumberOnWebsiteToBeMasked',
    'destinationOrTerminationNumber',
    'trackingNumberType',
    'tollFreeNumberFeesBeenCharged',
    'regionForTrackingNumber'
  ],
  ...dropdownFields,
  ...textFields
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const TrackingNumber1DetailsCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.phoneNumberOnWebsiteToBeMasked === '') {
    errors.phoneNumberOnWebsiteToBeMasked.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  if (formData?.destinationOrTerminationNumber === '') {
    errors.destinationOrTerminationNumber.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  if (formData?.trackingNumberType === '') {
    errors.trackingNumberType.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  if (formData?.tollFreeNumberFeesBeenCharged === '') {
    errors.tollFreeNumberFeesBeenCharged.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  if (formData?.regionForTrackingNumber === '') {
    errors.regionForTrackingNumber.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  return errors;
};
