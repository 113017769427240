import {
  facebookBoostedPostsCustomValidate,
  facebookBoostedPostsSchema,
  facebookBoostedPostsUISchema,
} from 'pages/generic-product/details/product-schema/SmmFacebookPostsSchema';
import {
  InfographicsCustomValidate,
  InfographicsSchema,
  InfographicsUISchema,
} from 'pages/generic-product/details/product-schema/SmmInfographicsSchema';
import {
  SmmTwitterCustomValidate,
  SmmTwitterSchema,
  SmmTwitterUISchema,
} from 'pages/generic-product/details/product-schema/SmmTwitterStrategySchema';
import {
  ShoppingAdsCustomValidate,
  ShoppingAdsSchema,
  ShoppingAdsUISchema,
} from 'pages/generic-product/details/product-schema/ShoppingAdsSchema';
import {
  CustomReportingCustomValidate,
  CustomReportingSchema,
  CustomReportingUISchema,
} from 'pages/generic-product/details/product-schema/CustomReportingSchema';
import {
  CustomSolutionSpotifySchema,
  CustomSolutionSpotifyUISchema,
  CustomSolutionSpotifyValidate,
} from 'pages/generic-product/details/product-schema/CustomSolutionSpotifySchema';
import {
  LocalServiceAdsSchema,
  LocalServiceAdsSchemaCustomValidate,
  LocalServiceAdsUISchema,
} from 'pages/generic-product/details/product-schema/LocalServiceAdsSchema';
import {
  YelpCustomValidate,
  YelpSchema,
  YelpUISchema,
} from 'pages/generic-product/details/product-schema/YelpSchema';
import {
  ChatHybridCustomValidate,
  ChatHybridSchema,
  ChatHybridUISchema,
} from 'pages/generic-product/details/product-schema/ChatHybridAddOnSchema';
import {
  DisplayCreativeServiceCustomValidate,
  DisplayCreativeServiceSchema,
  DisplayCreativeServiceUISchema,
} from 'pages/generic-product/details/product-schema/DisplayCreativeService';
import {
  CustomSolutionsSnapchatCustomValidate,
  CustomSolutionsSnapchatSchema,
  CustomSolutionsSnapchatUISchema,
} from 'pages/generic-product/details/product-schema/CustomSolutionsSnapchatSchema';
import {
  GTMCustomValidate,
  GTMSchema,
  GTMUISchema,
} from 'pages/generic-product/details/product-schema/GTMSchema';
import {
  SMBRetailGCIOnlyResponsiveSiteCustomValidate,
  SMBRetailGCIOnlyResponsiveSiteSchema,
  SMBRetailGCIOnlyResponsiveSiteUISchema,
} from 'pages/generic-product/details/product-schema/SMBRetailGCIOnlyResponsiveSite';
import {
  CustomTrackingCustomValidate,
  CustomTrackingSchema,
  CustomTrackingUISchema,
} from 'pages/generic-product/details/product-schema/CustomTracking';
import {
  SiteRetargetingWithLookalikesGDNCustomValidate,
  SiteRetargetingWithLookalikesGDNSchema,
  SiteRetargetingWithLookalikesGDNUISchema,
} from 'pages/generic-product/details/product-schema/SiteRetargetingWithLookalikesGDN';

import {
  CustomSolutionPandoraSchema,
  CustomSolutionPandoraUISchema,
  CustomSolutionPandoraValidate,
} from 'pages/generic-product/details/product-schema/CustomSolutionPandoraSchema';
import {
  CustomSolutionsTiktokSchema,
  CustomSolutionsTiktokSchemaCustomValidate,
  CustomSolutionsTiktokUISchema,
} from 'pages/generic-product/details/product-schema/CustomSolutionsTiktokSchema';
import {
  SmbRetailGCISmartListingsCustomValidate,
  SmbRetailGCISmartListingsSchema,
  SmbRetailGCISmartListingsUISchema,
} from 'pages/generic-product/details/product-schema/SmbRetailGCISmartListingsSchema';
import {
  VideoCreativeServiceCustomValidate,
  VideoCreativeServiceSchema,
  VideoCreativeServiceUISchema,
} from 'pages/generic-product/details/product-schema/VideoCreativeService';
import {
  SmbRetailGCIVisiblePresenceCustomValidate,
  SmbRetailGCIVisiblePresenceSchema,
  SmbRetailGCIVisiblePresenceUISchema,
} from 'pages/generic-product/details/product-schema/SmbRetailGCIVisiblePresenceSchema';

import {
  SMBRetailGCIOnly20PageSiteCustomValidate,
  SMBRetailGCIOnly20PageSiteSchema,
  SMBRetailGCIOnly20PageSiteUISchema,
} from 'pages/generic-product/details/product-schema/SMBRetailGCIOnly20PageSiteSchema';
import {
  SMBRetailGCIOnly5PageSiteCustomValidate,
  SMBRetailGCIOnly5PageSiteSchema,
  SMBRetailGCIOnly5PageSiteUISchema,
} from 'pages/generic-product/details/product-schema/SMBRetailGCIOnly5PageSiteSchema';
import {
  TargetedEmailCustomValidate,
  TargetedEmailSchema,
  TargetedEmailUISchema,
} from 'pages/generic-product/details/product-schema/TargetedEmailSchema';
import {
  RLBrandedContentCustomValidate,
  RLBrandedContentSchema,
  RLBrandedContentUISchema,
} from 'pages/generic-product/details/product-schema/RLBrandedContent';

import {
  CustomSolutionLinkedinSchema,
  CustomSolutionLinkedinUISchema,
  CustomSolutionLinkedinValidate,
} from 'pages/generic-product/details/product-schema/CustomSolutionLinkedinSchema';
import {
  SocialAdsLeadAdsCustomValidate,
  SocialAdsLeadAdsSchema,
  SocialAdsLeadAdsUISchema,
} from 'pages/generic-product/details/product-schema/SocialAdsLeadAdsSchema';
import {
  SocialAdsCustomCustomValidate,
  SocialAdsCustomSchema,
  SocialAdsCustomUISchema,
} from 'pages/generic-product/details/product-schema/SocialAdsCustomSchema';
import {
  SocialAdsWebClickChildCustomValidateChange,
  SocialAdsWebClickChildSchema,
  SocialAdsWebClickChildUISchemaChange,
} from 'pages/generic-product/details/product-schema/SocialAdsWebsiteClicksSchema';
import {
  SocialAdsAdEngagementAddOnCustomValidate,
  SocialAdsAdEngagementAddOnSchema,
  SocialAdsAdEngagementAddOnUISchema,
} from 'pages/generic-product/details/product-schema/SocialAdsAdEngagementAddOn';
import {
  SocialAdsLeadAdsWithDetailedTargetingCustomValidate,
  SocialAdsLeadAdsWithDetailedTargetingSchema,
  SocialAdsLeadAdsWithDetailedTargetingUISchema,
} from 'pages/generic-product/details/product-schema/SocialAdsLeadAdsWithDetailedTargetingSchema';
import {
  SocialAdsWebsiteClicksWithDetailedTargetingCustomValidate,
  SocialAdsWebsiteClicksWithDetailedTargetingSchema,
  SocialAdsWebsiteClicksWithDetailedTargetingUISchema,
} from 'pages/generic-product/details/product-schema/SocialAdsWebsiteClicksWithDetailedTargeting';

import {
  SocialAdsWithSmartOptimizationTechnologySchema,
  SocialAdsWithSmartOptimizationTechnologyUISchema,
  SocialAdsWithSmartOptimizationTechnologyValidate,
} from 'pages/generic-product/details/product-schema/SocialAdsWithSmartOptimizationTechnologySchema';
import {
  CRMIntegrationAddonCustomValidate,
  CRMIntegrationAddonSchema,
  CRMIntegrationAddonUISchema,
} from 'pages/generic-product/details/product-schema/CRMIntegrationAddonSchema';
import {
  CSWazePilotCustomValidate,
  CSWazePilotSchema,
  CSWazePilotUISchema,
} from 'pages/generic-product/details/product-schema/CSWazePilotSchema';
import {
  CustomCampaignLandingPagesCustomValidate,
  CustomCampaignLandingPagesSchema,
  CustomCampaignLandingPagesUISchema,
} from 'pages/generic-product/details/product-schema/CustomCampaignLandingPagesSchema';
import {
  EnhancedReportingAddOnCustomValidate, EnhancedReportingAddOnSchema, EnhancedReportingAddOnUISchema,
} from 'pages/generic-product/details/product-schema/EnhancedReportingAddOnSchema';
import {
  LocalServicesAdsManagementServicesCustomValidate,
  LocalServicesAdsManagementServicesSchema,
  LocalServicesAdsManagementServicesUISchema,
} from 'pages/generic-product/details/product-schema/LocalServicesAdsManagementServicesSchema';
import {
  NationalSEMCustomValidate, NationalSEMSchema, NationalSEMUISchema,
} from 'pages/generic-product/details/product-schema/NationalSEMSchema';
import {
  PerformanceMaxBetaCustomValidate, PerformanceMaxBetaSchema, PerformanceMaxBetaUISchema,
} from 'pages/generic-product/details/product-schema/PerformanceMaxBetaSchema';
import {
  WebsiteManagedServicesCustomValidate,
  WebsiteManagedServicesSchema, WebsiteManagedServicesUISchema,
} from 'pages/generic-product/details/product-schema/WebsiteManagedServicesSchema';
import {
  WebsitesContentMigrationCustomValidate,
  WebsitesContentMigrationSchema,
  WebsitesContentMigrationUISchema,
} from 'pages/generic-product/details/product-schema/WebsitesContentMigrationSchema';
import {
  PremiumListings10PlusPhysicianDirectoriesSchema,
  PremiumListings10PlusPhysicianDirectoriesUISchema,
  PremiumListings10PlusPhysicianDirectoriesCustomValidate,
} from 'pages/generic-product/details/product-schema/PremiumListings10PlusPhysicianDirectoriesSchema';
import {
  PremiumListing10PlusCustomValidate,
  PremiumListing10PlusSchema,
  PremiumListing10PlusUIOrderSchema,
} from 'pages/generic-product/details/product-schema/PremiumListings10PlusSchema';
import {
  SocialAdsRetargetingSchema,
  SocialAdsRetargetingUISchema,
  SocialAdsRetargetingValidate,
} from 'pages/generic-product/details/product-schema/SocialAdsRetargetingSchema';
import {
  DisplayCreativeServiceCustomSchema,
  DisplayCreativeServiceCustomUISchema,
  DisplayCreativeServiceValidate,
} from 'pages/generic-product/details/product-schema/DisplayCreativeServiceCustom';
import {
  CustomSolutionTwitterSchema,
  CustomSolutionTwitterUISchema,
  CustomSolutionTwitterValidate,
} from 'pages/generic-product/details/product-schema/CustomSolutionsTwitterSchema';
import {
  promotionsPageAndCreativesReachLocalBetaOnlyCustomValidate,
  promotionsPageAndCreativesReachLocalBetaOnlySchema,
  promotionsPageAndCreativesReachLocalBetaOnlyUISchema,
} from 'pages/generic-product/details/product-schema/PromotionsPageAndCreativesReachLocalBetaOnlySchema';
import {
  SearchEngineMarketingSchema,
  SearchEngineMarketingUISchema,
  SearchEngineMarketingValidate,
} from '../product-schema/SearchEngineMarketingSchema';
import {
  SpanishChatCustomValidate,
  SpanishChatSchema,
  SpanishChatUISchema,
} from '../product-schema/SpanishChatAddonSchema';

import {
  SmmPinterestCustomValidate,
  SmmPinterestSchema,
  SmmPinterestUISchema,
} from '../product-schema/SmmPinterestStrategySchema';
import {
  SmmInstagramCustomValidate,
  SmmInstagramSchema,
  SmmInstagramUISchema,
} from '../product-schema/SmmInstagramStrategySchema';
import {
  SEOContentPagesCustomValidate,
  SeoContentPagesSchema,
  SeoContentPagesUISchema,
} from '../product-schema/SeoContentPagesSchema';
import {
  SeoTopicLocationCustomValidate,
  SeoTopicLocationSchema,
  SeoTopicLocationUISchema,
} from '../product-schema/SeoTopicLocationSchema';
import {
  WebsiteContentPagesValidate,
  WebsiteContentPagesSchema,
  WebsiteContentPagesUISchema,
} from '../product-schema/WebsiteContentPages';
import {
  GetCreativeServiceFeeSchema, GetCreativeServiceFeeUISchema, GetCreativeServiceFeeValidate,
} from '../product-schema/GetCreativeServiceFeeSchema';
import {
  AusTikTokCustomValidate,
  AusTikTokSchema,
  AusTikTokUISchema
} from "pages/generic-product/details/product-schema/AusTikTokSchema";
import {
  AusTotalTrackCustomValidate,
  AusTotalTrackSchema, AusTotalTrackUISchema
} from "pages/generic-product/details/product-schema/AusTotalTrackSchema";

const setSchema = (setSchema, setUISchema, setCustomValidate, schema) => {
  setSchema(schema.formSchema);
  setUISchema(schema.uiSchema);
  setCustomValidate(() => schema.customValidate);
};

export const getOrderItemSchema = (item, setCurrentSchema, setCurrentUISchema, setCustomValidate, data = {}) => {
  switch (item.pdOfferingType) {
    case 'SEOTopicLocation':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: SeoTopicLocationSchema(data),
        uiSchema: SeoTopicLocationUISchema,
        customValidate: SeoTopicLocationCustomValidate,
      });
      break;
    case 'SEOContentPages':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: SeoContentPagesSchema,
        uiSchema: SeoContentPagesUISchema,
        customValidate: SEOContentPagesCustomValidate,
      });
      break;
    case 'SMMFacebookPosts':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: facebookBoostedPostsSchema(data),
        uiSchema: facebookBoostedPostsUISchema,
        customValidate: facebookBoostedPostsCustomValidate,
      });
      break;
    case 'SMMInstagram':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: SmmInstagramSchema(data),
        uiSchema: SmmInstagramUISchema,
        customValidate: SmmInstagramCustomValidate,
      });
      break;
    case 'SMMInfographics':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: InfographicsSchema,
        uiSchema: InfographicsUISchema,
        customValidate: InfographicsCustomValidate,
      });
      break;
    case 'SMMX':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: SmmTwitterSchema(data),
        uiSchema: SmmTwitterUISchema,
        customValidate: SmmTwitterCustomValidate,
      });
      break;
    case 'SMMPinterest':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: SmmPinterestSchema(data),
        uiSchema: SmmPinterestUISchema,
        customValidate: SmmPinterestCustomValidate,
      });
      break;
    case 'ShoppingAds':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: ShoppingAdsSchema,
        uiSchema: ShoppingAdsUISchema,
        customValidate: ShoppingAdsCustomValidate,
      });
      break;
    case 'LocalServiceAds':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: LocalServiceAdsSchema,
        uiSchema: LocalServiceAdsUISchema(data),
        customValidate: LocalServiceAdsSchemaCustomValidate,
      });
      break;
    case 'CustomReporting':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: CustomReportingSchema(data),
        uiSchema: CustomReportingUISchema,
        customValidate: CustomReportingCustomValidate,
      });
      break;
    case 'SpanishChatAddon':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: SpanishChatSchema(data),
        uiSchema: SpanishChatUISchema,
        customValidate: SpanishChatCustomValidate,
      });
      break;
    case 'Yelp':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: YelpSchema(data),
        uiSchema: YelpUISchema(data),
        customValidate: YelpCustomValidate,
      });
      break;
    case 'ChatHybridAddon':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: ChatHybridSchema,
        uiSchema: ChatHybridUISchema,
        customValidate: ChatHybridCustomValidate,
      });
      break;
    case 'GoogleTagManager':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: GTMSchema,
        uiSchema: GTMUISchema,
        customValidate: GTMCustomValidate,
      });
      break;
    case 'DisplayCreativeServices':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: DisplayCreativeServiceSchema,
        uiSchema: DisplayCreativeServiceUISchema,
        customValidate: DisplayCreativeServiceCustomValidate,
      });
      break;
    case 'DisplayCreativeServiceCustom':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: DisplayCreativeServiceCustomSchema,
        uiSchema: DisplayCreativeServiceCustomUISchema,
        customValidate: DisplayCreativeServiceValidate,
      });
      break;
    case 'CustomSolutionsSpotify':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: CustomSolutionSpotifySchema(data),
        uiSchema: CustomSolutionSpotifyUISchema,
        customValidate: CustomSolutionSpotifyValidate,
      });
      break;
    case 'CustomSolutionsSnapchat':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: CustomSolutionsSnapchatSchema(data),
        uiSchema: CustomSolutionsSnapchatUISchema,
        customValidate: CustomSolutionsSnapchatCustomValidate,
      });
      break;
    case 'CustomSolutionsPandora':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: CustomSolutionPandoraSchema(data),
        uiSchema: CustomSolutionPandoraUISchema,
        customValidate: CustomSolutionPandoraValidate,
      });
      break;
    case 'SMBRetailGCIOnlyResponsiveSite':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: SMBRetailGCIOnlyResponsiveSiteSchema,
        uiSchema: SMBRetailGCIOnlyResponsiveSiteUISchema(data),
        customValidate: SMBRetailGCIOnlyResponsiveSiteCustomValidate,
      });
      break;
    case 'CustomSolutionsLinkedin':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: CustomSolutionLinkedinSchema(data),
        uiSchema: CustomSolutionLinkedinUISchema,
        customValidate: CustomSolutionLinkedinValidate,
      });
      break;
    case 'CustomTracking':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: CustomTrackingSchema,
        uiSchema: CustomTrackingUISchema(data),
        customValidate: CustomTrackingCustomValidate,
      });
      break;
    case 'SocialAdsSmartOptimization':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: SocialAdsWithSmartOptimizationTechnologySchema(data),
        uiSchema: SocialAdsWithSmartOptimizationTechnologyUISchema(data),
        customValidate: SocialAdsWithSmartOptimizationTechnologyValidate,
      });
      break;
    case 'CustomSolutionsTiktok':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: CustomSolutionsTiktokSchema(data),
        uiSchema: CustomSolutionsTiktokUISchema,
        customValidate: CustomSolutionsTiktokSchemaCustomValidate,
      });
      break;
    case 'SMBRetailGCIOnlySmartListings':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: SmbRetailGCISmartListingsSchema(data),
        uiSchema: SmbRetailGCISmartListingsUISchema(data),
        customValidate: SmbRetailGCISmartListingsCustomValidate,
      });
      break;
    case 'VideoCreativeServicePackage':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: VideoCreativeServiceSchema(data),
        uiSchema: VideoCreativeServiceUISchema,
        customValidate: VideoCreativeServiceCustomValidate,
      });
      break;
    case 'SMBRetailGCIOnlyVisiblePresence':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: SmbRetailGCIVisiblePresenceSchema(data),
        uiSchema: SmbRetailGCIVisiblePresenceUISchema(data),
        customValidate: SmbRetailGCIVisiblePresenceCustomValidate,
      });
      break;
    case 'SiteRetargetingWithLookalikesGDN':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: SiteRetargetingWithLookalikesGDNSchema,
        uiSchema: SiteRetargetingWithLookalikesGDNUISchema(data),
        customValidate: SiteRetargetingWithLookalikesGDNCustomValidate,
      });
      break;
    case 'SMBRetailGCIOnly20PageSite':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: SMBRetailGCIOnly20PageSiteSchema,
        uiSchema: SMBRetailGCIOnly20PageSiteUISchema,
        customValidate: SMBRetailGCIOnly20PageSiteCustomValidate,
      });
      break;
    case 'SMBRetailGCIOnly5PageSite':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: SMBRetailGCIOnly5PageSiteSchema,
        uiSchema: SMBRetailGCIOnly5PageSiteUISchema,
        customValidate: SMBRetailGCIOnly5PageSiteCustomValidate,
      });
      break;
    case 'TargetedEmail':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: TargetedEmailSchema(data),
        uiSchema: TargetedEmailUISchema,
        customValidate: TargetedEmailCustomValidate,
      });
      break;
    case 'RLBrandedContent':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: RLBrandedContentSchema(data),
        uiSchema: RLBrandedContentUISchema,
        customValidate: RLBrandedContentCustomValidate,
      });
      break;
    case 'SocialAdsLeadAds':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: SocialAdsLeadAdsSchema(data),
        uiSchema: SocialAdsLeadAdsUISchema(data),
        customValidate: SocialAdsLeadAdsCustomValidate,
      });
      break;
    case 'SocialAdsAdEngagementAddOn':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: SocialAdsAdEngagementAddOnSchema,
        uiSchema: SocialAdsAdEngagementAddOnUISchema,
        customValidate: SocialAdsAdEngagementAddOnCustomValidate,
      });
      break;
    case 'SocialAdsLeadAdswithDetailedTargeting':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: SocialAdsLeadAdsWithDetailedTargetingSchema(data),
        uiSchema: SocialAdsLeadAdsWithDetailedTargetingUISchema(data),
        customValidate: SocialAdsLeadAdsWithDetailedTargetingCustomValidate,
      });
      break;
    case 'SearchEngineMarketing':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: SearchEngineMarketingSchema(data),
        uiSchema: SearchEngineMarketingUISchema(data),
        customValidate: SearchEngineMarketingValidate,
      });
      break;
    case 'SocialAdsCustom':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: SocialAdsCustomSchema(data),
        uiSchema: SocialAdsCustomUISchema(data),
        customValidate: SocialAdsCustomCustomValidate,
      });
      break;
    case 'SocialAdsWebsiteClicks':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: SocialAdsWebClickChildSchema(data),
        uiSchema: SocialAdsWebClickChildUISchemaChange(data),
        customValidate: SocialAdsWebClickChildCustomValidateChange,
      });
      break;
    case 'SocialAdsWebsiteClicksWithDetailedTargeting':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: SocialAdsWebsiteClicksWithDetailedTargetingSchema(data),
        uiSchema: SocialAdsWebsiteClicksWithDetailedTargetingUISchema(data),
        customValidate: SocialAdsWebsiteClicksWithDetailedTargetingCustomValidate,
      });
      break;
    case 'CRMIntegrationAddon':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: CRMIntegrationAddonSchema,
        uiSchema: CRMIntegrationAddonUISchema,
        customValidate: CRMIntegrationAddonCustomValidate,
      });
      break;
    case 'CSWazePilot':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: CSWazePilotSchema,
        uiSchema: CSWazePilotUISchema,
        customValidate: CSWazePilotCustomValidate,
      });
      break;
    case 'CustomCampaignLandingPages':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: CustomCampaignLandingPagesSchema,
        uiSchema: CustomCampaignLandingPagesUISchema,
        customValidate: CustomCampaignLandingPagesCustomValidate,
      });
      break;
    case 'EnhancedReportingAddOn':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: EnhancedReportingAddOnSchema,
        uiSchema: EnhancedReportingAddOnUISchema,
        customValidate: EnhancedReportingAddOnCustomValidate,
      });
      break;
    case 'LocalServicesAdsManagementServices':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: LocalServicesAdsManagementServicesSchema,
        uiSchema: LocalServicesAdsManagementServicesUISchema,
        customValidate: LocalServicesAdsManagementServicesCustomValidate,
      });
      break;
    case 'NationalSEM':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: NationalSEMSchema,
        uiSchema: NationalSEMUISchema,
        customValidate: NationalSEMCustomValidate,
      });
      break;
    case 'PerformanceMaxBeta':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: PerformanceMaxBetaSchema,
        uiSchema: PerformanceMaxBetaUISchema,
        customValidate: PerformanceMaxBetaCustomValidate,
      });
      break;
    case 'WebsiteManagedServices':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: WebsiteManagedServicesSchema,
        uiSchema: WebsiteManagedServicesUISchema,
        customValidate: WebsiteManagedServicesCustomValidate,
      });
      break;
    case 'WebsitesContentMigration':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: WebsitesContentMigrationSchema,
        uiSchema: WebsitesContentMigrationUISchema,
        customValidate: WebsitesContentMigrationCustomValidate,
      });
      break;
    case 'PremiumListings10PlusPhysicianDirectories':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: PremiumListings10PlusPhysicianDirectoriesSchema,
        uiSchema: PremiumListings10PlusPhysicianDirectoriesUISchema,
        customValidate: PremiumListings10PlusPhysicianDirectoriesCustomValidate,
      });
      break;
    case 'PremiumListings10Plus':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: PremiumListing10PlusSchema(data),
        uiSchema: PremiumListing10PlusUIOrderSchema,
        customValidate: PremiumListing10PlusCustomValidate,
      });
      break;
    case 'GetCreativeServiceFee':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: GetCreativeServiceFeeSchema(data),
        uiSchema: GetCreativeServiceFeeUISchema,
        customValidate: GetCreativeServiceFeeValidate,
      });
      break;
    case 'SocialAdsRetargeting':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: SocialAdsRetargetingSchema(data),
        uiSchema: SocialAdsRetargetingUISchema(data),
        customValidate: SocialAdsRetargetingValidate,
      });
      break;
    case 'CustomSolutionsTwitter':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: CustomSolutionTwitterSchema(data),
        uiSchema: CustomSolutionTwitterUISchema,
        customValidate: CustomSolutionTwitterValidate,
      });
      break;
    case 'PromotionsPageAndCreativesReachLocalBetaOnly':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: promotionsPageAndCreativesReachLocalBetaOnlySchema(data),
        uiSchema: promotionsPageAndCreativesReachLocalBetaOnlyUISchema,
        customValidate: promotionsPageAndCreativesReachLocalBetaOnlyCustomValidate,
      });
      break;
    case 'WebsiteAddOnContentPages':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: WebsiteContentPagesSchema,
        uiSchema: WebsiteContentPagesUISchema,
        customValidate: WebsiteContentPagesValidate,
      });
      break;
    case 'AusTiktok':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: AusTikTokSchema,
        uiSchema: AusTikTokUISchema,
        customValidate: AusTikTokCustomValidate,
      });
      break;
    case 'AusTotalTrack':
      setSchema(setCurrentSchema, setCurrentUISchema, setCustomValidate, {
        formSchema: AusTotalTrackSchema(data),
        uiSchema: AusTotalTrackUISchema(data),
        customValidate: AusTotalTrackCustomValidate,
      });
      break;
    default:
      break;
  }
};
