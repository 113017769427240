import * as yup from 'yup';
import i18n from 'i18n/i18n';

const {t} = i18n;

const urlValidationRegex = /^(https?:\/\/)?([\da-z%&\-_]+\.)+([a-z\.]{2,6})([/\w \.-]*)*(\?[\w%&=+!-_.,]*)?(#[\w\-]*)?$/i;

const schema = {
  sitelinkName: yup.string().required(t('pages.xmo.sem.sitelinkNameErrorMessage')),
  sitelinkUrl: yup.string().matches(urlValidationRegex,t('pages.xmo.sem.sitelinkUrlErrorMessage')).required(t('pages.xmo.sem.sitelinkUrlErrorMessage')),
};

export default {
  yupValidations: schema,
  customValidations: {},
};