/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { FormProvider, useForm } from 'react-hook-form';
import { ValidationProvider } from '@gannettdigital/shared-react-components';
import GridContainer from 'components/grid/GridContainer';
import { locationsTypes } from 'layouts/StepperLayout';
import { useTranslation } from 'react-i18next';
import GridItem from 'components/grid/GridItem';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBusinessLocation,
  selectCurrentLocation,
  updateLocationBrand,
  updateLocationCompetitors,
  updateLocationDemographics,
  updateLocationFulfilledAttribute,
  updateLocationLanguages,
  updateLocationPayments,
  updateLocationServices,
  updateLocationStatus,
} from 'services/businessLocationSlice';
import NeSelectChipCheckboxes from 'components/chips/NeSelectChipCheckboxes';
import { Typography, useTheme } from '@mui/material';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import NeSelectChip from 'components/chips/NeSelectChip';
import { selectUserAccess } from 'services/userSlice';
import { getOrderItems, selectOrdersContent } from 'services/ordersSlice';
import scrollWindowToTop from 'shared/scrollWindowToTop';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { TitleWithBadge } from 'pages/premium-listings/TitleWithBadge';
import { FulfillmentCheckbox } from 'pages/premium-listings/FulfillmentCheckbox';
import {
  getServicesProductsByBusinessCategoryId,
  selectOrderBusiness,
  selectServicesProductsCategoriesSuggestions,
} from 'services/businessSlice';
import { checkKeyDown } from 'shared/sharedFunctions';
import { dummyLanguages, dummyPaymentTypes } from './LocationAdditionalData';

const MAX_ITEMS = 20;

export default function LocationAdditional(props: any) {
  const { type, schema, defaultValues } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigationHandler();
  const { orderId, locationId } = useParams();
  const location = useSelector(selectCurrentLocation);
  const isOrderCompleted = useSelector(selectOrdersContent)?.completed;
  const [serviceValues, setServiceValues] = useState([]);
  const [brandValues, setBrandValues] = useState([]);
  const [paymentsValues, setPaymentsValues] = useState(null);
  const [languagesValues, setLanguagesValues] = useState(null);
  const [demographicsValues, setDemographicsValues] = useState([]);
  const [competitorsValues, setCompetitorsValues] = useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [serviceSuggestions, setServiceSuggestions] = useState([]);

  const access = useSelector(selectUserAccess);
  const currentBusiness = useSelector(selectOrderBusiness);
  const serviceProductsSuggestions = useSelector(selectServicesProductsCategoriesSuggestions);
  const history: any = useLocation();
  const isRedirectedFromOverviewPage = useMemo(() => history.state?.previousPath.includes('overview'), [history]);

  const isStandard = type === locationsTypes.STANDARD;
  const isPl = ([locationsTypes.PREMIUM_LISTINGS, locationsTypes.HEALTHCARE].includes(type) && !location?.isProvider);
  const isHc = (type === locationsTypes.HEALTHCARE && location?.isProvider);

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      ...defaultValues,
    },
  });

  const {
    handleSubmit, getValues, setValue, watch, formState: {
      isValid, isDirty, isSubmitSuccessful, isSubmitting,
    },
  } = methods;

  useEffect(() => {
    dispatch(getBusinessLocation(locationId));
    dispatch(getOrderItems(orderId));
    scrollWindowToTop();
  }, [locationId]);

  useEffect(() => {
    if (currentBusiness.content.businessCategory && currentBusiness.content.businessCategory.length > 0) {
      const categoryIds = currentBusiness.content.businessCategory
        .map(category => category.categoryId)
        .filter(id => id != null)
        .join(',');
      const country = currentBusiness?.content?.country;
      dispatch(getServicesProductsByBusinessCategoryId({
        businessCategoryId: categoryIds,
        country,
      }));
    }
  }, [currentBusiness]);

  useEffect(() => {
    if (serviceProductsSuggestions && serviceProductsSuggestions.length > 0) {
      const flattenedServices = serviceProductsSuggestions.flatMap(category => category.services.map(service => ({
        label: service.displayName,
        value: service.displayName,
      })));
      setServiceSuggestions(flattenedServices);
    }
  }, [serviceProductsSuggestions]);

  const mapValues = (values) => {
    if (values) return values.map(value => value.name);
    return [];
  };

  const updateValues = (arr, name, setFunc) => {
    setFunc(mapValues(arr));
    setValue(name, mapValues(arr));
  };

  useEffect(() => {
    if (Object.keys(location).length > 0) {
      updateValues(location.services, 'services', setServiceValues);
      updateValues(location.brands, 'brands', setBrandValues);
      updateValues(location.payments, 'paymentTypes', setPaymentsValues);
      updateValues(location.languages, 'languages', setLanguagesValues);
      updateValues(location.demographics, 'demographic', setDemographicsValues);
      updateValues(location.competitors, 'competitors', setCompetitorsValues);
      if (isPl || isHc) setValue('fulfilledAdditionalInfo', location?.fulfilledAdditionalInfo === 'on');
    }
  }, [location, isPl, isHc]);

  const createPutRequest = async (arr, action) => {
    if (arr) {
      const resource = arr.map(el => {
        if (typeof el === 'object' && el !== null) return { name: el.name, locationId, id: el.id };
        return { name: el, locationId };
      });
      await dispatch(action({ locationId, resource }));
    }
  };

  const handleFulfillmentSubmission = async (values) => {
    const value = values?.fulfilledAdditionalInfo ? 'on' : null;
    const fulfilledAttribute = 'additionalInfo';
    if (value === null) {
      await dispatch(updateLocationFulfilledAttribute({ locationId, fulfilledAttribute }));
    } else {
      await dispatch(updateLocationFulfilledAttribute({ locationId, fulfilledAttribute, value }));
    }
  };

  useEffect(() => {
    const selectedChips = getValues('services');
    const mappedServices = selectedChips.map((value) => {
      const service = serviceSuggestions.find((service) => service.value === value);
      return {
        title: service ? service.label : value,
        googleService: service ? service.value : null,
      };
    });

    setSelectedCategories(mappedServices);
  }, [watch('services')]);

  // eslint-disable-next-line consistent-return
  const onFormSubmit = async () => {
    const values = getValues();
    await createPutRequest(values.services, updateLocationServices);
    await createPutRequest(values.brands, updateLocationBrand);
    await createPutRequest(values.paymentTypes, updateLocationPayments);
    await createPutRequest(values.languages, updateLocationLanguages);
    await createPutRequest(values.demographic, updateLocationDemographics);
    await createPutRequest(values.competitors, updateLocationCompetitors);
    if ((isPl || isHc) && isOrderCompleted && (access.isAdmin || access.canDoFulfillment)) {
      await dispatch(updateLocationStatus({ locationId }));
      await handleFulfillmentSubmission(values);
      return navigate.to('../review?reviewId=true');
    }
    await dispatch(updateLocationStatus({ locationId }));
    props.onContinue();
  };

  useEffect(() => {
    props.onUpdateStatus(getValues(), isValid);
  }, [isValid]);

  return (
    <ValidationProvider schema={schema}>
      <FormProvider {...methods}>
        <form id={props.formId} onSubmit={handleSubmit(onFormSubmit)} onKeyDown={(e) => checkKeyDown(e)}>
          {(Array.isArray(languagesValues) && Array.isArray(paymentsValues)) && (
          <GridContainer fullWidth>
            <GridItem marginBottom={0}>
              <Typography variant="overline" marginBottom={0}
                sx={{ textTransform: 'initial', fontSize: '18px', color: 'text.secondary' }}
              >
                {isHc ? 'Provider Listing' : 'Locations'}
              </Typography>
            </GridItem>
            <GridItem>
              <Typography variant="h2" marginBottom={3}>{t('pages.business.location.additional.title')}</Typography>
            </GridItem>
            {!isStandard && (
              <GridItem sizes={[8]} marginTop={0}>
                <TitleWithBadge
                  title={t('pages.premiumListings.location.additional.additionalDetails')}
                  badge={t('pages.premiumListings.badge')}
                  tooltip={t('pages.premiumListings.badgeTooltip')}
                />
              </GridItem>
            )}
            <GridItem>
              <NeSelectChip name="services" values={serviceValues} label
                labelText={t('pages.business.location.additional.services')}
                maxItems={MAX_ITEMS}
                items={serviceSuggestions}
                freeSolo
                placeholder={t(isStandard
                  ? 'pages.business.location.additional.placeholder'
                  : 'pages.premiumListings.location.additional.placeholder')}
                required={false}
                schema={schema}
                theme={theme}
              />
            </GridItem>
            <GridItem>
              <NeSelectChip name="brands" values={brandValues} label
                labelText={t(isStandard
                  ? 'pages.business.location.additional.brands'
                  : 'pages.premiumListings.location.additional.brands')}
                maxItems={MAX_ITEMS} items={[]}
                freeSolo
                placeholder={t(isStandard
                  ? 'pages.business.location.additional.placeholder'
                  : 'pages.premiumListings.location.additional.placeholder')}
                required={false}
                schema={schema}
                theme={theme}
              />
            </GridItem>
            <GridItem>
              <NeSelectChipCheckboxes name="paymentTypes" values={paymentsValues} label
                labelText={t(isStandard
                  ? 'pages.business.location.additional.paymentTypes'
                  : 'pages.premiumListings.location.additional.paymentTypes')}
                placeholder={t(isStandard
                  ? 'pages.business.location.additional.placeholder'
                  : 'pages.premiumListings.location.additional.placeholder')}
                withCheckboxOptions
                maxItems={MAX_ITEMS} items={dummyPaymentTypes}
                required={false}
                supportCustomChips={false}
                labelTooltipPaddingBottom={18}
              />
            </GridItem>
            <GridItem>
              <NeSelectChipCheckboxes name="languages" values={languagesValues} label
                labelText={t(isStandard
                  ? 'pages.business.location.additional.languages'
                  : 'pages.premiumListings.location.additional.languages')}
                placeholder={t(isStandard
                  ? 'pages.business.location.additional.placeholder'
                  : 'pages.premiumListings.location.additional.placeholder')}
                maxItems={MAX_ITEMS} items={dummyLanguages}
                withCheckboxOptions
                required={false}
                supportCustomChips={false}
                labelTooltipPaddingBottom={18}
              />
            </GridItem>
            {!isStandard && (
              <GridItem marginTop={3}>
                <Typography variant="h6">{t('pages.premiumListings.location.additional.marketDetails')}</Typography>
              </GridItem>
            )}
            <GridItem>
              <NeSelectChip name="demographic" values={demographicsValues} label
                labelText={t(isStandard
                  ? 'pages.business.location.additional.demographic'
                  : 'pages.premiumListings.location.additional.demographic')}
                placeholder={t(isStandard
                  ? 'pages.business.location.additional.placeholder'
                  : 'pages.premiumListings.location.additional.placeholder')}
                labelTooltipText={!isStandard
                  ? t('pages.premiumListings.location.additional.tooltip')
                  : ''}
                maxItems={MAX_ITEMS} items={[]}
                freeSolo
                required={false}
                schema={schema}
                theme={theme}
              />
            </GridItem>
            <GridItem>
              <NeSelectChip name="competitors" values={competitorsValues} label
                labelText={t(isStandard
                  ? 'pages.business.location.additional.competitors'
                  : 'pages.premiumListings.location.additional.competitors')}
                placeholder={t(isStandard
                  ? 'pages.business.location.additional.placeholder'
                  : 'pages.premiumListings.location.additional.placeholder')}
                labelTooltipText={!isStandard
                  ? t('pages.premiumListings.location.additional.tooltip')
                  : ''}
                maxItems={MAX_ITEMS} items={[]}
                freeSolo
                required={false}
                schema={schema}
                theme={theme}
              />
            </GridItem>
            {!isStandard && (
              <FulfillmentCheckbox
                fieldName="fulfilledAdditionalInfo"
                isLocationCompleted={location.completed}
              />
            )}
          </GridContainer>
          )}
        </form>
        <CoNavigationConfirm
          showDialog={isDirty && !(isSubmitSuccessful || isSubmitting) && isRedirectedFromOverviewPage}
        />
      </FormProvider>
    </ValidationProvider>
  );
}
