import React, { useEffect, useMemo, useState } from 'react';
import {
  IQCheckbox,
  ValidationProvider,
} from '@gannettdigital/shared-react-components';
import GridContainer from 'components/grid/GridContainer';
import GridItem from 'components/grid/GridItem';
import { StepperRootProps } from 'layouts/StepperLayout';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { ProductTypes, selectFilesUploaded, deleteFile } from 'services/fileUploaderSlice';
import { WebsiteOfferingTypes } from 'services/websitesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import {
  getBusinessLocation,
  selectPhotos,
  setPhotos,
  updateLocationLogo,
  uploadPhotos,
} from 'services/businessLocationSlice';
import { getOrderBusinessByOrderId, selectOrderBusiness } from 'services/businessSlice';
import { FileUploader } from 'components/fileUploader/FileUploaders';
import CoModalConfirm from 'components/modals/CoModalConfirm';
import { Typography } from '@mui/material';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import schema from './LocationPhotos.schema';
import { fileUploaderOptions } from './fileUploaderOptions';

export const LocationPhotos = (props: StepperRootProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { orderId, locationId } = useParams();
  const fileUploaded = useSelector(selectFilesUploaded);
  const { content: { sameLogoAttachments } } = useSelector(selectOrderBusiness);
  const standardLogo = useSelector(selectPhotos);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [fileToRemove, setFileToRemove] = useState({ fileName: '' });
  const history: any = useLocation();
  const isRedirectedFromOverviewPage = useMemo(() => history.state?.previousPath.includes('overview'), [history]);

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      standardLogo: [],
      applyToAll: false,
    },
  });

  const {
    handleSubmit, getValues, setValue, formState: {
      isValid, isDirty, isSubmitSuccessful, isSubmitting,
    },
  } = methods;

  useEffect(() => {
    if (orderId) dispatch(getOrderBusinessByOrderId(orderId));
  }, [orderId]);

  useEffect(() => {
    if (locationId) dispatch(getBusinessLocation(locationId));
  }, [locationId]);

  useEffect(() => {
    if (sameLogoAttachments) setValue('applyToAll', sameLogoAttachments);
  }, [sameLogoAttachments]);

  useEffect(() => {
    const standardLogo = fileUploaded.content.filter(
      file => file.uploaderType === 'logo_attachments' && file.productId.toString() === locationId,
    );
    dispatch(setPhotos(standardLogo));
  }, [fileUploaded]);

  const onFormSubmit = async () => {
    const data = getValues();
    const logoString = JSON.stringify(fileUploaded.content);
    const resource = {
      logoAttachments: logoString,
      sameLogoAttachments: data.applyToAll,
      sameSquareLogoAttachments: false,
      squareLogoAttachments: null,
    };
    await dispatch(updateLocationLogo({ locationId, resource }));
    props.onContinue();
  };

  useEffect(() => {
    props.onUpdateStatus(getValues(), isValid);
  }, [isValid]);

  const onUploadSuccess = (data) => {
    dispatch(uploadPhotos(data));
  };

  const onFileDelete = (data) => {
    setFileToRemove(data);
    setShowDeleteModal(true);
  };

  const handleCloseRemove = () => {
    setShowDeleteModal(false);
  };

  const handleConfirmRemove = () => {
    dispatch(deleteFile({ websiteId: orderId, file: fileToRemove }));
  };

  return (
    <>
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <form id={props.formId} onSubmit={handleSubmit(onFormSubmit)}>
            <GridContainer fullWidth>
              <GridItem marginBottom={0}>
                <Typography variant="overline" marginBottom={0}
                  sx={{ textTransform: 'initial', fontSize: '18px', color: 'text.secondary' }}
                >
                  Locations
                </Typography>
              </GridItem>
              <GridItem>
                <Typography variant="h2" marginBottom={3}>{t('pages.business.location.photos.title')}</Typography>
              </GridItem>
              <GridItem>
                <FileUploader
                  showFileUploader
                  schema={schema}
                  productId={locationId}
                  fileUploaderOptions={fileUploaderOptions}
                  productType={ProductTypes.PRODUCT_CATALOG}
                  offeringType={WebsiteOfferingTypes.BUSINESS_LOCATIONS}
                  onDelete={onFileDelete}
                  onSuccess={onUploadSuccess}
                  filesUploaded={standardLogo}
                />
              </GridItem>
              <GridItem>
                <IQCheckbox
                  field="applyToAll"
                  label={t('pages.business.location.photos.applyToAll')}
                />
              </GridItem>
            </GridContainer>
          </form>
          <CoNavigationConfirm
            showDialog={isDirty && !(isSubmitSuccessful || isSubmitting) && isRedirectedFromOverviewPage}
          />
        </FormProvider>
      </ValidationProvider>
      <CoModalConfirm
        open={showDeleteModal}
        title={t('pages.premiumListings.location.photos.removeTitle')}
        description={(
          <Trans
            i18nKey="pages.premiumListings.location.photos.removeMessage"
            values={{ name: fileToRemove.fileName }}
            components={{ strong: <strong /> }}
          />
        )}
        cancelText={t('pages.premiumListings.location.photos.removeCancel')}
        confirmText={t('pages.premiumListings.location.photos.removeConfirm')}
        handleClose={handleCloseRemove}
        handleConfirm={handleConfirmRemove}
      />
    </>
  );
};
