import DefaultPageLayout from "layouts/DefaultPageLayout";
import {useTranslation} from "react-i18next";
import {
  FormControlLabel, Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Box} from "@mui/system";
import GridItem from "components/grid/GridItem";
import GridContainer from "components/grid/GridContainer";
import schema from "./XmoTargeting.schema";

import {
  IQFormInput, IQFormSelect, IQFormTextArea,
  IQTheme,
  ValidationProvider
} from "@gannettdigital/shared-react-components";
import {Controller, FormProvider, useForm} from "react-hook-form";
import XmoStepType from "models/XmoStepType";
import schemaValidate from "components/schemaValidate";
import CoNavigationConfirm from "components/navigation/CoNavigationConfirm";
import Autocomplete from "@mui/material/Autocomplete";
import useMapboxService from "services/MapboxService";
import ErrorIcon from "@mui/icons-material/Error";
import {
  targetingTypeDropdownItems,
  targetingTypeRadiusDistanceDropdownItems
} from "./XmoTargetingService";

interface Props {
  product: any;
  onSubmit: (data: any) => void;
  onBack: () => void;
}

export type DetailsFormProps = {
  targetingType: string,
  targetingDetails: string,
  radiusOrKilometerDistance: string,
  salesforceBusinessAddress: string,
  areasToIncludeNotInTargetArea: string,
  areasToExcludeInTargetArea: string,
  addressLine1: string,
  addressLine2: string,
  city: string,
  stateOrProvinceOrRegion: string,
  zipOrPostalCode: string,
  country: string,
};

export default function XmoTargetingPageForm({product, onSubmit, onBack}: Props) {
  const {t} = useTranslation();
  const [targetingType, setTargetingType] = useState<string>(product?.targetingType || '');
  const [salesforceBusinessAddressRadio, setSalesforceBusinessAddressRadio] = useState(product?.salesforceBusinessAddress || '');

  const methods = useForm<DetailsFormProps>({
    mode: 'onChange',
    defaultValues: {
      targetingType: product?.targetingType || '',
      targetingDetails: product?.targetingDetails || '',
      areasToIncludeNotInTargetArea: product?.areasToIncludeNotInTargetArea || '',
      areasToExcludeInTargetArea: product?.areasToExcludeInTargetArea || '',
      radiusOrKilometerDistance: product?.radiusOrKilometerDistance || '',
      salesforceBusinessAddress: product?.salesforceBusinessAddress || '',
      addressLine1: product?.addressLine1 || '',
      addressLine2: product?.addressLine2 || '',
      city: product?.city || '',
      stateOrProvinceOrRegion: product?.stateOrProvinceOrRegion || '',
      zipOrPostalCode: product?.zipOrPostalCode || '',
      country: product?.country || '',
    },
  });

  const handleFormSubmit = (data: DetailsFormProps) => {
    if (!['Radius', 'City', 'DMA', 'Multi-Radius', 'Zip/Postal Code', 'State'].includes(targetingType)) {
      data.targetingDetails = '';
    }
    if (targetingType !== 'Radius') {
      data.salesforceBusinessAddress = '';
      data.addressLine1 = '';
      data.addressLine2 = '';
      data.city = '';
      data.stateOrProvinceOrRegion = '';
      data.zipOrPostalCode = '';
      data.country = '';
    }
    if (targetingType === 'Radius' && salesforceBusinessAddressRadio === 'Yes') {
      data.addressLine1 = '';
      data.addressLine2 = '';
      data.city = '';
      data.stateOrProvinceOrRegion = '';
      data.zipOrPostalCode = '';
      data.country = '';
    }

    const pageFormData = {
      solutionStrategy: {
        ...product, ...data
      }
    };
    onSubmit(pageFormData);
  }

  const {
    setValue, getValues, watch, control, handleSubmit, unregister, register,
    formState: {
      isValid,
      isDirty,
      isSubmitSuccessful,
      isSubmitting,
    },
  } = methods;

  const watchedValues = watch(["addressLine1", "city", "stateOrProvinceOrRegion", "zipOrPostalCode", "country"]);

  const [inputValue, setInputValue] = useState('');
  const [isTouched, setIsTouched] = useState(false);
  const [addressData, setAddressData] = useState([]);
  const [selectedValue, setSelectedValue] = useState<any>(product?.addressLine1 ? {
    label: product?.addressLine1,
    value: ''
  } : null);
  const [placeDetails, setPlaceDetails] = useState<any>(null);
  const [isAddressFieldsEmpty, setIsAddressFieldsEmpty] = useState<any>(false);

  const {getServiceAreas, getPlaceDetails} = useMapboxService();

  const getSuggestions = async (value: any) => {
    const data = await getServiceAreas(value, 'US');
    setAddressData(data);
  };

  useEffect(() => {
    const getPlaceDetailsApiCall = async (placeId: any) => {
      const data = await getPlaceDetails(placeId)
      setPlaceDetails(data.data)
    }
    if (selectedValue && selectedValue.value !== '') {
      getPlaceDetailsApiCall(selectedValue.value);
    }
  }, [selectedValue]);

  useEffect(() => {
    if (placeDetails) {
      setValue('addressLine2', placeDetails.addressLine2 || '');
      setValue('city', placeDetails.city || '');
      setValue('stateOrProvinceOrRegion', placeDetails.state || '');
      setValue('zipOrPostalCode', placeDetails.zipCode || '');
      setValue('country', placeDetails.country === 'US' ? 'United States' : placeDetails.country || '');
    }
  }, [placeDetails]);

  useEffect(() => {
    const formValues = getValues();
    if (salesforceBusinessAddressRadio === 'No' && watchedValues.includes('')) {
      setIsAddressFieldsEmpty(true);
    } else {
      setIsAddressFieldsEmpty(false);
    }
  }, [watchedValues]);

  return (
      <DefaultPageLayout
          disableContinue={!isValid || isAddressFieldsEmpty}
          onBack={onBack}
          header={t('pages.xmo.solutionsStrategy.targeting.targetingTitle')}
          onContinue={handleSubmit(handleFormSubmit)}>
        <ValidationProvider schema={schema}>
          <FormProvider {...methods}>
            <Box
                id="mainForm"
                component="form"
                sx={{display: 'flex'}}
                onSubmit={() => {
                  handleSubmit(handleFormSubmit)
                }}
            >
              <GridContainer fullWidth>
                <GridItem sizes={[12]}>
                  <Typography
                      variant="h6">{t('pages.xmo.solutionsStrategy.targeting.typeTitle')}</Typography>
                </GridItem>
                <GridItem sizes={[12]}>
                  <IQFormSelect
                      id="targetingType"
                      name="targetingType"
                      labelText={t('pages.xmo.solutionsStrategy.targeting.targetingType')}
                      items={targetingTypeDropdownItems}
                      defaultValue=""
                      theme={IQTheme}
                      fontLabelWeight="600"
                      required
                      fullWidth
                      showError
                      onChange={(e: any) => setTargetingType(e.target.value)}
                      schema={schema}
                  />
                </GridItem>
                {
                    ['Radius', 'City', 'DMA', 'Multi-Radius', 'Zip/Postal Code', 'State'].includes(targetingType) &&
                    <GridItem sizes={[12]}>
                      <IQFormInput
                          schema={schema}
                          theme={IQTheme}
                          fontLabelWeight="600"
                          id="targetingDetails"
                          name="targetingDetails"
                          fullWidth
                          showError
                          labelText={t('pages.xmo.solutionsStrategy.targeting.targetingDetails')}
                      />
                    </GridItem>
                }
                {
                    targetingType === 'Radius' &&
                    <>
                      <GridItem sizes={[12]}>
                        <IQFormSelect
                            id="radiusOrKilometerDistance"
                            name="radiusOrKilometerDistance"
                            labelText={t('pages.xmo.solutionsStrategy.targeting.radiusOrKilometerDistance')}
                            items={targetingTypeRadiusDistanceDropdownItems()}
                            defaultValue=""
                            theme={IQTheme}
                            fontLabelWeight="600"
                            required
                            fullWidth
                            showError
                            schema={schema}
                        />
                      </GridItem>
                      <GridItem sizes={[12]}>
                        <Typography
                            fontWeight={600}
                            variant="subtitle1">{t('pages.xmo.solutionsStrategy.targeting.salesforceBusinessAddress')}
                          <RadioGroup
                              id='salesforceBusinessAddress'
                              name='salesforceBusinessAddress'
                              defaultValue={watch('salesforceBusinessAddress') || ''}
                              onChange={(e: any) => setSalesforceBusinessAddressRadio(e.target.value)}
                              row
                          >
                            {[t('pages.xmo.solutionsStrategy.inputValues.yesInputValue'),
                              t('pages.xmo.solutionsStrategy.inputValues.noInputValue')].map(opt => (
                                <FormControlLabel
                                    key={opt}
                                    control={<Radio value={opt}/>}
                                    label={opt}
                                    {...register('salesforceBusinessAddress', {
                                      validate: (value) => schemaValidate(value, 'salesforceBusinessAddress', schema),
                                    })}
                                />
                            ))}
                          </RadioGroup>
                        </Typography>
                      </GridItem>
                      {
                          salesforceBusinessAddressRadio === t('pages.xmo.solutionsStrategy.inputValues.noInputValue') &&
                          <>
                            <GridItem sizes={[12]}>
                              <Typography
                                  variant="h6">{t('pages.xmo.solutionsStrategy.targeting.newBusinessAddressTitle')}</Typography>
                            </GridItem>
                            <GridItem sizes={[12]}>
                              <Typography
                                  fontWeight={600}
                                  variant="subtitle1"
                                  mb={-1}
                              >
                                {t('pages.xmo.solutionsStrategy.targeting.addressLine1')}
                              </Typography>
                            </GridItem>
                            <GridItem sizes={[12]}>
                              <Controller
                                  name="addressLine1"
                                  control={control}
                                  render={({field: {onChange, value}}) => (
                                      <Autocomplete
                                          value={selectedValue}
                                          disablePortal
                                          id="addressLine1"
                                          options={addressData}
                                          getOptionLabel={(option) => option.label || ""}
                                          sx={{width: '100%'}}
                                          inputValue={inputValue}
                                          onInputChange={(event, newInputValue) => {
                                            setInputValue(newInputValue);
                                            setIsTouched(true);
                                            getSuggestions(newInputValue);
                                          }}
                                          onChange={(event, newValue) => {
                                            setInputValue(newValue ? newValue.label : "");
                                            onChange(newValue ? newValue.label : "");
                                            setSelectedValue(newValue);
                                          }}
                                          renderInput={(params) => <TextField {...params} />}
                                      />
                                  )}
                              />
                            </GridItem>
                            <GridItem sizes={[12]}>
                              <IQFormInput
                                  schema={schema}
                                  theme={IQTheme}
                                  fontLabelWeight="600"
                                  id="addressLine2"
                                  name="addressLine2"
                                  fullWidth
                                  showError
                                  labelText={t('pages.xmo.solutionsStrategy.targeting.addressLine2')}
                              />
                            </GridItem>
                            <GridItem sizes={[6, 6]} columnSpacing={2}>
                              <IQFormInput
                                  schema={schema}
                                  theme={IQTheme}
                                  fontLabelWeight="600"
                                  id="city"
                                  name="city"
                                  fullWidth
                                  showError
                                  labelText={t('pages.xmo.solutionsStrategy.targeting.city')}
                              />
                              <IQFormInput
                                  schema={schema}
                                  theme={IQTheme}
                                  fontLabelWeight="600"
                                  id="stateOrProvinceOrRegion"
                                  name="stateOrProvinceOrRegion"
                                  fullWidth
                                  showError
                                  labelText={t('pages.xmo.solutionsStrategy.targeting.stateOrProvinceOrRegion')}
                              />
                            </GridItem>
                            <GridItem sizes={[6, 6]} columnSpacing={2}>
                              <IQFormInput
                                  schema={schema}
                                  theme={IQTheme}
                                  fontLabelWeight="600"
                                  id="zipOrPostalCode"
                                  name="zipOrPostalCode"
                                  fullWidth
                                  showError
                                  labelText={t('pages.xmo.solutionsStrategy.targeting.zipOrPostalCode')}
                              />
                              <IQFormInput
                                  schema={schema}
                                  theme={IQTheme}
                                  fontLabelWeight="600"
                                  id="country"
                                  name="country"
                                  fullWidth
                                  showError
                                  labelText={t('pages.xmo.solutionsStrategy.targeting.country')}
                              />
                            </GridItem>
                            {isAddressFieldsEmpty &&
                                <Grid container>
                                  <Grid item xs={12}>
                                    <Typography
                                        sx={{
                                          color: '#C20F1E',
                                          fontSize: '14px',
                                          marginTop: '-10px',
                                          marginBottom: '15px',
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                        variant="subtitle1"
                                    >
                                      <Box sx={{display: 'flex', alignItems: 'center'}}>
                                        <ErrorIcon
                                            sx={{
                                              color: '#C20F1E',
                                              fontSize: '16px',
                                              marginRight: '5px'
                                            }}
                                        />
                                        {t('pages.xmo.solutionsStrategy.targeting.genericAddressErrorMessage')}
                                      </Box>
                                    </Typography>
                                  </Grid>
                                </Grid>
                            }
                          </>
                      }
                    </>

                }
                <GridItem sizes={[12]}>
                  <Typography
                      variant="h6">{t('pages.xmo.solutionsStrategy.targeting.areasTitle')}</Typography>
                </GridItem>
                <GridItem sizes={[12]}>
                  <IQFormTextArea
                      fontLabelWeight="600"
                      id="areasToIncludeNotInTargetArea"
                      name="areasToIncludeNotInTargetArea"
                      fullWidth
                      showError
                      labelText={t('pages.xmo.solutionsStrategy.targeting.areasToIncludeNotInTargetArea')}
                  />
                </GridItem>
                <GridItem sizes={[12]}>
                  <IQFormTextArea
                      fontLabelWeight="600"
                      id="areasToExcludeInTargetArea"
                      name="areasToExcludeInTargetArea"
                      fullWidth
                      showError
                      labelText={t('pages.xmo.solutionsStrategy.targeting.areasToExcludeInTargetArea')}
                  />
                </GridItem>
              </GridContainer>
            </Box>
            <CoNavigationConfirm
                showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
            />
          </FormProvider>
        </ValidationProvider>
      </DefaultPageLayout>
  );
}