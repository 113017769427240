/* eslint-disable import/no-extraneous-dependencies */
import { RJSFSchema } from '@rjsf/utils';
import i18n from 'i18n/i18n';
import AddWebEvents from "pages/generic-product/details/custom-fields/AddWebEvents";

const { t } = i18n;

export const ProxyOrProvisioningSchema: RJSFSchema | any = (data) => ({
  title: t('pages.socialAds.solutionStrategy.proxyProvisioning.title'),
  required: ['primaryNumberToBeProvisioned', 'utmImplementString',
    'doesWebsiteHaveCaptchaInstalled', 'doesClientHaveAnotherAccount'],
  type: 'object',
  properties: {
    clientHaveALocaliqWebsite:{
      type: 'string',
      title: t('pages.sem-details.localiqWebsiteTitle'),
    },
    localiqWebsite: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.websites.localiqWebsiteTitle'),
      default: data?.localiqWebsite || '',
      enum: [
        '',
        t('Yes'),
        t('No'),
      ],
    },
    localAreaCodePreference1: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.proxyProvisioning.localAreaCodePreference1'),
      default: data?.localAreaCodePreference1 || '',
    },
    localAreaCodePreference2: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.proxyProvisioning.localAreaCodePreference2'),
      default: data?.localAreaCodePreference2 || '',
    },
    primaryNumberToBeProvisioned: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.proxyProvisioning.primaryNumberToBeProvisioned'),
      default: data?.primaryNumberToBeProvisioned || '',
    },
    doesPrimaryBusinessNumberNeedToForward: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.proxyProvisioning.doesPrimaryBusinessNumberNeedToForward'),
      default: data?.doesPrimaryBusinessNumberNeedToForward || '',
      enum: [
        '',
        t('pages.socialAds.solutionStrategy.proxyProvisioning.doesPrimaryBusinessNumberNeedOptions.yes'),
        t('pages.socialAds.solutionStrategy.proxyProvisioning.doesPrimaryBusinessNumberNeedOptions.no'),
      ],
    },
    utmDetailsHeading: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.proxyProvisioning.utmDetailsHeading'),
    },
    utmImplementString: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.proxyProvisioning.utmImplementString'),
      description: t('pages.socialAds.solutionStrategy.proxyProvisioning.utmImplementStringHelperText'),
      default: data?.utmImplementString || '',
      enum: [
        t('pages.socialAds.solutionStrategy.proxyProvisioning.utmImplementStringOptions.yesUseUtmStringOnly'),
        t('pages.socialAds.solutionStrategy.proxyProvisioning.utmImplementStringOptions.yesUseUtmStringBestPractices'),
        t('pages.socialAds.solutionStrategy.proxyProvisioning.utmImplementStringOptions.noDoNotUseUtmString'),
      ],
    },
    doesWebsiteHaveCaptchaInstalled: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.proxyProvisioning.doesWebsiteHaveCaptchaInstalled'),
      default: data?.doesWebsiteHaveCaptchaInstalled || '',
      enum: [
        t('pages.socialAds.solutionStrategy.proxyProvisioning.doesWebsiteHaveCaptchaInstalledOptions.yes'),
        t('pages.socialAds.solutionStrategy.proxyProvisioning.doesWebsiteHaveCaptchaInstalledOptions.no'),
      ],
    },
    doesClientHaveAnotherAccount: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.proxyProvisioning.doesClientHaveAnotherAccount'),
      default: data?.doesClientHaveAnotherAccount || '',
      enum: [
        t('pages.socialAds.solutionStrategy.proxyProvisioning.doesThisClientHaveAnotherAccount(MAID)Options.yes'),
        t('pages.socialAds.solutionStrategy.proxyProvisioning.doesThisClientHaveAnotherAccount(MAID)Options.no'),
      ],
    },
    doNotProxyWebsite: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.proxyProvisioning.doNotProxyWebsite'),
      default: data?.doNotProxyWebsite || '',
    },
    webEvents: {
      type: 'string',
      title: t('Web Events'),
      default: '',
      enum: [
        '',
        t('pages.socialAds.solutionStrategy.proxyProvisioning.webEventsOptions.adjustWebEvents'),
        t('pages.socialAds.solutionStrategy.proxyProvisioning.webEventsOptions.useMyWebEventsOnly'),
      ],
    },
  },

  allOf:[
    {
      if: {
        properties: {
          captureCodeCompatible: {
            enum: [
              'Yes',
            ]
          },
          isClientPartOfAFranchise: {
            enum: [
              '',
            ]
          },
        },
        required: [
          'captureCodeCompatible',
          'isClientPartOfAFranchise',
        ]
      },
      then: {
        properties: {
          reachCodeCompatibilityReportLink: {
            type: 'string',
            title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.Link1Description'),
            enum: [
              'https://example.com/',
            ],
          },
          howToUseTheCapComToolIsAvailableHereLink: {
            type: 'string',
            title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.Link2Description'),
            enum: [
              'https://thesource.reachlocal.com/resources/capcom-live-how-guide',
            ],
          },
          websiteTracking: {
            type: 'string',
            title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.websiteTrackingLabel'),
            default: data?.websiteTracking || '',
            enum: [
              '',
              t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.baseWebsiteDropDown1'),
              t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.baseWebsiteDropDown2'),
              t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.baseWebsiteDropDown4'),
            ],
          },
        },
        dependencies: {
          websiteTracking: {
            oneOf: [
              {
                properties: {
                  websiteTracking: {
                    enum: ['CaptureCode on Client\'s Site',
                      'Client Center with Lead Engagement Add-On (Edge',
                    ],
                  },
                  canWeStartYourCampaignOn3pp: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppLabel'),
                    description: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppHelper'),
                    default: data?.canWeStartYourCampaignOn3pp || '',
                    enum: [
                      '',
                      t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDropDownOpt1'),
                      t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDropDownOpt2'),
                    ],
                  },
                  canWeStartYourCampaignOn3ppDescription1Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription1')
                  },
                  canWeStartYourCampaignOn3ppDescription2Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription2')
                  },
                  canWeStartYourCampaignOn3ppDescription3Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription3')
                  },
                  canWeStartYourCampaignOn3ppDescription4Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription4')
                  },
                  canWeStartYourCampaignOn3ppDescription5Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription5')
                  },
                  canWeStartYourCampaignOn3ppDescription6Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription6')
                  },
                  canWeStartYourCampaignOn3ppDescription7Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription7')
                  },
                  canWeStartYourCampaignOn3ppDescription8Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription8')
                  },
                  canWeStartYourCampaignOn3ppDescription9Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription9')
                  },
                },
              },
            ],
          },
        }
      }
    },
    {
      if: {
        properties: {
          captureCodeCompatible: {
            enum: [
              'Yes',
            ]
          },
          isClientPartOfAFranchise: {
            enum: [
              'Yes',
            ]
          },
        },
        required: [
          'captureCodeCompatible',
          'isClientPartOfAFranchise',
        ]
      },
      then: {
        properties: {
          reachCodeCompatibilityReportLink: {
            type: 'string',
            title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.Link1Description'),
            enum: [
              'https://example.com/',
            ],
          },
          howToUseTheCapComToolIsAvailableHereLink: {
            type: 'string',
            title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.Link2Description'),
            enum: [
              'https://thesource.reachlocal.com/resources/capcom-live-how-guide',
            ],
          },
          websiteTracking: {
            type: 'string',
            title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.websiteTrackingLabel'),
            default: data?.websiteTracking || '',
            enum: [
              '',
              t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.baseWebsiteDropDown1'),
              t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.baseWebsiteDropDown2'),
              t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.baseWebsiteDropDown4'),
            ],
          },
        },
        dependencies: {
          websiteTracking: {
            oneOf: [
              {
                properties: {
                  websiteTracking: {
                    enum: ['CaptureCode on Client\'s Site',
                      'Client Center with Lead Engagement Add-On (Edge',
                    ],
                  },
                  canWeStartYourCampaignOn3ppForFranchiseYes: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppLabel'),
                    description: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppHelper'),
                    default: 'No - Hold Campaign until CaptureCode is Implemented',
                    enum: [
                      '',
                      t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDropDownOpt2'),
                    ],
                  },
                  canWeStartYourCampaignOn3ppDescription1Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription1')
                  },
                  canWeStartYourCampaignOn3ppDescription2Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription2')
                  },
                  canWeStartYourCampaignOn3ppDescription3Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription3')
                  },
                  canWeStartYourCampaignOn3ppDescription4Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription4')
                  },
                  canWeStartYourCampaignOn3ppDescription5Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription5')
                  },
                  canWeStartYourCampaignOn3ppDescription6Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription6')
                  },
                  canWeStartYourCampaignOn3ppDescription7Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription7')
                  },
                  canWeStartYourCampaignOn3ppDescription8Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription8')
                  },
                  canWeStartYourCampaignOn3ppDescription9Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription9')
                  },
                },
              },
            ],
          },
        }
      }
    },
    {
      if: {
        properties: {
          captureCodeCompatible: {
            enum: [
              'Yes',
            ]
          },
          isClientPartOfAFranchise: {
            enum: [
              'No',
            ]
          },
        },
        required: [
          'captureCodeCompatible',
          'isClientPartOfAFranchise',
        ]
      },
      then: {
        properties: {
          reachCodeCompatibilityReportLink: {
            type: 'string',
            title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.Link1Description'),
            enum: [
              'https://example.com/',
            ],
          },
          howToUseTheCapComToolIsAvailableHereLink: {
            type: 'string',
            title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.Link2Description'),
            enum: [
              'https://thesource.reachlocal.com/resources/capcom-live-how-guide',
            ],
          },
          websiteTracking: {
            type: 'string',
            title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.websiteTrackingLabel'),
            default: data?.websiteTracking || '',
            enum: [
              '',
              t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.baseWebsiteDropDown1'),
              t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.baseWebsiteDropDown3'),
              t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.baseWebsiteDropDown2'),
              t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.baseWebsiteDropDown4'),
            ],
          },
        },
        dependencies: {
          websiteTracking: {
            oneOf: [
              {
                properties: {
                  websiteTracking: {
                    enum: [
                      'CaptureCode on Client\'s Site',
                      'CaptureCode + Third-Party Proxy (3PP)',
                      'Client Center with Lead Engagement Add-On (Edge',
                    ],
                  },
                  canWeStartYourCampaignOn3pp: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppLabel'),
                    description: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppHelper'),
                    default: data?.canWeStartYourCampaignOn3pp || '',
                    enum: [
                      '',
                      t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDropDownOpt1'),
                      t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDropDownOpt2'),
                    ],
                  },
                  canWeStartYourCampaignOn3ppDescription1Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription1')
                  },
                  canWeStartYourCampaignOn3ppDescription2Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription2')
                  },
                  canWeStartYourCampaignOn3ppDescription3Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription3')
                  },
                  canWeStartYourCampaignOn3ppDescription4Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription4')
                  },
                  canWeStartYourCampaignOn3ppDescription5Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription5')
                  },
                  canWeStartYourCampaignOn3ppDescription6Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription6')
                  },
                  canWeStartYourCampaignOn3ppDescription7Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription7')
                  },
                  canWeStartYourCampaignOn3ppDescription8Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription8')
                  },
                  canWeStartYourCampaignOn3ppDescription9Label: {
                    type: 'string',
                    title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.canWeStartYourCampaignOn3ppDescription9')
                  },
                },
              },
            ],
          },
        }
      }
    },
    {
      if: {
        properties: {
          captureCodeCompatible: {
            enum: [
              'No',
            ]
          },
          isClientPartOfAFranchise: {
            enum: [
              '',
              'No',
            ]
          },
        },
        required: [
          'captureCodeCompatible',
          'isClientPartOfAFranchise',
        ]
      },
      then: {
        properties: {
          websiteTracking: {
            type: 'string',
            title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.websiteTrackingLabel'),
            default: data?.websiteTracking || '',
            enum: [
              '',
              t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.websiteTracking1stDropDown'),
              t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.websiteTracking2stDropDown'),
            ],
          },
        },
      }
    },
    {
      if: {
        properties: {
          captureCodeCompatible: {
            enum: [
              'No',
            ]
          },
          isClientPartOfAFranchise: {
            enum: [
              'Yes',
            ]
          },
        },
        required: [
          'captureCodeCompatible',
          'isClientPartOfAFranchise',
        ]
      },
      then: {
        properties: {
          websiteTrackingForFranchiseYes: {
            type: 'string',
            title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.websiteTrackingLabel'),
            default: 'No Tracking',
            enum: [
              '',
              t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.websiteTracking2stDropDown'),
            ],
          },
        },
      }
    },
  ],

  dependencies: {
    localiqWebsite: {
      oneOf: [
        {
          properties: {
            localiqWebsite: {
              enum: ['No'],
            },
            captureCodeCompatible: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.captureCodeCompatibleLabel'),
              default: data?.captureCodeCompatible || '',
              enum: [
                '',
                t('pages.socialAds.solutionStrategy.proxyProvisioning.doesWebsiteHaveCaptchaInstalledOptions.yes'),
                t('pages.socialAds.solutionStrategy.proxyProvisioning.doesWebsiteHaveCaptchaInstalledOptions.no'),
              ],
            },
            isClientPartOfAFranchise: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.isClientPartOfAFranchiseLabel'),
              default: data?.isClientPartOfAFranchise || '',
              enum: [
                '',
                t('pages.socialAds.solutionStrategy.proxyProvisioning.doesWebsiteHaveCaptchaInstalledOptions.yes'),
                t('pages.socialAds.solutionStrategy.proxyProvisioning.doesWebsiteHaveCaptchaInstalledOptions.no'),
              ],
            },
          },
        },
      ],
    },
    clientHaveALocaliqWebsite: {
      oneOf: [
        {
          properties: {
            clientHaveALocaliqWebsite: {
              enum: ['no'],
            },
            captureCodeCompatible: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.captureCodeCompatibleLabel'),
              default: data?.captureCodeCompatible || '',
              enum: [
                '',
                t('pages.socialAds.solutionStrategy.proxyProvisioning.doesWebsiteHaveCaptchaInstalledOptions.yes'),
                t('pages.socialAds.solutionStrategy.proxyProvisioning.doesWebsiteHaveCaptchaInstalledOptions.no'),
              ],
            },
            isClientPartOfAFranchise: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captureCode.isClientPartOfAFranchiseLabel'),
              default: data?.isClientPartOfAFranchise || '',
              enum: [
                '',
                t('pages.socialAds.solutionStrategy.proxyProvisioning.doesWebsiteHaveCaptchaInstalledOptions.yes'),
                t('pages.socialAds.solutionStrategy.proxyProvisioning.doesWebsiteHaveCaptchaInstalledOptions.no'),
              ],
            },
          },
        },
      ],
    },
    doesPrimaryBusinessNumberNeedToForward: {
      oneOf: [
        {
          properties: {
            doesPrimaryBusinessNumberNeedToForward: {
              enum: ['Yes'],
            },
            alternativeForwardingNumber: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.proxyProvisioning.alternativeForwardingNumber'),
              default: data?.alternativeForwardingNumber || '',
            },
          },
        },
      ],
    },
    utmImplementString: {
      oneOf: [
        {
          properties: {
            utmImplementString: {
              enum: ['Yes - Use my UTM strings only'],
            },
            useThisUtm: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.proxyProvisioning.useThisUtm'),
              description: t('pages.socialAds.solutionStrategy.proxyProvisioning.helperTextForUseThisUtm'),
              default: data?.useThisUtm || '',
            },
          },
        },
        {
          properties: {
            utmImplementString: {
              enum: ['Yes - Use UTM strings Best Practices', 'No - Do NOT use UTM strings'],
            },
          },
        },
      ],
    },
    doesWebsiteHaveCaptchaInstalled: {
      oneOf: [
        {
          properties: {
            doesWebsiteHaveCaptchaInstalled: {
              enum: ['Yes'],
            },
            captchaPiece: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.proxyProvisioning.captchaPiece'),
              default: data?.captchaPiece || '',
              enum: [
                '',
                t('pages.socialAds.solutionStrategy.proxyProvisioning.provisioningProceedWithBreakingTrackingOptions.yes'),
                t('pages.socialAds.solutionStrategy.proxyProvisioning.provisioningProceedWithBreakingTrackingOptions.no'),
              ],
            },
          },
        },
        {
          properties: {
            doesWebsiteHaveCaptchaInstalled: {
              enum: ['No'],
            },
          },
        },
      ],
    },
    webEvents: {
      oneOf: [
        {
          properties: {
            webEvents: {
              enum: ['Use only my web events'],
            },
            webEventItems: {
              type: 'string',
              items: {
                type: 'object',
              },
              title: 'Web Events',
              default: data?.webEventItems || '',
            },
          },
        },
        {
          properties: {
            webEvents: {
              enum: ['Please adjust web events per best practise'],
            },
          },
        },
      ],
    },
  },
});
const dropDownKeys = [
  'doesPrimaryBusinessNumberNeedToForward',
  'utmImplementString',
  'doesWebsiteHaveCaptchaInstalled',
  'captchaPiece',
  'doesClientHaveAnotherAccount',
  'webEvents',
  'captureCodeCompatible',
  'isClientPartOfAFranchise',
  'websiteTracking',
  'websiteTrackingForFranchiseYes',
  'websiteTrackingForCaptureCodeCompatibleNo',
  'canWeStartYourCampaignOn3pp',
  'canWeStartYourCampaignOn3ppForFranchiseYes',
];

const dropDownFields = dropDownKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'dropdown' },
}), {});

const headingTextLabelFieldKeys = [
  'utmDetailsHeading',
];
const headingLabelFields = headingTextLabelFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'headingTextLabel' },
}), {});

const inputFieldKeys = [
  'localAreaCodePreference1',
  'localAreaCodePreference2',
  'useThisUtm',
];
const inputFields = inputFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'textInputField' },
}), {});
const textAreaFieldKeys = [
  'doNotProxyWebsite',
];
const textAreaFields = textAreaFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'TextareaWidget' },
}), {});
const phoneNumberFieldsKeys = [
  'primaryNumberToBeProvisioned',
  'alternativeForwardingNumber',
];
const phoneNumberFields = phoneNumberFieldsKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'phoneNumberTextField' },
}), {});
const textLabelFieldsKeys = [
  'canWeStartYourCampaignOn3ppDescription1Label',
  'canWeStartYourCampaignOn3ppDescription3Label',
  'canWeStartYourCampaignOn3ppDescription4Label',
  'canWeStartYourCampaignOn3ppDescription6Label',
  'canWeStartYourCampaignOn3ppDescription7Label',
  'canWeStartYourCampaignOn3ppDescription9Label',
];
const textLabel = textLabelFieldsKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'textLabel' },
}), {});
export const ProxyOrProvisioningUISchema = (data) => ({
  'ui:order':
      [
        'clientHaveALocaliqWebsite', 'localiqWebsite',
        'localAreaCodePreference1', 'localAreaCodePreference2', 'primaryNumberToBeProvisioned',
        'doesPrimaryBusinessNumberNeedToForward', 'alternativeForwardingNumber', 'utmDetailsHeading', 'utmImplementString', 'useThisUtm',
        'captureCodeCompatible', 'isClientPartOfAFranchise', 'reachCodeCompatibilityReportLink', 'howToUseTheCapComToolIsAvailableHereLink', 'websiteTracking',
        'websiteTrackingForFranchiseYes', 'canWeStartYourCampaignOn3pp', 'canWeStartYourCampaignOn3ppForFranchiseYes', 'canWeStartYourCampaignOn3ppDescription1Label',
        'canWeStartYourCampaignOn3ppDescription2Label', 'canWeStartYourCampaignOn3ppDescription3Label', 'canWeStartYourCampaignOn3ppDescription4Label',
        'canWeStartYourCampaignOn3ppDescription5Label', 'canWeStartYourCampaignOn3ppDescription6Label', 'canWeStartYourCampaignOn3ppDescription7Label',
        'canWeStartYourCampaignOn3ppDescription8Label', 'canWeStartYourCampaignOn3ppDescription9Label',
        'doesWebsiteHaveCaptchaInstalled', 'captchaPiece', 'doesClientHaveAnotherAccount', 'doNotProxyWebsite', 'helperText', 'webEvents', 'webEventItems', 'webEventUrl', 'webEventName', 'value',
      ],

  canWeStartYourCampaignOn3ppDescription2Label:{
    'ui:widget': 'textLabel',
    'ui:style': {
      marginBottom: '-11px',
    },
  },
  canWeStartYourCampaignOn3ppDescription5Label:{
    'ui:widget': 'textLabel',
    'ui:style': {
      marginBottom: '-11px',
    },
  },
  canWeStartYourCampaignOn3ppDescription8Label:{
    'ui:widget': 'textLabel',
    'ui:style': {
      marginBottom: '-11px',
    },
  },
  localiqWebsite: {
    'ui:widget': 'hidden',
  },
  clientHaveALocaliqWebsite: {
    'ui:widget': 'hidden',
  },
  webEventItems: {
    'ui:widget': (props) => <AddWebEvents {...props} data={data} />,
  },
  reachCodeCompatibilityReportLink: {
    'ui:widget': 'anchorTextLabel',
  },
  howToUseTheCapComToolIsAvailableHereLink: {
    'ui:widget': 'anchorTextLabel',
  },
  helperText: { 'ui:widget': 'textLabel' },
  ...dropDownFields,
  ...headingLabelFields,
  ...inputFields,
  ...textAreaFields,
  ...phoneNumberFields,
  ...textLabel,
});

export const ProxyOrProvisioningCustomValidate = (formData: any, errors: any, uiSchema: any) => {
  if (formData?.primaryNumberToBeProvisioned === '') {
    errors.primaryNumberToBeProvisioned.addError(t('pages.socialAds.solutionStrategy.proxyProvisioning.primaryNumberToBeProvisionedErrorMsg'));
    errors.addError(true);
  }
  if (formData?.utmImplementString === '') {
    errors.utmImplementString.addError(t('pages.socialAds.solutionStrategy.proxyProvisioning.inputError.implementUtmString'));
    errors.addError(true);
  }
  if (formData?.doesWebsiteHaveCaptchaInstalled === '') {
    errors.doesWebsiteHaveCaptchaInstalled.addError(t('pages.socialAds.solutionStrategy.proxyProvisioning.inputError.captchaInstalled'));
    errors.addError(true);
  }
  if (formData?.doesClientHaveAnotherAccount === '') {
    errors.doesClientHaveAnotherAccount.addError(t('pages.socialAds.solutionStrategy.proxyProvisioning.inputError.anotherAccountMAIDError'));
    errors.addError(true);
  }
  return errors;
};
