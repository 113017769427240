import {
  Button, Card, CardActions, CardContent, Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { styled } from '@mui/material/styles';
import SitePagesType from 'models/SitePagesType';
import { H5 } from '../labels/H5';
import { Text } from '../labels/Text';

export interface PageCardType {
  onEdit?: (arg?: unknown) => unknown,
  onDelete?: (arg?: unknown) => unknown,
  isFirst: boolean
}

export const CoPageCardButton = styled(Button)`
  &.Mui-disabled {
    color: #FFFFFF
  }
  font-weight: 400;
  text-transform: none;
  font-size: 14px;
  line-height: 18px;
  padding: 0 30px 0 0;
  min-width: 0;
  font-family: 'Unify Sans';
  
  svg.MuiSvgIcon-root {
    font-size: 12px;
  }
`;

export const CoPageCardHeadingStyles = {
  fontWeight: '600',
  opacity: '0.8',
  marginRight: '16px',
};

const styles = makeStyles(
  {
    card: {
      marginTop: '24px',
    },
    cardFirst: {
      marginTop: '2px',
    },
    innerGrid: {
      marginTop: '9px',
    },
    button: {
      textTransform: 'none',
      padding: '0 20px 0 0',
      minWidth: 0,
    },
    cardContent: {
      paddingTop: '24px',
      paddingRight: '24px',
      paddingLeft: '24px',
      paddingBottom: '3px',
    },
    cardActions: {
      paddingBottom: '24px',
      paddingRight: '24px',
      paddingLeft: '24px',
    },
    boxStyles: {
      minWidth: 275,
      border: '1px solid #D7D7D7',
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
      borderRadius: '2px',
    },
  },
);

export default function PageCard(props: PageCardType & SitePagesType) {
  const { t } = useTranslation();

  const style = styles();

  return (
    <Card className={`${props.isFirst ? style.cardFirst : style.card} ${style.boxStyles}`} variant="outlined">
      <CardContent className={style.cardContent}>
        <Grid container>
          <Grid item xs={12}>
            <H5>
              {props.pageName}
            </H5>
          </Grid>
          <Grid item xs={3.5} className={style.innerGrid}>
            <Text style={{ ...CoPageCardHeadingStyles }}>
              {t('components.pageCard.labels.targetMessaging')}
              :
            </Text>
          </Grid>
          <Grid item xs={8.5} className={style.innerGrid}>
            <Text>
              {props.targetMessaging}
            </Text>
          </Grid>
          <Grid item xs={3.5} className={style.innerGrid}>
            <Text style={{ ...CoPageCardHeadingStyles }}>
              {t('components.pageCard.labels.otherNotes')}
              :
            </Text>
          </Grid>
          <Grid item xs={8.5} className={style.innerGrid}>
            <Text>
              {props.otherNotes}
            </Text>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions className={style.cardActions}>
        <CoPageCardButton name="edit" endIcon={<ArrowForwardIosIcon />}
          onClick={props.onEdit}
        >
          {t('components.pageCard.buttons.edit')}
        </CoPageCardButton>
        <CoPageCardButton name="delete"
          onClick={props.onDelete}
        >
          {t('components.pageCard.buttons.remove')}
        </CoPageCardButton>
      </CardActions>
    </Card>
  );
}
