import ContentLoader from 'components/contentLoader/ContentLoader';
import useProduct from 'hooks/useProduct';
import {Urls} from 'navigation/Urls';
import XmoGoalPageForm from './XmoGoalPageForm';

export default function XmoGoal() {
  const {product, onSubmit, onBack} = useProduct(Urls.XmoStrategyGoal);

  return (
      <ContentLoader status={product.status} message={product.message}>
        <XmoGoalPageForm
            product={product?.content?.data}
            onSubmit={onSubmit}
            onBack={onBack}
        />
      </ContentLoader>
  );
}