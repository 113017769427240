/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StatusType } from 'models/StatusType';
import { RootState } from 'services/redux/store';
import { TopNavStatusEnum } from './TopNavStatusEnum';

export type MenuStatus = 'completed' | 'active' | 'incomplete';
export interface MenuType {
  id?: number,
  label?: string;
  url?: string;
  name?: string;
  status: MenuStatus | TopNavStatusEnum;
  offeringType?: string;
  orderItemId?: string;
  subSteps?: MenuType[];
  builtFor?: number,
}

export interface MenuState {
  menu: StatusType<MenuType[]>
  built: boolean
  activeOfferingType: string
  nextStep: string;
  activeProductId: number;
}

const initialState: MenuState = {
  menu: {
    content: [],
    status: 'idle',
    message: '',
  },
  built: false,
  activeOfferingType: null,
  nextStep: '',
  activeProductId: null,
};

const updateSubStepsAsCompleted = (menu) => {
  if (menu?.subSteps) {
    // eslint-disable-next-line no-return-assign
    menu.subSteps.forEach(step => step.status = 'completed');
  }
};

const updatePreviousStepsAsCompleted = (menu:MenuType[], index: number) => {
  let i = 0;
  while (i < index) {
    menu[i].status = 'completed';
    updateSubStepsAsCompleted(menu[i]);
    i++;
  }
};

const updatePreviousTopNavStepsAsCompleted = (menu:MenuType[], index: number) => {
  let i = 0;
  while (i < index) {
    menu[i].status = TopNavStatusEnum.Complete;
    updateSubStepsAsCompleted(menu[i]);
    i++;
  }
};

const navigationSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    buildProductsMenu(state, action) {
      state.menu.content = action.payload;
      state.menu.status = 'idle';
      state.built = true;
    },
    updateActiveStep(state, action: PayloadAction<{ url: string, offeringType?: string, orderItemId?: string }>) {
      let menuIndex = state.menu.content.findIndex(step => step.url === action.payload.url
      || step.builtFor === Number(action.payload.orderItemId));

      let leftIndex = -1;
      let topNavIndex = -1;
      if (menuIndex < 0 && leftIndex < 0) {
        state.menu.content.forEach((step, index) => {
          if (menuIndex < 0 && step?.subSteps) {
            leftIndex = step.subSteps.findIndex(s => s.url === action.payload.url);
            if (leftIndex < 0) {
              step.subSteps.forEach((s, i) => {
                if (leftIndex < 0 && s?.subSteps) {
                  topNavIndex = s.subSteps.findIndex(s => s.url === action.payload.url);
                  if (topNavIndex >= 0) {
                    leftIndex = i;
                    menuIndex = index;
                  }
                }
              });
            } else menuIndex = index;
          }
        });
      }

      if (menuIndex >= 0) {
        state.menu.content[menuIndex].status = 'active';
        if (action.payload.orderItemId && leftIndex === -1 && action.payload.orderItemId !== '') {
          const menuSubSteps = state.menu.content[menuIndex].subSteps;
          leftIndex = menuSubSteps.findIndex(s => s.url === action.payload.url);
          menuSubSteps.forEach((s, i) => {
            if (leftIndex < 0 && s?.subSteps) {
              topNavIndex = s.subSteps.findIndex(s => s.url === action.payload.url);
              if (topNavIndex >= 0) {
                leftIndex = i;
              }
            }
          });
        }
        updatePreviousStepsAsCompleted(state.menu.content, menuIndex);
      }
      if (leftIndex >= 0) {
        state.menu.content[menuIndex].subSteps[leftIndex].status = 'active';
        updatePreviousStepsAsCompleted(state.menu.content[menuIndex].subSteps, leftIndex);
      }
      if (topNavIndex >= 0) {
        state.menu.content[menuIndex].subSteps[leftIndex].subSteps[topNavIndex].status = TopNavStatusEnum.Current;
        updatePreviousTopNavStepsAsCompleted(state.menu.content[menuIndex].subSteps[leftIndex].subSteps, topNavIndex);
      }
    },
    startLoading(state) {
      state.menu.status = 'loading';
    },
    finishLoading(state) {
      state.menu.status = 'idle';
    },
    changeActiveOfferingType(state, action: PayloadAction<string>) {
      state.activeOfferingType = action.payload;
    },
    changeActiveProductId(state, action: PayloadAction<number>) {
      state.activeProductId = action.payload;
    },
  },
});

export const {
  buildProductsMenu, updateActiveStep, changeActiveOfferingType, changeActiveProductId,
} = navigationSlice.actions;

// Create just one array for all the menu options
export function flattenMenu(steps) {
  const flatArray = [];
  function flatten(step, builtFor) {
    const flattenedStep = { ...step };

    if (builtFor !== undefined) {
      flattenedStep.builtFor = builtFor;
    }

    flatArray.push(flattenedStep);

    if (step.subSteps) {
      step.subSteps.forEach(subStep => flatten(subStep, builtFor));
    }
  }

  steps.forEach(step => flatten(step, step.builtFor));

  return flatArray;
}

export const selectActiveOfferingType = (state: RootState) => state.navMenu.activeOfferingType;
export const selectActiveProductId = (state: RootState) => state.navMenu.activeProductId;
export const selectNavMenuBuilt = (state: RootState) => state.navMenu.built;
export const selectNavigationMenu = (state: RootState) => state.navMenu.menu.content;

const NavMenuReducer = navigationSlice.reducer;

export default NavMenuReducer;
