import { Typography, Box, Grid } from '@mui/material';
import GridItem from 'components/grid/GridItem';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from 'services/redux/store';
import { getProduct } from 'services/productsSlice';
import { displayNotRequiredField, editButton } from 'pages/overview/Overview';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { Urls } from 'navigation/Urls';
import OrderTracker from 'pages/orders/OrderTracker';
import { headingStyle } from '../Business/BusinessSection';
import { renderChatLocation } from './chatLocation';
import { actionItems } from '../common/actionItems';
import { GeneralQuestionsSection } from '../generalQuestions/GeneralQuestionsSection';

const mapCallConnect = {
  standardBusinessHours: 'Standard Business Hours',
  customCallConnectHours: 'Custom Call Connect Hours',
};

export default function ChatSection({
  product, isEditable, showTracker, openBusinessAndSalesAgent,
}: any) {
  const appDispatch = useAppDispatch();
  const navigate = useNavigationHandler();
  const [chatData, setChatData] = useState({} as any);

  const siteType = product.pdOfferingType;

  const getProductData = (orderItemId) => {
    appDispatch(getProduct({
      orderItemId,
    })).then((response) => setChatData(response.payload));
  };

  useEffect(() => {
    if (product) {
      getProductData(product?.id);
    }
  }, [product]);

  const offeringType = product.offeringType === 'ProductsData' ? product.pdOfferingType : product.offeringType;
  const businessUrl = offeringType === 'ChatProduct' ? Urls.BusinessChat : Urls.PlatinumBusinessChat;
  const scriptDetailsUrl = offeringType === 'ChatProduct' ? Urls.ScriptDetails : Urls.PlatinumScriptDetails;
  const additionalUrl = offeringType === 'ChatProduct' ? Urls.ChatAdditional : Urls.PlatinumChatAdditional;
  const chatLocationsUrl = offeringType === 'ChatProduct' ? Urls.ChatLocations : Urls.PlatinumChatLocations;
  const status = product?.trackerStatus === 'Completed' ? 'Chat Live' : product?.trackerStatus;

  return (
    <Grid container sx={{ borderTop: '1px solid #716F6F' }}>
      {/* Status Tracker */}
      {showTracker && (
      <GridItem sizes={[12, 12]} marginBottom={8} sx={{
        padding: '40px 0 0', borderBottom: '1px solid #000000',
      }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {editButton(isEditable, () => {})}
          <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Order Status</Typography>
        </Box>
        <Box>
          <OrderTracker
            key={product?.id}
            status={status}
            productName={product?.pdOfferingType}
            product={product}
          />
        </Box>
      </GridItem>
      )}

      {/* General Questions */}
      { GeneralQuestionsSection(offeringType, product.offeringId, product.id, navigate, isEditable) }

      {/* Display */}
      <GridItem sizes={[12, 12]} marginBottom={3} sx={{ padding: '20px 0 0', borderTop: '1px solid black' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {editButton(isEditable, () => navigate.to(
            businessUrl,
            product.id,
          ))}
          <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Display</Typography>
        </Box>
        <Box sx={{ marginTop: '15px' }}>
          <GridItem sizes={[6, 6]}>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Business URL</Typography>
                <Typography>{chatData?.data?.businessUrl}</Typography>
              </Box>
            </Box>
            <Box />
          </GridItem>
        </Box>
      </GridItem>

      {/* Chat Script Details */}
      <GridItem sizes={[12, 12]} marginBottom={3} sx={{ padding: '20px 0 0', borderTop: '1px solid black' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {editButton(isEditable, () => navigate.to(
            scriptDetailsUrl,
            product.id,
          ))}
          <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Script Details</Typography>
        </Box>
        <Box sx={{ marginTop: '15px' }}>
          <GridItem sizes={[6, 6]}>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Provides Estimates or Consultations?</Typography>
                {chatData?.data?.provideEstimatesConsultations
                  ? (
                    <Typography style={{ textTransform: 'capitalize' }}>
                      {chatData?.data?.provideEstimatesConsultations}
                    </Typography>
                  ) : <Typography fontWeight="bold">No estimates or consultations</Typography>}
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">How are estimates/consultations provided?</Typography>
                {chatData?.data?.additionalDetails?.provideEstimateInPerson && <Typography>In-Person</Typography>}
                {chatData?.data?.additionalDetails?.provideEstimateByPhone && <Typography>Over the Phone</Typography>}
                {chatData?.data?.additionalDetails?.otherDescription && (
                  <Typography>{chatData?.data?.additionalDetails?.otherDescription}</Typography>)}
                {(!chatData?.data?.additionalDetails?.provideEstimateInPerson
                  && !chatData?.data?.additionalDetails?.provideEstimateByPhone
                  && !chatData?.data?.additionalDetails?.other) && (
                    <Typography fontWeight="bold">No options</Typography>)}
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Estimate and Consultation Information</Typography>
                {displayNotRequiredField('information', chatData?.data?.estimatesConsultationsInformation)}
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Are Estimates/Consultations free?</Typography>
                {chatData?.data?.additionalDetails?.provideFreeConsultation === 'no' && (
                  <Typography>No, there is a service fee</Typography>)}
                {chatData?.data?.additionalDetails?.provideFreeConsultation === 'yes' && (
                  <Typography>Yes</Typography>)}
                {!chatData?.data?.additionalDetails?.provideFreeConsultation && (
                  <Typography fontWeight="bold">No selection provided</Typography>)}
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Service Fee Amount</Typography>
                {displayNotRequiredField('service fee amount', chatData?.data?.minimumServiceFee)}
              </Box>
              {siteType === 'ChatPlatinumProduct' && (
                <>
                  <Box mb={2}>
                    <Typography sx={headingStyle} color="primary">Products and Services</Typography>
                    <Typography>{chatData?.data?.productsAndServices}</Typography>
                  </Box>
                  <Box mb={2}>
                    <Typography sx={headingStyle} color="primary">Appointments and Scheduling</Typography>
                    <Typography>{chatData?.data?.appointmentsAndScheduling}</Typography>
                  </Box>
                </>
              )}
            </Box>
            <Box>
              {siteType !== 'ChatPlatinumProduct' && (
                <Box mb={2}>
                  <Typography sx={headingStyle} color="primary">Chat Suggestions</Typography>
                  {displayNotRequiredField('suggestions', chatData?.data?.chatSuggestions)}
                </Box>
              )}
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Call Connect Time Zone</Typography>
                {displayNotRequiredField('timezone', chatData?.data?.additionalDetails?.callConnectTimezone)}
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">When should Call Connect be available?</Typography>
                {/* eslint-disable-next-line max-len */}
                {displayNotRequiredField('timezone', mapCallConnect[chatData?.data?.additionalDetails?.callConnectAvailable])}
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Custom Call Connect Hours</Typography>
                {chatData?.data?.additionalDetails?.customCallConnectHours
                  ? chatData.data.additionalDetails.customCallConnectHours
                    .replaceAll('\\n', '_br_')
                    .replaceAll('\\', '')
                    .split('_br_').map(item => <Typography>{item}</Typography>)
                  : <Typography fontWeight="bold">No hours provided</Typography>}
              </Box>
              {siteType === 'ChatPlatinumProduct' && (
                <Box mb={2}>
                  <Typography sx={headingStyle} color="primary">FAQs and Response</Typography>
                  <Typography>{chatData?.data?.faqsAndResponses}</Typography>
                </Box>
              )}
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Follow-Up Preferences</Typography>
                {chatData?.data?.followUpPhone && <Typography>Phone</Typography>}
                {chatData?.data?.followUpEmail && <Typography>Email</Typography>}
                {chatData?.data?.followUpSms && <Typography>SMS</Typography>}
              </Box>
            </Box>
          </GridItem>
        </Box>
      </GridItem>

      {/* Locations */}
      {React.Children.toArray(chatData?.data?.locations?.filter((location) => location?.selected)
        .map((location) => renderChatLocation(
          location,
          navigate,
          product,
          offeringType,
          chatLocationsUrl,
          isEditable,
        )))}

      {/* Additional */}
      <GridItem sizes={[12, 12]} marginBottom={3} sx={{ padding: '20px 0 0', borderTop: '1px solid black' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {editButton(isEditable, () => navigate.to(
            additionalUrl,
            product.id,
          ))}
          <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Additional</Typography>
        </Box>
        <Box sx={{ marginTop: '15px' }}>
          <GridItem sizes={[6, 6]}>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Additional Notes</Typography>
                {displayNotRequiredField('notes', chatData?.data?.additionalNotes)}
              </Box>
            </Box>
            <Box />
          </GridItem>
        </Box>
      </GridItem>

      {/* Actions items */}
      {showTracker && actionItems(false, product?.id, false, openBusinessAndSalesAgent)}
    </Grid>
  );
}
