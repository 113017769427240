/* eslint-disable no-param-reassign */
import {DefaultPageLayout} from 'layouts/DefaultPageLayout';
import {useTranslation} from 'react-i18next';
import {FormControlLabel, Radio, RadioGroup, Typography,} from '@mui/material';
import {useState} from 'react';
import GridItem from 'components/grid/GridItem';
import GridContainer from 'components/grid/GridContainer';
import schema from 'pages/xmo/details/XmoStepDetails.schema';
import {
  IQFormInput,
  IQInputEmail,
  IQInputWebURL,
  IQTheme,
  IQThemeV2,
  ValidationProvider,
} from '@gannettdigital/shared-react-components';
import {FormProvider, useForm} from 'react-hook-form';
import XmoStepType from 'models/XmoStepType';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import schemaValidate from 'components/schemaValidate';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import NePhoneInput from 'components/NePhoneInputContainer/IQPhoneInputContainer';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import {yesNoItems} from "pages/xmo/details/XmoStepDetailsService";

interface Props {
  product: XmoStepType;
  onSubmit: (data) => void;
  onBack: () => void;
}

export type DetailsFormProps = {
  clientHaveLocalIqWebsite: string,
  localIqWebsiteLive: string,
  websiteUrl: string,
  temporaryWebsiteUrl: string,
  clientFullName: string,
  clientEmail: string,
  clientPhoneNumber: string,
  sameEmailForCreativeApproval: string,
  creativeApprovalEmail: string,
  bestPersonToGrantFBAdminAccess: string,
  facebookAdminAccessEmail: string,
  clientHaveGoogleAnalytics: string,
  bestPersonForGoogleAnalyticsAccess: string,
  googleAnalyticsAccessContactFullName: string,
  googleAnalyticsAccessEmail: string,
  googleAnalyticsAccessPhoneNumber: string
};

export default function XmoStepDetailsPageForm({ product, onSubmit, onBack }: Props) {
  const prodXmoStepDetails = product?.details;

  const { t } = useTranslation();
  const [isClientHavingWebsite, setIsClientHavingWebsite] = useState(prodXmoStepDetails?.clientHaveLocalIqWebsite || '');
  const [isLocalWebsiteLive, setIsLocalWebsiteLive] = useState(prodXmoStepDetails?.localIqWebsiteLive || '');
  const [isSameEmail, setIsSameEmail] = useState(prodXmoStepDetails?.sameEmailForCreativeApproval || '');
  const [isBestPerson, setIsBestPerson] = useState(prodXmoStepDetails?.bestPersonToGrantFBAdminAccess || '');
  const [isClientHavingGoogleAnalytics, setIsClientHavingGoogleAnalytics] = useState(prodXmoStepDetails?.clientHaveGoogleAnalytics || '');
  const [isBestPersonForGAA, setIsBestPersonForGAA] = useState(prodXmoStepDetails?.bestPersonForGoogleAnalyticsAccess || '');

  const methods = useForm<DetailsFormProps>({
    mode: 'all',
    defaultValues: {
      clientHaveLocalIqWebsite: prodXmoStepDetails?.clientHaveLocalIqWebsite || '',
      localIqWebsiteLive: prodXmoStepDetails?.localIqWebsiteLive || '',
      websiteUrl: prodXmoStepDetails?.websiteUrl || '',
      temporaryWebsiteUrl: prodXmoStepDetails?.temporaryWebsiteUrl || '',
      clientFullName: prodXmoStepDetails?.clientFullName || '',
      clientEmail: prodXmoStepDetails?.clientEmail || '',
      clientPhoneNumber: prodXmoStepDetails?.clientPhoneNumber || '',
      sameEmailForCreativeApproval: prodXmoStepDetails?.sameEmailForCreativeApproval || '',
      creativeApprovalEmail: prodXmoStepDetails?.creativeApprovalEmail || '',
      bestPersonToGrantFBAdminAccess: prodXmoStepDetails?.bestPersonToGrantFBAdminAccess || '',
      facebookAdminAccessEmail: prodXmoStepDetails?.facebookAdminAccessEmail || '',
      clientHaveGoogleAnalytics: prodXmoStepDetails?.clientHaveGoogleAnalytics || '',
      bestPersonForGoogleAnalyticsAccess: prodXmoStepDetails?.bestPersonForGoogleAnalyticsAccess || '',
      googleAnalyticsAccessContactFullName: prodXmoStepDetails?.googleAnalyticsAccessContactFullName || '',
      googleAnalyticsAccessEmail: prodXmoStepDetails?.googleAnalyticsAccessEmail || '',
      googleAnalyticsAccessPhoneNumber: prodXmoStepDetails?.googleAnalyticsAccessPhoneNumber || '',
    },
  });

  const handleFormSubmit = (data: DetailsFormProps) => {
    if (data.clientHaveLocalIqWebsite !== undefined && data.clientHaveLocalIqWebsite === 'No') {
      data.localIqWebsiteLive = '';
      data.websiteUrl = '';
      data.temporaryWebsiteUrl = '';
    } else if (data.clientHaveLocalIqWebsite !== undefined && data.clientHaveLocalIqWebsite === 'Yes') {
      if (data.localIqWebsiteLive !== undefined && data.localIqWebsiteLive === 'Yes') {
        data.temporaryWebsiteUrl = '';
      } else if (data.localIqWebsiteLive !== undefined && data.localIqWebsiteLive === 'No') {
        data.websiteUrl = '';
      }
    }
    if (data.sameEmailForCreativeApproval !== undefined && data.sameEmailForCreativeApproval === 'Yes') {
      data.creativeApprovalEmail = '';
    }
    if (data.bestPersonToGrantFBAdminAccess !== undefined && data.bestPersonToGrantFBAdminAccess === 'Yes') {
      data.facebookAdminAccessEmail = '';
    }
    if (data.clientHaveGoogleAnalytics !== undefined && data.clientHaveGoogleAnalytics === 'No') {
      data.bestPersonForGoogleAnalyticsAccess = '';
      data.googleAnalyticsAccessContactFullName = '';
      data.googleAnalyticsAccessEmail = '';
      data.googleAnalyticsAccessPhoneNumber = '';
    } else if (data.clientHaveGoogleAnalytics !== undefined && data.clientHaveGoogleAnalytics === 'Yes') {
      if (data.bestPersonForGoogleAnalyticsAccess !== undefined && data.bestPersonForGoogleAnalyticsAccess === 'Yes') {
        data.googleAnalyticsAccessContactFullName = '';
        data.googleAnalyticsAccessEmail = '';
        data.googleAnalyticsAccessPhoneNumber = '';
      }
    }
    const pageFormData = {
      details: data
    };
    onSubmit(pageFormData);
  };

  const {
    handleSubmit, register,
    formState: {
      isValid,
      isDirty,
      isSubmitSuccessful,
      isSubmitting,
    },
    watch,
    setValue,
  } = methods;

  const handleClientHavingWebsiteRadioChange = (event) => {
    setIsClientHavingWebsite(event.target.value);
    if (event.target.value === 'No') {
      setIsLocalWebsiteLive(undefined);
    }
    if (event.target.value === 'No') {
      setValue('localIqWebsiteLive', '');
    }
  };

  const handleClientHaveGoogleAnalyticsRadioChange = (event) => {
    setIsClientHavingGoogleAnalytics(event.target.value)
    if (event.target.value === 'No') {
      setValue('bestPersonForGoogleAnalyticsAccess', '');
    }
  };

  const handleBestPersonGoogleAnalyticsRadioChange = (event) => {
    setIsBestPersonForGAA(event.target.value)
    if (event.target.value === 'Yes') {
      setValue('googleAnalyticsAccessContactFullName', '');
      setValue('googleAnalyticsAccessEmail', '');
      setValue('googleAnalyticsAccessPhoneNumber', '');
    }
  };

  return (
    <DefaultPageLayout
      disableContinue={!isValid}
      onBack={onBack}
      header={t('pages.generalQuestions.details')}
      onContinue={handleSubmit(handleFormSubmit)}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <GridContainer fullWidth>
            <GridItem sizes={[12]}>
              <Typography
                variant="h6"
              >
                {t('pages.targetedDisplay.solutionStrategy.websiteInfo.title')}
              </Typography>
            </GridItem>
            <GridItem sizes={[12]}>
              <Typography
                variant="subtitle1"
              >
                {t('pages.xmo.details.doesClientHaveWebsite')}
                <RadioGroup
                  id="clientHaveLocalIqWebsite"
                  name="clientHaveLocalIqWebsite"
                  defaultValue={prodXmoStepDetails?.clientHaveLocalIqWebsite || ''}
                  onChange={handleClientHavingWebsiteRadioChange}
                  row
                >
                  {yesNoItems.map(opt => (
                    <FormControlLabel
                        key={opt.label}
                        control={<Radio value={opt.label}/>}
                        label={opt.label}
                      {...register('clientHaveLocalIqWebsite', {
                        validate: (value) => schemaValidate(value, 'clientHaveLocalIqWebsite', schema),
                      })}
                    />
                  ))}
                </RadioGroup>
              </Typography>
            </GridItem>
            {isClientHavingWebsite === 'Yes' && (
            <GridItem sizes={[12]}>
              <Typography
                variant="subtitle1"
              >
                {t('pages.xmo.details.isLocaliQWebsiteLive')}
                <RadioGroup
                  id="localIqWebsiteLive"
                  name="localIqWebsiteLive"
                  defaultValue={watch('localIqWebsiteLive') || ''}
                  onChange={(event) => setIsLocalWebsiteLive(event.target.value)}
                  row
                >
                  {yesNoItems.map(opt => (
                    <FormControlLabel
                        key={opt.label}
                        control={<Radio value={opt.label}/>}
                        label={opt.label}
                      {...register('localIqWebsiteLive', {
                        validate: (value) => schemaValidate(value, 'localIqWebsiteLive', schema),
                      })}
                    />
                  ))}
                </RadioGroup>
              </Typography>
            </GridItem>
            )}
            {isLocalWebsiteLive === 'Yes' && (
            <GridItem sizes={[12]}>
              <IQInputWebURL theme={IQTheme}
                fontLabelWeight="600"
                id="websiteUrl"
                name="websiteUrl"
                required
                fullWidth
                showError
                label={t('pages.ccByDash.details.websiteUrlLabel')}
                adornmentIcon={<InsertLinkIcon />}
              />
            </GridItem>
            )}
            {isLocalWebsiteLive === 'No' && (
            <GridItem sizes={[12]}>
              <IQInputWebURL theme={IQTheme}
                fontLabelWeight="600"
                id="temporaryWebsiteUrl"
                name="temporaryWebsiteUrl"
                required
                fullWidth
                showError
                label={t('pages.xmo.details.temporaryWebsiteUrlLabel')}
                adornmentIcon={<InsertLinkIcon />}
              />
            </GridItem>
            )}
            <GridItem sizes={[12]}>
              <Typography variant="h6">{t('pages.xmo.details.clientInfo')}</Typography>
            </GridItem>
            <GridItem sizes={[12]}>
              <IQFormInput
                schema={schema}
                theme={IQTheme}
                fontLabelWeight="600"
                id="clientFullName"
                name="clientFullName"
                fullWidth
                showError
                labelText={t('pages.business.client.edit.fullNameLabel')}
              />
            </GridItem>
            <GridItem sizes={[12]}>
              <IQInputEmail
                schema={schema}
                theme={IQTheme}
                fontLabelWeight="600"
                field="clientEmail"
                name="clientEmail"
                required
                fullWidth
                showError
                adornmentIcon={<MailOutlineIcon />}
                label={t('pages.business.client.edit.emailLabel')}
              />
            </GridItem>
            <GridItem sizes={[12]}>
              <NePhoneInput
                name="clientPhoneNumber"
                defaultValue=""
                country="US"
                hasExtension
                phoneLabelOptions={{
                  tooltipText: '',
                  labelText: t('pages.socialAds.solutionStrategy.socialAdsLeadAds.phoneNumberOptions'),
                  hasError: true,
                  theme: IQThemeV2,
                  required: true,
                }}
                hasType={false}
                countryCallingCodeEditable={false}
                international
                extensionOptions={{
                  name: 'extension',
                  defaultValue: '',
                }}
              />
            </GridItem>
            <GridItem sizes={[12]}>
              <Typography
                variant="subtitle1"
              >
                {t('pages.socialAds.solutionStrategy.contactInformation.isThisTheSameEmailForCreativeApprovalLabel')}
                <RadioGroup
                  id="sameEmailForCreativeApproval"
                  name="sameEmailForCreativeApproval"
                  defaultValue={prodXmoStepDetails?.sameEmailForCreativeApproval || ''}
                  onChange={(event) => setIsSameEmail(event.target.value)}
                  row
                >
                  {yesNoItems.map(opt => (
                    <FormControlLabel
                        key={opt.label}
                        control={<Radio value={opt.label}/>}
                        label={opt.label}
                      {...register('sameEmailForCreativeApproval', {
                        validate: (value) => schemaValidate(value, 'sameEmailForCreativeApproval', schema),
                      })}
                    />
                  ))}
                </RadioGroup>
              </Typography>
            </GridItem>
            {isSameEmail === 'No' && (
            <GridItem sizes={[12]}>
              <IQInputEmail
                schema={schema}
                theme={IQTheme}
                fontLabelWeight="600"
                field="creativeApprovalEmail"
                name="creativeApprovalEmail"
                required
                fullWidth
                showError
                adornmentIcon={<MailOutlineIcon />}
                label={t('pages.xmo.details.creativeApprovalEmailLabel')}
              />
            </GridItem>
            )}
            <GridItem sizes={[12]}>
              <Typography
                variant="subtitle1"
              >
                {t('pages.socialAds.solutionStrategy.contactInformation.isThisTheBestPersonToGrantUsFacebookAdminAccessLabel')}
                <RadioGroup
                  id="bestPersonToGrantFBAdminAccess"
                  name="bestPersonToGrantFBAdminAccess"
                  defaultValue={prodXmoStepDetails?.bestPersonToGrantFBAdminAccess || ''}
                  onChange={(event => setIsBestPerson(event.target.value))}
                  row
                >
                  {yesNoItems.map(opt => (
                    <FormControlLabel
                        key={opt.label}
                        control={<Radio value={opt.label}/>}
                        label={opt.label}
                      {...register('bestPersonToGrantFBAdminAccess', {
                        validate: (value) => schemaValidate(value, 'bestPersonToGrantFBAdminAccess', schema),
                      })}
                    />
                  ))}
                </RadioGroup>
              </Typography>
            </GridItem>
            {isBestPerson === 'No' && (
            <GridItem sizes={[12]}>
              <IQInputEmail
                schema={schema}
                theme={IQTheme}
                fontLabelWeight="600"
                field="facebookAdminAccessEmail"
                name="facebookAdminAccessEmail"
                required
                fullWidth
                showError
                adornmentIcon={<MailOutlineIcon />}
                label={t('pages.xmo.details.facebookAdminAccessEmailLabel')}
              />
            </GridItem>
            )}
            <GridItem sizes={[12]}>
              <Typography
                variant="subtitle1"
              >
                {t('pages.xmo.details.clientHasGoogleAnalyticsAndWantUsToHaveAccessToItToGaugePerformanceLabel')}
                <RadioGroup
                    id="clientHaveGoogleAnalytics"
                    name="clientHaveGoogleAnalytics"
                    defaultValue={prodXmoStepDetails?.clientHaveGoogleAnalytics || ''}
                    onChange={handleClientHaveGoogleAnalyticsRadioChange}
                    row
                >
                  {yesNoItems.map(opt => (
                    <FormControlLabel
                        key={opt.label}
                        control={<Radio value={opt.label}/>}
                        label={opt.label}
                      {...register('clientHaveGoogleAnalytics', {
                        validate: (value) => schemaValidate(value, 'clientHaveGoogleAnalytics', schema),
                      })}
                    />
                  ))}
                </RadioGroup>
              </Typography>
            </GridItem>
            {isClientHavingGoogleAnalytics === 'Yes' && (
            <GridItem sizes={[12]}>
              <Typography
                variant="subtitle1"
              >
                {t('pages.xmo.details.bestPersonForGoogleAnalyticsAccessLabel')}
                <RadioGroup
                  id="bestPersonForGoogleAnalyticsAccess"
                  name="bestPersonForGoogleAnalyticsAccess"
                  defaultValue={watch('bestPersonForGoogleAnalyticsAccess') || ''}
                  onChange={handleBestPersonGoogleAnalyticsRadioChange}
                  row
                >
                  {yesNoItems.map(opt => (
                    <FormControlLabel
                        key={opt.label}
                        control={<Radio value={opt.label}/>}
                        label={opt.label}
                      {...register('bestPersonForGoogleAnalyticsAccess', {
                        validate: (value) => schemaValidate(value, 'bestPersonForGoogleAnalyticsAccess', schema),
                      })}
                    />
                  ))}
                </RadioGroup>
              </Typography>
            </GridItem>
            )}
            {watch('bestPersonForGoogleAnalyticsAccess') === 'No' && (
                <>
                  <GridItem sizes={[12]}>
                    <IQFormInput
                        schema={schema}
                        theme={IQTheme}
                        fontLabelWeight="600"
                        id="googleAnalyticsAccessContactFullName"
                        name="googleAnalyticsAccessContactFullName"
                        fullWidth
                        showError
                        labelText={t('pages.xmo.details.googleAnalyticsAccessContactFullNameLabel')}
                    />
                  </GridItem>
                  <GridItem sizes={[12]}>
                    <IQInputEmail
                        schema={schema}
                        theme={IQTheme}
                        fontLabelWeight="600"
                        field="googleAnalyticsAccessEmail"
                        name="googleAnalyticsAccessEmail"
                        required
                        fullWidth
                        showError
                        adornmentIcon={<MailOutlineIcon/>}
                        label={t('pages.business.client.edit.emailLabel')}
                    />
                  </GridItem>
                  <GridItem sizes={[12]}>
                    <NePhoneInput
                        name="googleAnalyticsAccessPhoneNumber"
                        defaultValue=""
                        country="US"
                        hasExtension
                        phoneLabelOptions={{
                          tooltipText: '',
                          labelText: t('pages.socialAds.solutionStrategy.socialAdsLeadAds.phoneNumberOptions'),
                          hasError: true,
                          theme: IQThemeV2,
                          required: true,
                        }}
                        hasType={false}
                        countryCallingCodeEditable={false}
                        international
                        extensionOptions={{
                          name: 'extension',
                          defaultValue: '',
                        }}
                    />
                  </GridItem>
                </>
            )}
          </GridContainer>
          <CoNavigationConfirm
            showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
          />
        </FormProvider>
      </ValidationProvider>
    </DefaultPageLayout>
  );
}
