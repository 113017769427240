import React, { useEffect, useState } from 'react';
import { IQThemeV2 } from '@gannettdigital/shared-react-components';
import {
  FormHelperText, Grid, InputAdornment, TextField, Typography,
} from '@mui/material';
import ErrorText from 'components/errorText/ErrorText';
import PhoneIcon from '@mui/icons-material/Phone';
import i18n from 'i18n/i18n';

const { t } = i18n;

export default function PhoneNumberTextField(props: any) {
  const [isTouched, setIsTouched] = useState(!!props.value);
  const [validationError, setValidationError] = useState('');
  const [formattedValue, setFormattedValue] = useState('+1');

  const formatPhoneNumber = (value) => {
    const numericValue = value.replace(/\D/g, '');
    return numericValue.replace(/(\d{1})(\d{3})?(\d{3})?(\d{4})?/, '+1 $2 $3 $4').trim();
  };

  useEffect(() => {
    if (props.value) {
      setFormattedValue(formatPhoneNumber(props.value));
    }
  }, [props.value]);

  useEffect(() => {
    if (props.value && !isTouched) {
      setFormattedValue(formatPhoneNumber(props.value));
    }
  }, [props.value, isTouched]);

  const stripNonNumeric = (value) => value.replace(/\D/g, '');

  const handleChange = (newValue) => {
    setIsTouched(true);
    const strippedValue = stripNonNumeric(newValue);
    const formattedNumber = formatPhoneNumber(strippedValue);
    setFormattedValue(formattedNumber);

    const isValidFormat = /^\+1 \d{3} \d{3} \d{4}$/.test(formattedNumber);

    if (isValidFormat) {
      props.onChange(newValue);
      setValidationError('');
    } else {
      props.onChange('');
      setValidationError(t('pages.shoppingAds.solutionStrategy.clientsContactNumberErrorLabel'));
    }
  };

  const handleKeyPress = (e) => {
    const isValidKey = /^\d$/.test(e.key);
    if (!isValidKey) {
      e.preventDefault();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Backspace' && formattedValue === '+1') {
      e.preventDefault();
    }
  };

  const hasRawErrors = Array.isArray(props.rawErrors) && props.rawErrors.length > 0;

  return (
    <Grid item xs={9} mt={3}>
      <Typography
        fontWeight={600}
        sx={{
          color: validationError || (hasRawErrors && isTouched)
            ? IQThemeV2.palette.error.main
            : IQThemeV2.palette.text.primary,
        }}
      >
        {props.label}
      </Typography>
      <TextField
        sx={{ mt: 1 }}
        id={props.id}
        disabled={props.disabled}
        required={props.required}
        name={props.name}
        type="tel"
        placeholder="+1 888 888 8888"
        error={!!validationError || (hasRawErrors && isTouched)}
        value={formattedValue}
        onChange={(e) => {
          handleChange(e.target.value);
          handleKeyPress(e);
        }}
        onKeyDown={handleKeyDown}
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PhoneIcon />
            </InputAdornment>
          ),
        }}
      />
      {props.placeholder && (!hasRawErrors || !isTouched) && (
        <FormHelperText error={false}>{props.placeholder}</FormHelperText>
      )}
      <ErrorText
        hasError={!!validationError || (hasRawErrors && isTouched)}
        errorText={validationError || props?.rawErrors?.[0]}
      />
    </Grid>
  );
}
