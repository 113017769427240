export enum FormStatus {
  WEBSITE = 'ProductsData',
  GOLD_SITE = 'ProductsData',
  LANDING_PAGES = 'ProductsData',
  ECOMMERCE_MICROSITE = 'ProductsData',
  CHAT_PRODUCT = 'ProductsData',
  CHAT_PLATINUM_PRODUCT = 'ProductsData',
}

export enum LocationSteps {
  SERVICE_AREA = 'service_area',
  BUSINESS_INFORMATION = 'business_information',
  ADDRESS = 'address',
  CLIENT_INFORMATION = 'client_information',
  HOURS = 'hours',
  PHOTOS = 'business_logos',
  ADDITIONAL_INFO = 'additional_info',
  CONTACT = 'contact',
  PROVIDER = 'provider',
  INFO = 'info',
}

export enum OrderFlow {
  SALES_FORCE = 'SALESFORCE',
  INTERNAL = 'INTERNAL',
  PUNCHLIST = 'PUNCHLIST',
  SALESFORCE_BLUE = 'SALESFORCE_BLUE',
}

export const mediaProducts = [
  'Website', 'GoldSite', 'EcommerceMicrosite', 'LandingPage',
  'PremiumListing', 'ChatProduct', 'ChatPlatinumProduct',
  'CustomLandingPage', 'PiQLandingPages', 'CustomWebsite', 'CustomCampaignLandingPages',
];

export const isLocationStepCompleted = (step: string, lastCompletedStep?: string) => {
  const lastStepU = lastCompletedStep ? lastCompletedStep.toLowerCase() : '';
  switch (step.toLowerCase()) {
    case LocationSteps.ADDITIONAL_INFO:
      if (lastStepU === LocationSteps.ADDITIONAL_INFO) {
        return true;
      }
      break;
    case LocationSteps.PHOTOS:
      if (lastStepU === LocationSteps.PHOTOS || lastStepU === LocationSteps.ADDITIONAL_INFO) {
        return true;
      }
      break;
    case LocationSteps.HOURS:
      if (lastStepU === LocationSteps.ADDITIONAL_INFO
        || lastStepU === LocationSteps.PHOTOS
        || lastStepU === LocationSteps.HOURS) {
        return true;
      }
      break;
    case LocationSteps.BUSINESS_INFORMATION:
      if (lastStepU === LocationSteps.BUSINESS_INFORMATION
          || lastStepU === LocationSteps.ADDITIONAL_INFO
          || lastStepU === LocationSteps.PHOTOS
          || lastStepU === LocationSteps.HOURS) {
        return true;
      }
      break;
    case LocationSteps.INFO:
      if (lastStepU === LocationSteps.INFO
            || lastStepU === LocationSteps.ADDITIONAL_INFO
            || lastStepU === LocationSteps.CONTACT
            || lastStepU === LocationSteps.PHOTOS
            || lastStepU === LocationSteps.HOURS) {
        return true;
      }
      break;
    case LocationSteps.CONTACT:
      if (lastStepU === LocationSteps.CLIENT_INFORMATION
        || lastStepU === LocationSteps.ADDITIONAL_INFO
        || lastStepU === LocationSteps.CONTACT
        || lastStepU === LocationSteps.PHOTOS
        || lastStepU === LocationSteps.HOURS) {
        return true;
      }
      break;
    case LocationSteps.PROVIDER:
      if (lastStepU === LocationSteps.PROVIDER
          || lastStepU === LocationSteps.ADDITIONAL_INFO
          || lastStepU === LocationSteps.CONTACT
          || lastStepU === LocationSteps.PHOTOS
          || lastStepU === LocationSteps.HOURS) {
        return true;
      }
      break;
    case LocationSteps.ADDRESS:
      if (lastStepU === LocationSteps.ADDRESS
        || lastStepU === LocationSteps.CLIENT_INFORMATION
        || lastStepU === LocationSteps.CONTACT
        || lastStepU === LocationSteps.HOURS
        || lastStepU === LocationSteps.PHOTOS
        || lastStepU === LocationSteps.ADDITIONAL_INFO) {
        return true;
      }
      break;
    default:
      return false;
  }

  return false;
};

export const urlValidationRegex = /^(https?:\/\/|www\.)[a-zA-Z0-9\-._~%!$&'()*+,;=:@]+(\.[a-zA-Z]{2,})(:[0-9]{1,5})?(\/[a-zA-Z0-9\-._~%!$&'()*+,;=:@\/?]*)?$/
