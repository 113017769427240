import { useSelector } from 'react-redux';
import { flattenMenu, selectNavigationMenu } from 'services/navigationSlice';
import { useParams } from 'react-router-dom';
import { Urls } from 'navigation/Urls';

interface TopnavMenuMethods {
  nextStep: () => string
  previousStep: () => string
}

function getLastStep(menu) {
  let lastStep = null;

  function traverse(steps) {
    steps?.forEach(step => {
      if (step.subSteps && step.subSteps.length > 0) {
        traverse(step.subSteps);
      } else {
        lastStep = step;
      }
    });
  }
  traverse(menu?.subSteps);
  return lastStep;
}

export function useTopnavMenu(menuUrl: string) : TopnavMenuMethods {
  const { orderItemId } = useParams();
  const menu = useSelector(selectNavigationMenu);
  const flatMenu = flattenMenu(menu).filter(step => step.url !== '');
  const getOrderItemBasedIndex = () => flatMenu.findIndex(step => step.url === menuUrl
      && step.builtFor.toString() === orderItemId);
  const getUrlBasedIndex = () => flatMenu.findIndex(step => step.url === menuUrl);
  const index = orderItemId ? getOrderItemBasedIndex() : getUrlBasedIndex();
  const nextStep = () => {
    const nextStepUrl = flatMenu[index + 1]?.url;
    if (orderItemId) {
      const menuIndex = menu.findIndex(item => item.builtFor?.toString() === orderItemId);
      if (getLastStep(menu[menuIndex])?.url === menuUrl) {
        return Urls.ProductMoveForward;
      }
    }

    return nextStepUrl;
  };
  const previousStep = () => {
    const prevStepUrl = flatMenu[index - 1]?.url;
    if (orderItemId) {
      const menuIndex = menu.findIndex(item => item.builtFor?.toString() === orderItemId);
      const activeStepIndex = menu[menuIndex]?.subSteps.findIndex(item => item.url === menuUrl);
      if (activeStepIndex === 0 || getLastStep(menu[menuIndex])?.url === prevStepUrl) {
        return Urls.ProductMoveBackward;
      }
    }

    return prevStepUrl;
  };
  return {
    nextStep, previousStep,
  };
}
