import { Urls } from 'navigation/Urls';
import { MenuType } from 'services/navigationSlice';
import { TopNavStatusEnum } from 'services/TopNavStatusEnum';

export const xmoMenu: MenuType = ({
  label: 'XMO',
  url: '',
  status: 'incomplete',
  offeringType: 'XMO',
  subSteps: [
    {
      label: 'General Questions',
      url: Urls.GeneralQuestions,
      status: 'incomplete',
    },
    {
      label: 'Details',
      url: Urls.XmoDetails,
      status: 'incomplete',
    },
    {
      label: 'Solution Strategy',
      url: '',
      status: 'incomplete',
      subSteps: [
        {
          id: 0,
          url: Urls.XmoStrategyGeneral,
          name: 'General',
          status: TopNavStatusEnum.NotStarted,
        },
        {
          id: 1,
          url: Urls.XmoStrategyGoal,
          name: 'Goal',
          status: TopNavStatusEnum.NotStarted,
        },
        {
          id: 2,
          url: Urls.XmoStrategyTargeting,
          name: 'Targeting',
          status: TopNavStatusEnum.NotStarted,
        },
        {
          id: 3,
          url: Urls.XmoStrategyProxy,
          name: 'Proxy/Provisioning',
          status: TopNavStatusEnum.NotStarted,
        },
      ],
    },
    {
      label: 'SEM',
      url: Urls.XmoSem,
      status: 'incomplete',
    },
    {
      label: 'Social Smart Optimization',
      url: Urls.XmoSocialSmartOptimization,
      status: 'incomplete',
    },
    {
      label: 'Website Clicks',
      url: Urls.XmoWebsiteClicks,
      status: 'incomplete',
    },
    {
      label: 'Lead Ads',
      url: Urls.XmoLeadAds,
      status: 'incomplete',
    },
    {
      label: 'Retargeting',
      url: Urls.XmoRetargeting,
      status: 'incomplete',
    },
    {
      label: 'Chat',
      url: Urls.XmoChat,
      status: 'incomplete',
    },
    {
      label: 'YouTube',
      url: Urls.XmoYouTube,
      status: 'incomplete',
    },
    {
      label: 'Reach',
      url: Urls.XmoReach,
      status: 'incomplete',
    },
    {
      label: 'Targeted Display',
      url: Urls.XmoTargetedDisplay,
      status: 'incomplete',
    },
    {
      label: 'Snapchat',
      url: Urls.XmoSnapchat,
      status: 'incomplete',
    },
  ],
});
