import { useSelector } from 'react-redux';
import { IQButtonLink, IQButtonRadio, IQTheme } from '@gannettdigital/shared-react-components';
import {
  Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, Typography, IconButton, Collapse, useTheme, TableSortLabel,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState } from 'react';
import { selectBusinessSearch } from 'services/businessSlice';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Business, SortOrder } from 'models/BusinessType';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { Urls } from 'navigation/Urls';
import { backgroundDefaultColor } from 'styles/common_styles';

interface Props {
  onBusinessSelect: (business: Business) => void
  order?: SortOrder
  orderBy?: string
  onSort: (property: string, direction: SortOrder) => void
  isCustomProduct?: boolean
}

interface RowProps {
  row: Business;
  className: string;
  selected: boolean;
  onSelect: () => void;
}

const columns = ['gmaid', 'businessName', 'city'];

const BusinessRow = (props: RowProps) => {
  const {
    row, className, selected, onSelect,
  } = props;
  const [open, setOpen] = useState(false);
  const noBorderStyle = {
    '& > *': { borderBottom: 'unset !important' },
  };
  const expandable = row.businessLocation.length > 1;
  const locationDisplay = (row: Business) => {
    if (row.city && !row.stateProvince) {
      return row.city;
    }

    if (!row.city && row.stateProvince) {
      return row.stateProvince;
    }

    if (row.city && row.stateProvince) {
      return `${row.city}, ${row.stateProvince}`;
    }

    return '';
  };

  return (
    <>
      <TableRow
        className={className}
        sx={noBorderStyle}
      >
        <TableCell className='business-search-result-row-select'>
          <IQButtonRadio
            theme={IQTheme}
            value={row.id.toString()}
            field="selectedBusiness"
            label={row.gmaid}
            selected={selected}
            onClick={onSelect}
          />
        </TableCell>
        <TableCell>
          <Typography>{row.businessName}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{locationDisplay(row)}</Typography>
        </TableCell>
        <TableCell>
          {expandable ? (
            <IconButton
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          ) : null}
        </TableCell>
      </TableRow>
      {expandable ? (
        <TableRow className={className} sx={noBorderStyle}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              {row.businessLocation.map(location => (
                <Grid container key={location.id}>
                  <Grid item xs={4}>
                    <Typography>{location.locationName}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>{`${location.city}, ${location.stateProvince}`}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>{location.businessPhoneNumber}</Typography>
                  </Grid>
                </Grid>
              ))}
            </Collapse>
          </TableCell>
        </TableRow>
      ) : null}
    </>
  );
};

export default function BusinessSearchResults(props: Props) {
  const { t } = useTranslation();
  const {
    order, orderBy, onBusinessSelect, onSort, isCustomProduct,
  } = props;
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rowCount, setRowCount] = useState(-1);
  const businesses = useSelector(selectBusinessSearch);
  const theme = useTheme();
  const navigate = useNavigationHandler();
  const [selectedBusiness, setSelectedBusiness] = useState(-1);
  const getClassName = (row, index) => {
    let className = '';

    if (row.id === selectedBusiness) {
      className = 'selected';
    } else if (index % 2 === 0) {
      className = 'even';
    }

    return className;
  };

  const createSortHandler = (property) => () => {
    const isAsc = orderBy === property && order === 'asc';
    onSort(property, isAsc ? 'desc' : 'asc');
  };

  const handleNewBusinessClick = () => {
    navigate.to(Urls.BusinessAddNew);
  };

  const handleBusinessSelect = (business: Business) => {
    setSelectedBusiness(business ? business.id : 0);
    onBusinessSelect(business);
  };

  useEffect(() => {
    if (businesses.content.length > 0) {
      setRowCount(businesses.totalElements);
      setRows(businesses.content);
    }
    setLoading(false);
  }, [businesses]);

  return (!loading
    && (
    <Grid className='business-search-result-container' item xs={12}>
      { rowCount > 0
        ? (
          <TableContainer component={Paper} sx={{ marginTop: '50px' }}>
            <Table>
              <TableHead sx={{ fontWeight: 'bold' }}>
                <TableRow>
                  {columns.map(col => (
                    <TableCell key={col} sortDirection={orderBy === col ? order : false}>
                      <TableSortLabel
                        active={orderBy === col}
                        direction={orderBy === col ? order : 'asc'}
                        onClick={createSortHandler(col)}
                      >
                        {t(`pages.business.search.columns.${col}`)}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                  <TableCell />
                </TableRow>
              </TableHead>

              <TableBody
                sx={{
                  '.even': { backgroundColor: backgroundDefaultColor },
                  '.selected': { backgroundColor: theme.palette.primary.light },
                }}
              >
                {rows.map((row, index) => (
                  <BusinessRow className={getClassName(row, index)} key={row.id} row={row}
                    selected={row.id === selectedBusiness} onSelect={() => handleBusinessSelect(row)}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )
        : (<Typography className='business-search-noresult' fontWeight="bold">{t('pages.business.search.noResults')}</Typography>)}
      {isCustomProduct && (
        <Grid container
          direction="row"
          alignItems="center"
        >
          <Typography>{t('pages.business.search.cantFindBusiness')}</Typography>
          <IQButtonLink startIcon={<AddIcon />} onClick={handleNewBusinessClick}>
            {t('pages.business.search.addNewBusiness')}
          </IQButtonLink>
        </Grid>
      )}
    </Grid>
    )
  );
}
