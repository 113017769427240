import { FormProvider, useForm } from 'react-hook-form';
import {
  ValidationProvider,
  IQInputEmail,
  IQCheckbox,
  IQFormInput,
  IQTheme,
} from '@gannettdigital/shared-react-components';
import GridContainer from 'components/grid/GridContainer';
import GridItem from 'components/grid/GridItem';
import { Typography } from '@mui/material';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useTranslation } from 'react-i18next';
import ClientPhones, { defaultNumber, matchCountryCode } from 'pages/business/client/ClientPhones';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBusinessLocation,
  selectCurrentLocation,
  updateContactInformation,
} from 'services/businessLocationSlice';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { getOrderBusinessByOrderId, selectOrderBusiness } from 'services/businessSlice';
import useMapboxService from 'services/MapboxService';
import { selectOrdersContent } from 'services/ordersSlice';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import { RootState } from 'services/redux/store-schedule';
import { selectPlaceDetail } from 'services/placeDetailSlice';
import { PhoneType } from 'pages/business/client/ClientType';
import { locationsTypes } from 'layouts/StepperLayout';
import { TitleWithBadge } from 'pages/premium-listings/TitleWithBadge';
import { OrderFlow } from 'shared/constants';
import ExistingBusinessListings from './ExistingBusinessListings';
import { phoneTypes } from './LocationContact.data';
import SocialMediaAccounts from './SocialMediaAccounts';

const US_CODE = '+1';

export default function LocationContact(props: any) {
  const { type, schema, defaultValues } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { orderId, locationId } = useParams();
  const location = useSelector(selectCurrentLocation);
  const business = useSelector(selectOrderBusiness);
  const order = useSelector(selectOrdersContent);
  const [countryCode, setCountryCode] = useState(null);
  const history: any = useLocation();
  const isRedirectedFromOverviewPage = useMemo(() => history.state?.previousPath.includes('overview'), [history]);
  const savedPlaceDetail = useSelector((state: RootState) => selectPlaceDetail(state));

  const isStandard = type === locationsTypes.STANDARD;
  const isPl = ([locationsTypes.PREMIUM_LISTINGS, locationsTypes.HEALTHCARE].includes(type) && !location?.isProvider);
  const isHc = (type === locationsTypes.HEALTHCARE && location?.isProvider);
  const ordersContent = useSelector(selectOrdersContent);
  const { orderFlow } = ordersContent;
  const [isPunchlist, setIsPunchlist] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    orderFlow === OrderFlow.PUNCHLIST ? setIsPunchlist(true) : setIsPunchlist(false);
  }, []);

  const {
    countryNamesConversion, twoLettersCountryEquivalent,
  } = useMapboxService();

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      ...defaultValues,
    },
  });

  const {
    handleSubmit, getValues, setValue, formState: {
      isValid, isDirty, isSubmitSuccessful, isSubmitting,
    },
  } = methods;

  const shapeSocialAccounts = (obj) => {
    const b = business.content;
    const socialsNoFb = [
      {
        name: 'Twitter',
        enabled: !!obj.twitterUrl,
        applyToAll: b.sameTwitterUrl,
        url: obj.twitterUrl || '',
      },
      {
        name: 'Instagram',
        enabled: !!obj.instagramUrl,
        applyToAll: b.sameInstagramUrl,
        url: obj.instagramUrl || '',
      },
      {
        name: 'YouTube',
        enabled: !!obj.youtubeUrl,
        applyToAll: b.sameYoutubeUrl,
        url: obj.youtubeUrl || '',
      },
      {
        name: 'Vimeo',
        enabled: !!obj.vimeoUrl,
        applyToAll: b.sameVimeoUrl,
        url: obj.vimeoUrl || '',
      },
      {
        name: 'LinkedIn',
        enabled: !!obj.linkedinUrl,
        applyToAll: b.sameLinkedinUrl,
        url: obj.linkedinUrl || '',
      },
      {
        name: 'Pinterest',
        enabled: !!obj.pinterestUrl,
        applyToAll: b.samePinterestUrl,
        url: obj.pinterestUrl || '',
      },
    ];

    const socialsWithFb = [
      {
        name: 'Facebook',
        enabled: !!obj.facebookUrl,
        applyToAll: b.sameFacebookUrl,
        url: obj.facebookUrl || '',
      },
      ...socialsNoFb,
    ];

    const socials = isStandard ? socialsWithFb : socialsNoFb;

    setValue('accounts', socials);
    socials?.forEach((account, index) => setValue(`accounts.${index}`, account));
  };

  const getCountry = (location) => {
    const defCountry = matchCountryCode[order?.country] || 'US';
    if (!location) return defCountry;
    const locationLength = location?.length;
    switch (locationLength) {
      case 2:
        return location;
      case 3:
        return twoLettersCountryEquivalent[location];
      default:
        return countryNamesConversion[location] || 'US';
    }
  };

  useEffect(() => {
    setCountryCode(getCountry(location.country));
  }, [location]);

  useEffect(() => {
    if (location && business.content) {
      if (location.socials?.length) shapeSocialAccounts(location.socials[0]);
      setValue('locationEmail', location.locationEmail || '');
      if (!isHc) {
        setValue('locationWebsiteSameForAll', location.locationWebsiteSameForAll || false);
        setValue('locationEmailSameForAll', location.locationEmailSameForAll || false);
      }
      if (isPl || isHc) {
        setValue('facebookYesNo', location.hasFacebookListing);
        setValue('facebookListingsUrl', location.facebookListingUrl);
        setValue('googleYesNo', location.hasGmbListing);
        setValue('googleListingsUrl', location.gmbListingUrl);
      }
      if (location.phoneNumbers && location.phoneNumbers.length > 0) {
        if (savedPlaceDetail.placeDetail) {
          setValue('locationWebsite', savedPlaceDetail.placeDetail.website || '');
          setValue('phoneNumbers', [
            {
              phoneType: PhoneType.BUSINESS,
              phoneNumber: `${US_CODE} ${savedPlaceDetail.placeDetail.phoneNumber}`,
              ext: '',
            },
          ]);
        } else {
          setValue('locationWebsite', location.locationWebsite || '');
          setValue(
            'phoneNumbers',
            location.phoneNumbers.length > 0 ? location.phoneNumbers : [defaultNumber],
          );
        }
        if (location.phoneNumbers.length > 1) {
          location.phoneNumbers.forEach((phone, index) => {
            setValue(
              `phoneNumbers.${index}`,
              { phoneNumber: phone.phoneNumber, phoneType: phone.phoneType, ext: phone.ext },
            );
            setValue(`phoneNumbers.${index}.phoneNumber`, phone.phoneNumber);
            setValue(`phoneNumbers.${index}.phoneType`, phone.phoneType);
            setValue(`phoneNumbers.${index}.ext`, phone.ext);
          });
        }
      } else if (savedPlaceDetail.placeDetail) {
        setValue('locationWebsite', savedPlaceDetail.placeDetail.website || '');
        setValue('phoneNumbers', [
          {
            phoneType: PhoneType.BUSINESS,
            phoneNumber: `${US_CODE} ${savedPlaceDetail.placeDetail.phoneNumber}`,
            ext: '',
          },
        ]);
      }
    }
  }, [location, business.content]);

  useEffect(() => {
    dispatch(getOrderBusinessByOrderId(orderId));
  }, [orderId]);

  useEffect(() => {
    dispatch(getBusinessLocation(locationId));
  }, [locationId]);

  const onFormSubmit = async () => {
    const values = getValues();
    const fb = isStandard ? values.accounts.find(acc => acc.name === 'Facebook') : null;
    const tw = values.accounts.find(acc => acc.name === 'Twitter');
    const ig = values.accounts.find(acc => acc.name === 'Instagram');
    const lin = values.accounts.find(acc => acc.name === 'LinkedIn');
    const pt = values.accounts.find(acc => acc.name === 'Pinterest');
    const vm = values.accounts.find(acc => acc.name === 'Vimeo');
    const yt = values.accounts.find(acc => acc.name === 'YouTube');

    const standardResource = {
      businessSocialRequest: {
        facebookUrlSameForAll: fb?.enabled ? fb.applyToAll : false,
        facebookUrl: fb?.enabled ? fb.url : '',
        instagramUrl: ig.enabled ? ig.url : '',
        instagramUrlSameForAll: ig.enabled ? ig.applyToAll : false,
        linkedinUrl: lin.enabled ? lin.url : '',
        linkedinUrlSameForAll: lin.enabled ? lin.applyToAll : false,
        pinterestUrl: pt.enabled ? pt.url : '',
        pinterestUrlSameForAll: pt.enabled ? pt.applyToAll : false,
        twitterUrl: tw.enabled ? tw.url : '',
        twitterUrlSameForAll: tw.enabled ? tw.applyToAll : false,
        vimeoUrl: vm.enabled ? vm.url : '',
        vimeoUrlSameForAll: vm.enabled ? vm.applyToAll : false,
        youtubeUrl: yt.enabled ? yt.url : '',
        youtubeUrlSameForAll: yt.enabled ? yt.applyToAll : false,
      },
      email: values.locationEmail,
      emailSameForAll: values.locationEmailSameForAll,
      facebookListingUrl: '',
      gmbListingUrl: '',
      hasFacebookListing: '',
      hasGmbListing: '',
      phoneNumbers: values.phoneNumbers.map(phone => (
        { phoneNumber: phone.phoneNumber, phoneType: phone.phoneType, ext: phone.ext })),
      website: values.locationWebsite,
      websiteSameForAll: values.locationWebsiteSameForAll,
    };

    const plResource = {
      businessSocialRequest: {
        instagramUrl: ig.enabled ? ig.url : '',
        instagramUrlSameForAll: ig.enabled ? ig.applyToAll : false,
        linkedinUrl: lin.enabled ? lin.url : '',
        linkedinUrlSameForAll: lin.enabled ? lin.applyToAll : false,
        pinterestUrl: pt.enabled ? pt.url : '',
        pinterestUrlSameForAll: pt.enabled ? pt.applyToAll : false,
        twitterUrl: tw.enabled ? tw.url : '',
        twitterUrlSameForAll: tw.enabled ? tw.applyToAll : false,
        vimeoUrl: vm.enabled ? vm.url : '',
        vimeoUrlSameForAll: vm.enabled ? vm.applyToAll : false,
        youtubeUrl: yt.enabled ? yt.url : '',
        youtubeUrlSameForAll: yt.enabled ? yt.applyToAll : false,
      },
      email: values.locationEmail,
      emailSameForAll: values.locationEmailSameForAll,
      facebookListingUrl: values.facebookYesNo === 'yes' ? values.facebookListingsUrl : '',
      gmbListingUrl: values.googleYesNo === 'yes' ? values.googleListingsUrl : '',
      hasFacebookListing: values.facebookYesNo,
      hasGmbListing: values.googleYesNo,
      phoneNumbers: values.phoneNumbers.map(phone => (
        { phoneNumber: phone.phoneNumber, phoneType: phone.phoneType, ext: phone.ext })),
      website: values.locationWebsite,
      websiteSameForAll: values.locationWebsiteSameForAll,
    };

    const hcResource = {
      businessSocialRequest: {
        facebookUrl: values.facebookYesNo === 'yes' ? values.facebookListingsUrl : '',
        instagramUrl: ig.enabled ? ig.url : '',
        linkedinUrl: lin.enabled ? lin.url : '',
        pinterestUrl: pt.enabled ? pt.url : '',
        twitterUrl: tw.enabled ? tw.url : '',
        vimeoUrl: vm.enabled ? vm.url : '',
        youtubeUrl: yt.enabled ? yt.url : '',
      },
      email: values.locationEmail,
      emailSameForAll: false,
      facebookListingUrl: values.facebookYesNo === 'yes' ? values.facebookListingsUrl : '',
      gmbListingUrl: values.googleYesNo === 'yes' ? values.googleListingsUrl : '',
      hasFacebookListing: values.facebookYesNo,
      hasGmbListing: values.googleYesNo,
      phoneNumbers: values.phoneNumbers.map(phone => (
        { phoneNumber: phone.phoneNumber, phoneType: phone.phoneType, ext: phone.ext })),
      website: values.locationWebsite,
      websiteSameForAll: false,
    };

    const resource = () => {
      if (isPl) return plResource;
      if (isHc) return hcResource;
      return standardResource;
    };

    await dispatch(updateContactInformation({ locationId, resource: resource() }));
    props.onContinue();
  };

  useEffect(() => {
    props.onUpdateStatus(getValues(), isValid);
  }, [isValid]);

  return (
    <ValidationProvider schema={schema}>
      <FormProvider {...methods}>
        <form id={props.formId} onSubmit={handleSubmit(onFormSubmit)}>
          <GridContainer fullWidth>
            <GridItem marginBottom={0}>
              <Typography variant="overline" marginBottom={0}
                sx={{ textTransform: 'initial', fontSize: '18px', color: 'text.secondary' }}
              >
                Locations
              </Typography>
            </GridItem>
            <GridItem>
              <Typography variant="h2" marginBottom={3}>{t('pages.business.location.contact.title')}</Typography>
              {isStandard ? (
                <Typography variant="h6">{t('pages.business.location.contact.details.title')}</Typography>
              ) : (
                <TitleWithBadge
                  title={t('pages.premiumListings.location.contact.details.title')}
                  badge={t('pages.premiumListings.badge')}
                  tooltip={t('pages.premiumListings.badgeTooltip')}
                />
              )}
            </GridItem>
            <GridItem sizes={[12]}>
              <GridContainer fullWidth rowSpacing={3}>
                {countryCode !== null && (
                <ClientPhones
                  getValues={getValues}
                  addPhoneButtonLabel={t('pages.business.location.contact.details.addNumber')}
                  removePhoneDialogTitle="pages.business.location.contact.details.removeNumberTitle"
                  phoneTypes={phoneTypes}
                  fieldName="phoneNumbers"
                  country={countryCode}
                />
                )}
              </GridContainer>
            </GridItem>
            <GridItem sizes={[12]}>
              {isStandard ? (
                <IQFormInput
                  id="locationWebsite"
                  theme={IQTheme}
                  labelText={t('pages.business.location.contact.details.websiteUrl')}
                  fontLabelWeight="600"
                  name="locationWebsite"
                  fullWidth
                  customValidation={schema.yupValidations.locationWebsite}
                  adornmentIcon={<InsertLinkIcon />}
                />
              ) : (
                <IQFormInput
                  id="locationWebsite"
                  theme={IQTheme}
                  labelText={t('pages.premiumListings.location.contact.details.websiteUrl')}
                  fontLabelWeight="600"
                  name="locationWebsite"
                  fullWidth
                  customValidation={schema.yupValidations.locationWebsite}
                  adornmentIcon={<InsertLinkIcon />}
                  tooltipText={t('pages.premiumListings.location.contact.details.newWebsiteUrlTooltip')}
                  tooltipPaddingBottom={18}
                  tooltipPlacement="top"
                />
              )}
            </GridItem>
            {((isStandard || isPl) && !isPunchlist) && (
              <GridItem sizes={[12]}>
                <IQCheckbox
                  label={t('pages.business.location.contact.details.applyWebsiteToAll')}
                  field="locationWebsiteSameForAll"
                  name="locationWebsiteSameForAll"
                />
              </GridItem>
            )}
            <GridItem sizes={[12]}>
              {isStandard ? (
                <IQInputEmail
                  label={t('pages.business.location.contact.details.email')}
                  fontLabelWeight="600"
                  field="locationEmail"
                  fullWidth
                  adornmentIcon={<MailOutlineIcon />}
                />
              ) : (
                <IQInputEmail
                  label={t('pages.premiumListings.location.contact.details.email')}
                  fontLabelWeight="600"
                  field="locationEmail"
                  fullWidth
                  adornmentIcon={<MailOutlineIcon />}
                  tooltipText={t('pages.premiumListings.location.contact.details.emailTooltip')}
                  tooltipPaddingBottom={18}
                  tooltipPlacement="top"
                />
              )}
            </GridItem>
            {((isStandard || isPl) && !isPunchlist) && (
              <GridItem sizes={[12]}>
                <IQCheckbox
                  label={t('pages.business.location.contact.details.applyEmailToAll')}
                  field="locationEmailSameForAll"
                  name="locationEmailSameForAll"
                />
              </GridItem>
            )}
            {(isPl || isHc) && (
              <GridItem sizes={[12]}>
                <ExistingBusinessListings />
              </GridItem>
            )}
            <GridItem />
            <GridItem sizes={[12]}>
              <SocialMediaAccounts showApplyToAll={!isPunchlist} title={isStandard ? (
                <Typography variant="h6">
                  {t('pages.business.location.contact.details.socialMediaAccounts.title')}
                </Typography>
              ) : (
                <TitleWithBadge
                  title={t('pages.premiumListings.location.contact.details.socialMediaAccounts.title')}
                  badge={t('pages.premiumListings.badge')}
                  tooltip={t('pages.premiumListings.badgeTooltip')}
                />
              )}
              />
            </GridItem>
          </GridContainer>
        </form>
        <CoNavigationConfirm
          showDialog={isDirty && !(isSubmitSuccessful || isSubmitting) && isRedirectedFromOverviewPage}
        />
      </FormProvider>
    </ValidationProvider>
  );
}
