import {
  useState, useCallback, useEffect, useMemo,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Adornment,
  IQFormInput,
  IQFormSelect,
  IQTheme,
  ValidationProvider,
} from '@gannettdigital/shared-react-components';
import styled from '@emotion/styled';
import SearchIcon from '@mui/icons-material/Search';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import ContentLoader from 'components/contentLoader/ContentLoader';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import {
  searchBusinesses, searchBusinessesMP, selectBusinessSearch, selectBusinessSearchStatus, selectOrderBusiness,
} from 'services/businessSlice';
import debounce from 'lodash.debounce';
import { Business, SortOrder } from 'models/BusinessType';
import {
  startOrder, selectOrderItems, selectOrdersContent, updateOrderFlow,
} from 'services/ordersSlice';
import { useParams } from 'react-router';
import {
  getCaseDetails, getCurrentOffer, getSalesforceContact, selectCurrentOffer,
  selectOpportunity,
} from 'services/cefSlice';
import { useSearchParams } from 'react-router-dom';
import { OrderFlow } from 'shared/constants';
import { createClient, getClients, selectClients } from 'services/clientsSlice';
import { allCountries, selectCurrentCountry } from 'services/topNavMenuSlice';
import { useAppDispatch } from 'services/redux/store';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { Urls } from 'navigation/Urls';
import schema from './BusinessSearch.schema';
import { BusinessFunctions } from './BusinessFunctions';
import BusinessSearchResults from './BusinessSearchResults';
import VerifyBusinessModal from './VerifyBusinessModal';
import AddonsUrlConfirmation from './AddonsUrlConfirmation';
import { PhoneType } from '../client/ClientType';

interface Props {
  onBusinessSelected: (business: Business) => any;
}

const CountriesDropdown = styled(IQFormSelect)`
  background-color: white;
  margin-right: 16px;
  border-radius: 2px;
`;

export default function BusinessSearchForm(props: Props) {
  const { t } = useTranslation();
  const navigate = useNavigationHandler();
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const { orderId } = useParams();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const status = useSelector(selectBusinessSearchStatus);
  const businessSearch = useSelector(selectBusinessSearch);
  const orderContent = useSelector(selectOrdersContent);
  const { content: orderItems } = useSelector(selectOrderItems);
  const [loading, setLoading] = useState(true);
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [order, setOrder] = useState<SortOrder>('desc');
  const [orderBy, setOrderBy] = useState('id');
  const [businessName, setBusinessName] = useState('');
  const currentBusiness = useSelector(selectOrderBusiness);
  const clients = useSelector(selectClients);
  const [selectedBusiness, setSelectedeBusiness] = useState(currentBusiness.content);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isStandaloneAddon, setIsStandaloneAddon] = useState(false);
  const [isCustomProduct, setIsCustomProduct] = useState(false);
  const [showAddonsModal, setShowAddonsModal] = useState(false);
  const [isSalesforce, setIsSalesforce] = useState(false);
  const opportunity = useSelector(selectOpportunity);
  const currentOffer = useSelector(selectCurrentOffer);
  const country = useSelector(selectCurrentCountry);

  const debounceDuration = 300;
  const minQueryLength = 0;

  const isBlue = useMemo(() => orderItems?.filter(item => item.isBlue)?.length > 0, [orderItems]);

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      business: null,
      country: 'usa',
    },
  });

  useEffect(() => {
    if (opportunity?.account?.advertiserCountry) {
      const findCountryIndex = allCountries.findIndex((country: any) => opportunity.account.advertiserCountry === country.name);

      if (findCountryIndex === -1) {
        methods.setValue('country', allCountries[0].name.toLowerCase());
      } else {
        methods.setValue('country', allCountries[findCountryIndex].name.toLowerCase());
      }
    }
  }, [opportunity]);

  const {
    getValues, watch, setValue,
  } = methods;

  useEffect(() => {
    if (orderContent?.country) setValue('country', orderContent.country.toLowerCase());
  }, [orderContent]);

  const loadClients = async () => {
    setVerifyLoading(true);
    await dispatch(getClients(orderId));
    setVerifyLoading(false);
  };

  useEffect(() => {
    if (isSalesforce && orderId && clients.length === 0) loadClients();
  }, [isSalesforce]);

  const onPageChange = (_event, newPage) => {
    setPage(newPage);
    dispatch(searchBusinesses({
      businessName,
      country: `${getValues('country').toUpperCase()}`,
      page: newPage,
      pageSize,
      sort: `${orderBy},${order}`,
    }));
  };

  const onPageSizeChange = (event) => {
    const size = event.target.value;
    setPage(0);
    setPageSize(size);
    dispatch(searchBusinesses({
      businessName,
      country: `${getValues('country').toUpperCase()}`,
      page: 0,
      pageSize: size,
      sort: `${orderBy},${order}`,
    }));
  };

  const onSort = (property, direction) => {
    setOrder(direction);
    setOrderBy(property);
    dispatch(searchBusinesses({
      businessName, country: `${getValues('country').toUpperCase()}`, page, pageSize, sort: `${property},${direction}`,
    }));
  };

  const handlePhoneCountryCode = (phone) => (country.name === 'USA' ? `+1${phone}` : phone);

  const loadBlueCaseDetails = async () => {
    if (isBlue && Object.keys(currentOffer).length > 0) {
      setVerifyLoading(true);
      await dispatch(getCaseDetails(currentOffer?.salesForceOfferObject?.opportunity));
      setVerifyLoading(false);
    }
  };

  useEffect(() => {
    loadBlueCaseDetails();
  }, [currentOffer, isBlue]);

  const createSalesforceClient = async (opportunityId) => {
    const opptId = !isBlue ? opportunityId : opportunity?.opportunity?.id;
    await appDispatch(getSalesforceContact({ opportunityId: opptId, isBlue }))
      .then(async (res) => {
        const contactData = res?.payload?.data;
        const hasEmail = contactData?.email && contactData?.email !== 'null';
        const hasPhone = contactData?.mobilePhone && contactData?.mobilePhone !== 'null';

        const contact = {
          contact: {
            id: null,
            businessRoleId: null,
            clientFullName: contactData?.name || '',
            email: hasEmail ? contactData.email : '',
            firstName: '',
            lastName: '',
            sourceId: null,
          },
          contactEmailList: hasEmail ? [contactData.email] : [],
          contactPhoneNumberList: [{
            phoneNumber: hasPhone
              ? handlePhoneCountryCode(contactData.mobilePhone)
              : '',
            phoneType: hasPhone ? PhoneType.MOBILE : '',
            ext: '',
          }],
        };
        await dispatch(createClient({ orderId, contact }));
      });
  };

  const onBusinessSelect = async (id) => {
    setSelectedeBusiness(id);
  };

  const handleContinue = () => {
    setShowVerifyModal(true);
  };

  const handleBack = () => navigate.to(Urls.SalesRep);

  const onVerify = async () => {
    if (Object.keys(currentOffer).length > 0) {
      setVerifyLoading(true);
      if (clients.length === 0) await createSalesforceClient(currentOffer?.salesForceOfferObject?.opportunity);
      setVerifyLoading(false);
    }

    setShowVerifyModal(false);

    props.onBusinessSelected(selectedBusiness);
  };

  const onCancel = () => {
    setShowVerifyModal(false);
  };

  const onAddonsCancel = () => {
    setShowAddonsModal(false);
  };

  const gridProps = {
    page, pageSize, onPageChange, onPageSizeChange, onBusinessSelect, onSort, order, orderBy,
  };

  const searchBusiensses = (name) => {
    setSelectedeBusiness({} as Business);
    setPage(0);
    setPageSize(10);
    setIsSearching(true);
    setBusinessName(name);
    dispatch(searchBusinessesMP({
      query: name,
      country: `${getValues('country').toUpperCase()}`,
    }));
    setIsSearching(false);
  };

  const onTextSearch = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const query = event.target.value;
    if (query && query.length >= minQueryLength) {
      searchBusiensses(query);
    }
    setLoading(false);
  };

  const debouncedChangeHandler = useCallback(
    debounce(onTextSearch, debounceDuration),
    [],
  );

  const setBusinessFromSalesforce = async (gmaid) => {
    setIsSalesforce(true);
    setShowVerifyModal(true);
    await searchBusiensses(gmaid);
  };

  useEffect(() => {
    if (Object.keys(currentOffer).length > 0) {
      dispatch(updateOrderFlow(OrderFlow.SALES_FORCE));
      setBusinessFromSalesforce(currentOffer?.salesForceOfferObject?.gmaid);
    }
  }, [currentOffer]);

  useEffect(() => {
    if (businessSearch?.content?.length === 1 && isSalesforce) {
      setSelectedeBusiness(businessSearch.content[0]);
    }
  }, [businessSearch]);

  useEffect(() => {
    const query = getValues('business');
    if (query !== null && query !== '') searchBusiensses(query);
  }, [watch('country')]);

  useEffect(() => {
    if (orderItems.length > 0) {
      const salesforceItems = orderItems.filter(item => item.salesForceOfferId);
      if (salesforceItems.length > 0) {
        const itemId = salesforceItems[0].id;
        dispatch(updateOrderFlow(OrderFlow.SALES_FORCE));
        setShowVerifyModal(true);
        if (Object.keys(currentOffer).length === 0) dispatch(getCurrentOffer(itemId));
      }
      if (!(orderItems.some(item => ['Website', 'GoldSite'].includes(item.offeringType)))
       && orderItems.some(item => ['MasterAddon'].includes(item.offeringType))) {
        setIsStandaloneAddon(true);
      }

      if (orderItems.some(item => ['CustomWebsite', 'CustomLandingPage'].includes(item.offeringType))) {
        setIsCustomProduct(true);
      }
    }
  }, [orderItems]);

  return (
    <DefaultPageLayout
      disableContinue={Object.keys(selectedBusiness).length === 0}
      onBack={handleBack}
      onContinue={handleContinue}
      header={t('pages.business.search.title')}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <Grid container>
            <Grid item xs={3}>
              <CountriesDropdown
                className="country"
                fullWidth
                theme={IQTheme}
                name="country"
                items={BusinessFunctions.getCountries()}
                labelText={t('pages.business.search.countryLabel')}
                fontLabelWeight="bold"
                defaultValue="usa"
              />
            </Grid>
            <Grid item xs={9}>
              <IQFormInput
                fullWidth
                theme={IQTheme}
                name="business"
                id="business"
                labelText={t('pages.business.search.businessLabel')}
                placeholder={t('pages.business.search.businessPlaceholder')}
                fontLabelWeight="bold"
                schema={schema}
                adornmentIcon={<SearchIcon />}
                adornmentPosition={Adornment.START_ADORNMENT}
                onChange={debouncedChangeHandler}
              />
            </Grid>
            { !loading && !isSearching
              ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  minHeight="200px"
                  width="100%"
                >
                  <ContentLoader status={status} message="">
                    <BusinessSearchResults {... gridProps} isCustomProduct={isCustomProduct} />
                  </ContentLoader>
                </Box>
              ) : null }
            <VerifyBusinessModal
              open={showVerifyModal}
              business={selectedBusiness}
              onConfirm={onVerify}
              onCancel={onCancel}
              isSalesforce={isSalesforce}
              isLoading={verifyLoading}
            />
            <AddonsUrlConfirmation
              open={isStandaloneAddon && showAddonsModal}
              onCancel={onAddonsCancel}
            />
          </Grid>
        </FormProvider>
      </ValidationProvider>
    </DefaultPageLayout>
  );
}
