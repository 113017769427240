import React, {useCallback, useEffect, useState} from 'react';
import Papa from 'papaparse';
import AxiosAPI from "services/common/axios";
import {backgroundDefaultColor} from "styles/common_styles";
import {Alert, Box, Grid, Paper, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {DataGrid, GridColDef, GridPaginationModel} from "@mui/x-data-grid";
import {t} from "i18next";
import {
  Adornment,
  IQFormInput,
  IQLoadingSpinner,
  IQTheme
} from "@gannettdigital/shared-react-components";
import SearchIcon from "@mui/icons-material/Search";
import debounce from "lodash.debounce";
import {FormProvider, useForm} from 'react-hook-form';

const GCPPdfUpload = () => {
  const [fileName, setFileName] = useState(t('contractUpload.noFileChosen'));
  const [hoverEnable, setHoverEnable] = useState<boolean>(false);
  const [csvData, setCsvData] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const fileInputRef = React.useRef(null);
  const [pageSize, setPageSize] = useState<number>(100);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [searchResults, setSearchResults] = useState<any>([]);
  const [fetchedResults, setFetchedResults] = useState<any>([]);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) {
      setErrorMessage(t('contractUpload.noFileSelected'));
      setFileName(t('contractUpload.noFileChosen'));
      setCsvData(null);
      return;
    }

    if (file.type !== "text/csv") {
      setErrorMessage(t('contractUpload.pleaseUploadCsvFile'));
      setFileName(t('contractUpload.noFileChosen'));
      setCsvData(null);
      return;
    }

    setFileName(file.name);
    setErrorMessage('');
    setSuccessMessage('');
    Papa.parse(file, {
      header: true,
      complete: (result: any) => {
        const seenIds = new Set();
        const uniqueData = result.data.filter(item => {
          if (!seenIds.has(item.__id)) {
            seenIds.add(item.__id);
            return true;
          }
          return false;
        });

        const formattedData = uniqueData.map(({
                                                __id,
                                                campaign_name,
                                                general_question_submit,
                                                pdf_download_url,
                                                product,
                                                seller_email,
                                                general_info_submit,
                                                seller_name,
                                                account_name,
                                                session_id,
                                                created_timestamp,
                                                complete,
                                                completed_timestamp
                                              }: any) => {
          return {
            hashId: __id,
            campaignName: campaign_name,
            generalQuestionSubmit: general_question_submit,
            pdfDownloadUrl: pdf_download_url,
            product: product,
            sellerEmail: seller_email,
            generalInfoSubmit: general_info_submit,
            sellerName: seller_name,
            accountName: account_name,
            sessionId: session_id,
            createdTimestamp: created_timestamp,
            complete: complete,
            completedTimestamp: completed_timestamp
          }
        });

        setCsvData(formattedData);
      },
      error: (error) => {
        console.error('Error parsing CSV:', error);
      },
    });
  };

  const handleSubmit = async () => {
    if (!csvData) {
      setErrorMessage(t('contractUpload.pleaseSelectValidCsvFile'));
      return;
    }

    setLoading(true);
    try {
      await AxiosAPI.post('contract-upload/save', csvData);
      setFileName(t('contractUpload.noFileChosen'));
      setCsvData(null);
      setErrorMessage('');
      setSuccessMessage(t('contractUpload.fileUploadedSuccessfully'));
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      fetchData();
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage(t('contractUpload.errorUploadingFile'));
      setSuccessMessage('');
    } finally {
      setLoading(false);
    }
  }

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await AxiosAPI.get('contract-upload/get-all-contracts', {
        params: {
          page: currentPage,
          size: pageSize * 2,
          sort: "id,asc",
        }
      });

      const data = response.data;
      setFetchedResults(prevResults => {
        const newResults = updateResults(prevResults, data);
        setTotalRows(newResults.length);
        return newResults;
      });

    } catch (error) {
      console.error("Error: ", error)
    } finally {
      setLoading(false);
    }
  }

  const updateResults = (results, newResults) => {
    const resultsMap = new Map();
    results.forEach(result => resultsMap.set(result.id, result));
    newResults.forEach(newResult => resultsMap.set(newResult.id, newResult));
    return Array.from(resultsMap.values());
  };
  useEffect(() => {
    if (searchQuery && searchQuery.length >= minQueryLength) {
      searchContract(searchQuery);
    } else {
      fetchData();
    }
  }, [currentPage, pageSize, searchQuery]);

  const handlePageChange = (params: GridPaginationModel) => {
    setCurrentPage(params.page);
    setPageSize(params.pageSize);
  };

  const resultColumns: GridColDef[] = [
    {
      field: 'hashId',
      headerName: t('contractUpload.id'),
      width: 320,
    },
    {
      field: 'product',
      headerName: t('contractUpload.product'),
      width: 300,
    },
    {
      field: 'accountName',
      headerName: t('contractUpload.accountName'),
      width: 300,
    },
    {
      field: 'sellerName',
      headerName: t('contractUpload.sellerName'),
      width: 200,
    },
    {
      field: 'sellerEmail',
      headerName: t('contractUpload.sellerEmail'),
      width: 250,
    },
    {
      field: 'status',
      headerName: t('contractUpload.gcpUploadStatus'),
      width: 180,
    },
    {
      field: 'gcpPdfUploadedUrl',
      headerName: t('contractUpload.gcpPdfUploadedUrl'),
      width: 220,
      renderCell: (params) => (
          params.value && <Button
              component="a"
              href={params.value}
              target="_blank"
              variant="contained"
              color="primary"
          >
            {t('contractUpload.downloadContractFile')}
          </Button>
      )
    },
  ];

  const debounceDuration = 300;
  const minQueryLength = 0;

  const searchContract = async (query) => {
    setLoading(true);
    try {
      const response = await AxiosAPI.get('contract-upload/search', {params: {
          page: currentPage,
          size: pageSize * 2,
          searchQuery: query,
        }});

      const data = response.data;
      setSearchResults(prevResults => {
        const newResults = updateResults(prevResults, data);
        setTotalRows(newResults.length);
        return newResults;
      });
    } catch (error) {
      console.error('Error searching Contracts:', error);
    } finally {
      setLoading(false);
    }
  };

  const onTextSearch = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const query = event.target.value;
    setSearchQuery(query)

    if (query && query.length >= minQueryLength) {
      searchContract(query);
    } else {
      fetchData();
    }
  };

  useEffect(() => {
    setSearchResults([])
    setTotalRows(0)
    setCurrentPage(0);
  }, [searchQuery]);

  const debouncedChangeHandler = useCallback(
      debounce(onTextSearch, debounceDuration),
      []
  );

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      business: null,
    },
  });
  const displayResults = searchQuery ? searchResults : fetchedResults;

  return (

      <Paper elevation={0} sx={{backgroundColor: backgroundDefaultColor}}>
        <Grid px={6}>
        <Typography variant="h2" mb={3} px={6}>{t('contractUpload.archivedAirkitPdf')}</Typography>
        {errorMessage && (
            <Alert severity="error" sx={{mb: 2}}>{errorMessage}</Alert>
        )}
        {(successMessage && !errorMessage) && (
            <Alert severity="success"
                   sx={{mb: 2, backgroundColor: 'green', color: 'white'}}>{successMessage}</Alert>
        )}
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100px',
          border: '2px dashed #e0e0e0',
          borderRadius: '10px',
          transition: 'border 0.3s ease',
          backgroundColor: '#f9f9f9',
          position: 'relative',
        }}>
          <input
              type="file"
              id="file-upload"
              accept=".csv"
              ref={fileInputRef}
              onChange={handleFileUpload}
              style={{display: 'none'}}
          />
          <label htmlFor="file-upload" className="file-label">
            <span style={{
              cursor: 'pointer',
              color: '#ffffff',
              padding: '10px 20px',
              borderRadius: '5px',
              marginRight: '10px',
              transition: 'background-color 0.3s ease',
              backgroundColor: hoverEnable ? '#0056b3' : '#007bff'
            }}
                  onMouseEnter={() => setHoverEnable(true)}
                  onMouseLeave={() => setHoverEnable(false)}
            >
              Choose File
            </span>
            <span style={{fontSize: '16px', color: '#555555'}}>{fileName}</span>
          </label>
        </div>

        <Box sx={{display: 'flex', justifyContent: 'flex-start', p: 2, gap: "40%"}}>
          <Button disabled={loading} variant="contained"
                  onClick={handleSubmit}>{t('contractUpload.upload')}</Button>
          {
              loading &&
              < Grid item sx={{display: "flex", justifyContent: "center"}}>
                <IQLoadingSpinner/>
              </Grid>
          }
        </Box>
          <FormProvider {...methods}>
            <Grid container>
              <Grid item xs={3}>
                <IQFormInput
                    fullWidth
                    theme={IQTheme}
                    name="business"
                    id="business"
                    labelText={''}
                    placeholder={t('contractUpload.searchContract')}
                    fontLabelWeight="bold"
                    adornmentIcon={<SearchIcon/>}
                    adornmentPosition={Adornment.START_ADORNMENT}
                    onChange={debouncedChangeHandler}
                />
              </Grid>
            </Grid>
          </FormProvider>
        <Grid mt={2}>
          <DataGrid
              rows={displayResults}
              columns={resultColumns}
              pagination
              rowCount={totalRows}
              pageSizeOptions={[10, 20, 50, 100]}
              paginationModel={{page: currentPage, pageSize: pageSize}}
              onPaginationModelChange={handlePageChange}
          />
        </Grid>
        </Grid>
      </Paper>

  );
};

export default GCPPdfUpload;