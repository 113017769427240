import * as yup from 'yup';
import i18n from '../../../i18n/i18n';

const {t} = i18n;

const TacticsFormDialogSchema = {
  /*Tactics info*/
  /*common fields*/
  tacticType: yup.string().required(t('pages.xmo.targetedDisplay.campaignInfo.campaignGoalsError')).nullable(),
  specificProductOrService: yup.string().required(t('pages.xmo.targetedDisplay.tactics.specificProductOrServiceError')).nullable(),
  tacticNameInClientReporting: yup.string().required(t('pages.xmo.targetedDisplay.tactics.clientReportingTacticError')).nullable(),
  uniqueLandingPage: yup.string().notRequired().nullable(),
  landingPageUrl: yup.string().url().required(t('pages.xmo.targetedDisplay.tactics.landingPageURLError')).nullable(),
  tacticComeFrom: yup.string().required(t('pages.xmo.targetedDisplay.tactics.tacticFromError')).nullable(),
  uploadedInSalesForce: yup.string().notRequired().nullable(),
  designiqLink: yup.string().url().required(t('pages.xmo.targetedDisplay.tactics.designIQLinkError')).nullable(),
  creativeServiceCid: yup.string().required(t('pages.xmo.targetedDisplay.tactics.creativeServiceCIDError')).nullable(),
  sequentialRetargetingTactics: yup.string().notRequired().nullable(),
  geoFenceSegmentLength: yup.string().notRequired().nullable(),
  /*Geo fence -- standard/ client-provided/*/
  standardGeoFenceAddressListUpload: yup.string().notRequired(),
  addressableGeoFenceAddressListUpload: yup.string().notRequired(),
  geographicalArea: yup.string().required(t('pages.xmo.targetedDisplay.tactics.addressableGeoFenceCurated.geographicalAreaError')).nullable(),
  /*Geo fence -- curated*/
  states: yup.string().required(t('pages.xmo.targetedDisplay.tactics.addressableGeoFenceCurated.statesError')).nullable(),
  cities: yup.string().required(t('pages.xmo.targetedDisplay.tactics.addressableGeoFenceCurated.citiesError')).nullable(),
  congressionalDistricts: yup.string().required(t('pages.xmo.targetedDisplay.tactics.addressableGeoFenceCurated.congressionalDistrictError')).nullable(),
  zipCodes: yup.string().required(t('pages.xmo.targetedDisplay.tactics.addressableGeoFenceCurated.zipCodesError')).nullable(),
  segment1: yup.string().notRequired(),
  andOr1: yup.string().notRequired(),
  segment2: yup.string().notRequired(),
  andOr2: yup.string().notRequired(),
  segment3: yup.string().notRequired(),
  andOr3: yup.string().notRequired(),
  segment4: yup.string().notRequired(),
  andOr4: yup.string().notRequired(),
  segment5: yup.string().notRequired(),
  
  targetedType: yup.string().notRequired(),
  targetedCities: yup.array().notRequired()
};

export default {
  yupValidations: TacticsFormDialogSchema,
  customValidations: {},
};